import React, { useEffect, useState } from "react";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { Button, Col, Form, Row } from "antd";
import useStyles from "../../../hooks/use-styles";
import ReactCodeInput from "react-code-input";
import { showErrorNotification } from "../../../utils/common-utils";
import EnrollmentHeader from "../../../components/Enrollment/header/header";
import Loader from "../../../components/shared/loader";
import SignUpFlexButtons from "../../../components/Enrollment/SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../../../components/Enrollment/SignUp-Button/signup-custom-button";
import Footer from "../../Main/Footer";
import { setLoginUser } from "../../../app-store/user/user-reducer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { login, mfaLogin } from "../../../app-store/user/user-action";
import MaterialModal from "../../../components/Enrollment/Material-Modal";
import ConfirmationModal from "../../../components/Enrollment/Material-Modal/Confirmation-Modal/confirmation-modal";
import { errorMessage } from "../../../utils/validation";
import "./styles.css";
import { useWindowResize } from "../../../hooks/use-window-resize";
import { widthResizeFunc } from "./consumer-verification-utils";

const imgPath = "/assets/images";

const ConsumerVerification = (props: any) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const resizeWidth = useWindowResize() < 992;
  const mobileSizeWidth = useWindowResize();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState();
  const [userPassword, setUserPassword] = useState();
  const [userOtpToken, setUserOtpToken] = useState();
  const [deviceID, setDeviceID] = useState();
  const [seconds, setSeconds] = useState(60);
  const [codeSent, setCodeSent] = useState(true);
  const [valid, setValid] = useState(true);
  const [key, setKey] = useState(0);
  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [fieldValues, setFieldValues] = useState<any>({
    pinCode: "",
  });

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const { email, password, otpToken, device_info } = location.state as any;
    if (!email) {
      navigate("/login");
    }
    setUserEmail(email);
    setUserPassword(password);
    setUserOtpToken(otpToken);
    setDeviceID(device_info.device_unique_id);
  }, []);

  useEffect(() => {
    if (seconds > 0 && codeSent) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (seconds === 0) {
      setSeconds(60);
      setCodeSent(false);
    }
  }, [seconds, codeSent]);

  const { signUpTitleColor } = useStyles(["signUpTitleColor"]);

  const validate = (key: string, value: string) => {
    const errors = { ...fieldErrors };
    if (value?.trim().length === 6) {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);
  };

  const onFieldChange = (value: any, options?: any) => {
    validate("pinCode", value);
    setFieldValues((prev: any) => ({ ...prev, pinCode: value }));
  };
  const resetPinCodeField = () => {
    setFieldValues((prev: any) => ({ ...prev, pinCode: "" }));
    setKey((prevKey) => prevKey + 1);
  };

  const verifyOTPPhone = async () => {
    const errors = {} as any;
    let valid = true;
    if (!fieldValues.pinCode) {
      valid = false;
      errors["pinCode"] = errorMessage.required;
    } else if (fieldValues.pinCode.trim().length < 6) {
      valid = false;
      errors["pinCode"] = errorMessage.codeLength;
    }

    setFieldErrors(errors);
    setValid(valid);
    if (!valid) {
      return;
    } else {
      setLoader(true);
      if (userOtpToken && fieldValues.pinCode) {
        mfaLogin({
          otpToken: userOtpToken,
          otp: fieldValues.pinCode,
          device_info: {
            device_unique_id: deviceID,
          },
        })
          .then((response: any) => {
            dispatch(setLoginUser(response));
            setLoader(false);
            navigate("/");
          })
          .catch((error) => {
            setLoader(false);
            showErrorNotification(error);
          });
      }
    }
  };

  const openModal = () => {
    setConfirmOpen(true);
  };

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationTitle: "Are you sure",
      confirmationText: "Are you sure you want to return to login screen.",
      closeLabel: "Cancel",
      confirmButtonText: "Yes, I am sure",
      onClose: () => setConfirmOpen(false),
      onConfirm: () => navigate("/login"),
    };

    return (
      <MaterialModal
        open={true}
        handleClose={() => setConfirmOpen(false)}
        width={widthResizeFunc(mobileSizeWidth)}
      >
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  const handleResend = async () => {
    setLoader(true);
    if (userEmail && userPassword) {
      login({
        email: userEmail,
        password: userPassword,
        device_info: {
          device_unique_id: deviceID,
        },
      })
        .then((response: any) => {
          dispatch(setLoginUser(response));
          navigate("/");
        })
        .catch((error) => {
          if (error?.error_code === "AU006" || error?.error_code === "AU005" || error?.error_code === "AU009") {
            setCodeSent(true);
            setUserOtpToken(error.additional_data?.mfa_token);
            resetPinCodeField();
          } else {
            showErrorNotification(error);
          }
          setLoader(false);
        });
    }

  };

  return (
    <div className="consumer-verification-container">
      <Row gutter={[0, 0]}>
        {!resizeWidth && (
          <Col lg={9} md={9} sm={9}>
            <div className="consumer-verification-bg-container">
              <div className="consumer-verification-logo-container">
                <img src={`${imgPath}/login-page-main-logo.png`} alt="logo" />
              </div>
            </div>
          </Col>
        )}
        <Col lg={15} md={24} sm={24} xs={24}>
          <div className="consumer-verification-content-container">
            <div className="consumer-verification-form-container">
              <div className="consumer-verification-otp-screen-container">
                <EnrollmentHeader
                  title={"One Time Passcode"}
                  titleColor={signUpTitleColor}
                />
                <p className="consumer-verification-otp-subtitle">
                  Enter the 6 digit passcode sent at {userEmail}.
                </p>
                <div className="login-form">
                  <Form
                    name="login"
                    layout="vertical"
                    initialValues={fieldValues}
                    className="form-style"
                  >
                    <div className="auth-form">
                      <ReactCodeInput
                        key={key}
                        name="pinCode"
                        inputMode="numeric"
                        fields={6}
                        value={fieldValues.pinCode}
                        type="number"
                        onChange={(value) => onFieldChange(value, true)}
                        isValid={valid}
                        className="pin-fields-container"
                      />
                      {Object.keys(fieldErrors).length > 0 && (
                        <div style={{ color: "red" }}>
                          {Object.values(fieldErrors).map((error, index) => (
                            <span key={index}>{error as React.ReactNode}</span>
                          ))}
                        </div>
                      )}

                      <p className="label">
                        Did not get the OTP?
                        {!codeSent ? (
                          <Button
                            type="link"
                            className="resend-code-button"
                            onClick={handleResend}
                          >
                            Resend Code
                          </Button>
                        ) : (
                          <>
                            <span className="resend-code"> Resend code</span>
                            <span> in {seconds} seconds.</span>
                          </>
                        )}
                      </p>
                    </div>

                    {loader && (
                      <Loader className="custom-loader consumer-verification-loader" />
                    )}

                    <SignUpFlexButtons className="layout-buttons justify-start consumer-verification-buttons-container direction-row-reverse">
                      <SignUpCustomButton
                        type="primary"
                        className="next-button action-button"
                        disabled={loading || loader}
                        onClick={verifyOTPPhone}
                        htmltype="submit"
                        text="Verify"
                      />

                      <SignUpCustomButton
                        type="outline"
                        disabled={loader}
                        className="cancel-button action-button"
                        onClick={() => openModal()}
                        text="Cancel"
                      />
                    </SignUpFlexButtons>
                  </Form>
                </div>
              </div>
            </div>
            <div className="consumer-mobile-keyboard-detection">
              {!isKeyboardOpen ? (
                <Footer className="consumer-verification-footer" />
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
      {renderConfirmModal()}
    </div>
  );
};

export default ConsumerVerification;
