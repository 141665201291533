import React from "react";
import useLabels from "../../hooks/use-labels";
import useStyles from "../../hooks/use-styles";
import { BsArrowRight } from "react-icons/bs";

const NextButton = (props: any) => {
  const {
    primaryButtonLabelColor,
    primaryButtonBackground,
    primaryButtonBorder,
  } = useStyles([
    "primaryButtonLabelColor",
    "primaryButtonBackground",
    "primaryButtonBorder",
  ]);

  const { nextButtonLabel } = useLabels(["nextButtonLabel"]);
  const { text, style, className, ...buttonProps } = props;

  return (
    <button
      disabled={props.disabled}
      className={`custom-button next-button ${className!}`}
      style={{
        backgroundColor: primaryButtonBackground,
        border: `1px solid ${primaryButtonBorder}`,
        color: primaryButtonLabelColor,
        ...style,
      }}
      onClick={props.onClick}
      {...buttonProps}
    >
      <span>{text || nextButtonLabel}</span>
      <BsArrowRight />
    </button>
  );
};

export default NextButton;
