import { FC } from 'react'
import './confirmation-modal-style.css'
import Loader from '../../../shared/loader'
import SignUpFlexButtons from '../../SignUp-Button/signup-flex-button'
import SignUpCustomButton from '../../SignUp-Button/signup-custom-button'

interface ConfirmationModalProps {
  modalProps: any,
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  modalProps,
}) => {
  const {
    titleColor,
    confirmationTitle,
    confirmationText,
    closeLabel,
    onClose,
    confirmButtonText,
    onConfirm,
    loading
  } = modalProps

  
  return (
    <>
      <div className="mat-modal-body text-center mt-2" style={{ color: titleColor }}>
        <p className="mb-2 weight-600 title">
          {confirmationTitle}
        </p>

        <p className="mb-2 body-text">{confirmationText}</p>
      </div>

      {loading && <Loader className="custom-loader mb-3" />}

      <SignUpFlexButtons className=" mb-5 mt-1 layout-buttons direction-row-reverse confirmation-modal-btn-container">
        <SignUpCustomButton
          type="primary"
          className="confirm-button ant-btn-default modal-button"
          text={confirmButtonText}
          onClick={onConfirm}
        />

        <SignUpCustomButton
          className="cancel-button ant-btn-default modal-button"
          type="outline"
          text={closeLabel}
          onClick={onClose}
        />
      </SignUpFlexButtons>
    </>
  )
}

export default ConfirmationModal
