import { Form, Input } from "antd";
import FlexButtons from "../../../components/flex-button";
import CustomButton from "../../../components/custom-button";
import { useNavigate } from "react-router-dom";
import axiosAPI from "../../../utils/axios";
import React, { useState } from "react";
import EnrollmentHeader from "../../../components/Enrollment/header/header";
import useStyles from "../../../hooks/use-styles";
import { getLabel } from "../../../hooks/use-labels";
import Loader from "../../../components/shared/loader";
import { errorMessage, fieldValidator } from "../../../utils/validation";

interface ForgotPWFormProps {
  setEmailSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordEmailForm = ({
  setEmailSubmitted,
  setEmail,
}: ForgotPWFormProps) => {
  const navigate = useNavigate();

  const { signUpTitleColor, signUpSubTitleColor } = useStyles([
    "signUpTitleColor",
    "signUpSubTitleColor",
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const onFieldChange = (value: string, options?: any) => {
    validate(false);
    setFormEmail(value);
  };

  const cancelReset = () => {
    navigate("/login");
  };

  const validate = (addError: boolean): boolean => {
    let validated = false;
    setHasErrors(true);
    if (!formEmail.trim()) {
      setError(errorMessage.required);
    } else if (!fieldValidator.email(formEmail)) {
      setError(errorMessage.email);
    } else {
      validated = true;
      setHasErrors(false);
    }
    if (!addError) {
      setError("");
      setHasErrors(false);
    }
    return validated;
  };

  const submitEmail = async () => {
    if (!validate(true)) {
      return;
    } else {
      setLoading(true);
      try {
        await axiosAPI.post(`/identity/email/${formEmail}/otp`, {
          reason_type: "RP",
        });
        setEmailSubmitted(true);
        setEmail(formEmail);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="reset-password-form-container enter-email-form">
      <EnrollmentHeader
        title={getLabel("passwordRest")}
        subTitle={getLabel("provideEmailVerification")}
        titleColor={signUpTitleColor}
        subTitleColor={signUpSubTitleColor}
        className="reset-password-title"
      />
      <Form
        name="resetPWEmail"
        className="form-style email-form"
        layout="vertical"
      >
        <Form.Item
          label=""
          name="email"
          validateStatus={error ? "error" : ""}
          help={error}
        >
          <Input
            placeholder="Email"
            bordered={false}
            onChange={(event) => onFieldChange(event.target.value, true)}
            className="forget-password-email-input"
          />
        </Form.Item>
        {loading && <Loader className="custom-loader reset-password-loader" />}
        <Form.Item>
          <FlexButtons className="layout-buttons justify-start forget-password-btn-container">
            <CustomButton
              text="Cancel"
              className="cancel-button action-button"
              type="outline"
              onClick={() => cancelReset()}
              disabled={loading}
            />
            <CustomButton
              text="Submit"
              className="next-button action-button"
              type="primary"
              htmltype="submit"
              onClick={submitEmail}
              disabled={loading || hasErrors}
            />
          </FlexButtons>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordEmailForm;
