import React, { FC, useState } from "react";
import { Dropdown } from "antd";
import "./styles.css";

interface DropdownContainerTypes {
  dropdownComponent?: any;
  clickTrigger?: boolean;
  menuList?: Array<string | number | object>;
  onMenuClick?: () => void;
  MenuComponent?: any;
  componentProps?: any;
  className?: string;
  dropdownClass?: any;
  placement?: string;
  overLayClass?: string;
}

const DropdownContainer: FC<DropdownContainerTypes> = ({
  dropdownComponent,
  clickTrigger,
  menuList,
  onMenuClick,
  MenuComponent,
  componentProps,
  className,
  dropdownClass,
  placement,
  overLayClass,
}: any) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible((prev) => !prev);
  };

  const dropdownProps = {
    ...(clickTrigger && {
      open: visible,
      trigger: ["click"],
      onOpenChange: toggleVisible,
    }),
  };

  return (
    <Dropdown
      menu={{ items: menuList }}
      placement={`${placement ? placement : "bottom"}`}
      overlayClassName={`consumer-dropdown ${overLayClass || ""}`}
      className={`w-fit-content ${dropdownClass || ""}`}
      {...dropdownProps}
    >
      <div>{dropdownComponent}</div>
    </Dropdown>
  );
};
export default DropdownContainer;
