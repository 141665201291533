import { Col, Row, Switch } from "antd";
import CustomHeader from "../CardOperationHeader";
import { useEffect, useState } from "react";
import {
  fetchProfileData,
  getRestrictions,
  updateCardStatus,
  updateRestrictions,
} from "../../../apis/b2c_api";
import customToast from "../../../components/shared/services/toaster-service";
import { showErrorNotification } from "../../../utils/common-utils";
import { useDispatch } from "react-redux";
import { setUserProfile } from "../../../app-store/user/user-reducer";
import Loader from "../../../components/shared/loader";
import useStyles from "../../../hooks/use-styles";

interface RestrictionsProps {
  blockCashWithdrawal: boolean;
  blockECommerce: boolean;
  blockInternational: boolean;
  filterId: string;
  riskControlId: string;
  riskControlRulesId: string;
}

const CardRestrictions = (props: any) => {
  const { selectedCard } = props.props;

  const [operationLoading, setOperationLoading] = useState(false);
  const [statusloading, setStatusloading] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isBlocked, setBlocked] = useState<boolean | undefined>();
  const [allowInternational, setAllowInternational] = useState<
    boolean | undefined
  >();
  const [restrictions, setRestrictions] = useState<RestrictionsProps>();
  const dispatch = useDispatch();

  const { darkBlueColor } = useStyles(["darkBlueColor"]);

  useEffect(() => {
    fetchRestrictions();
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRestriction = () => {
    const restrictionProps = {
      blockCashWithdrawal: restrictions?.blockCashWithdrawal,
      blockECommerce: restrictions?.blockECommerce,
      filterId: restrictions?.filterId,
      riskControlId: restrictions?.riskControlId,
      riskControlRulesId: restrictions?.riskControlRulesId,
      cardId: selectedCard.id,
      blockInternational: !restrictions?.blockInternational,
    };
    setOperationLoading(true);
    updateRestrictions(restrictionProps)
      .then((data: any) => {
        customToast.success("Operation performed successfully");
        fetchRestrictions();
        setOperationLoading(false);
      })
      .catch((error: any) => {
        showErrorNotification(error);
        setOperationLoading(false);
      });
  };

  const fetchRestrictions = () => {
    getRestrictions(selectedCard.id)
      .then(({ restrictions }: any) => {
        setAllowInternational(restrictions.blockInternational);
        setRestrictions(restrictions);
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const fetchProfile = () => {
    const profile = {
      transType: "",
      variableCode: "P2PTransferBeneficiaryAllowedProducts",
    };
    fetchProfileData(profile)
      .then(({ profile }: any) => {
        const { cards } = profile;
        const card = cards.find((item: any) => item.id === selectedCard.id);
        card.status === "BLOCKED" ? setBlocked(true) : setBlocked(false);

        dispatch(setUserProfile(profile));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };
  const updateStatus = () => {
    const cardStatus = isBlocked ? "Open" : "Blocked";
    const statusProps = {
      cardId: selectedCard?.id,
      status: cardStatus,
    };
    setStatusloading(true);
    updateCardStatus(statusProps)
      .then((data) => {
        customToast.success("Operation performed successfully");
        fetchProfile();
        setStatusloading(false);
      })
      .catch((error) => {
        showErrorNotification(error);
        setStatusloading(false);
      });
  };

  if (loading) return <Loader />;

  return (
    <div className="card-operations-setting">
      <Row className="card-operations-row">
        <Col md={20} xs={24} className="card-operations-col">
          <CustomHeader
            title="Allow International"
            subTitle="Allow/Block international transactions on the card"
            titleColor={darkBlueColor}
            className="card-operations-header"
          />
        </Col>
        <Col md={4} xs={24} className="card-operations-col">
          <div className="card-switch-container">
            {operationLoading && <Loader className="card-spinner-loader" />}
            <Switch
              id="blockInternational"
              className="swith-button-container"
              checked={!allowInternational}
              onChange={updateRestriction}
            />
          </div>
        </Col>
      </Row>
      <Row className="card-operations-row">
        <Col md={20} xs={24} className="card-operations-col">
          <CustomHeader
            title="Block Card"
            subTitle="Temporarily Block/Unblock your card"
            titleColor={darkBlueColor}
            className="card-operations-header"
          />
        </Col>
        <Col md={4} xs={24} className="card-operations-col">
          <div className="card-switch-container">
            {statusloading && <Loader className="card-spinner-loader" />}
            <Switch
              id="blockCard"
              className="swith-button-container"
              checked={isBlocked}
              onChange={updateStatus}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CardRestrictions;
