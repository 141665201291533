import AccountTabs from "./accountTabs";

import "../dashboard-style.css";
import "./styles.css";

interface IShowModal {
  editAccount: (account: any) => void;
  addAccount: (account: any) => void;
  selectedAccount: (account: any) => void;
  accounts: any;
}

const DashboardAccountTabs = ({
  editAccount,
  addAccount,
  selectedAccount,
  accounts,
}: IShowModal) => {
  return (
    <div className="dashboard-account-tabs">
      <AccountTabs
        accounts={accounts}
        editAccount={editAccount}
        addAccount={addAccount}
        selectedAccount={selectedAccount}
      />
    </div>
  );
};

export default DashboardAccountTabs;
