import { RecordVoiceOver } from "@mui/icons-material";
import { Pagination, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import useStyles from "../../../../hooks/use-styles";

interface prepareTransactionsTableProps {
  dataSource: Array<any>;
  onChange: (pageNumber: number, pageSize: number) => void;
  paginationProps: any;
  loading: boolean;
}

const TransactionsTable = (props: prepareTransactionsTableProps) => {
  const { dataSource, onChange, paginationProps } = props;

  const { pageNumber, totalRecords } = paginationProps;
  const { textColor, successfulGreenColor, pendingOrangeColor } = useStyles([
    "textColor",
    "successfulGreenColor",
    "pendingOrangeColor",
  ]);

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "transferDate",
      key: "date",
      width: '30%',
      render: (record) => (
        <p className="mb-0 text-sm Poppins" style={{ color: textColor }}>
          {record}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "transferAmount",
      key: "amount",
      width: '30%',
      render: (record) => (
        <p className="mb-0 text-sm bold Poppins" style={{ color: textColor }}>
          $ {record.amount}
        </p>
      ),
    },
    {
      title: "Bank",
      dataIndex: "bankAccount",
      key: "bankName",
      width: '30%',
      render: (record) => (
        <p className="mb-0 text-sm Poppins" style={{ color: textColor }}>
          {record.bankName}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <>
          <p
            className="mb-0 text-sm Poppins"
            style={{ color: pendingOrangeColor }}
          >
            {record === "SCHEDULED" && "Pending"}
          </p>
          <p
            className="mb-0 text-sm Poppins"
            style={{ color: successfulGreenColor }}
          >
            {record === "PROCESSED" && "Processed"}
          </p>
        </>
      ),
      // width: 50,
    },
  ];

  return (
    <>
      <Table
        className="custom-ant-table ant-table custom-ant-pagination mt-1 text-center"
        columns={columns}
        dataSource={dataSource}
        //size="small"
        pagination={{ pageSize: 8 }}
        loading={props.loading}
        rowKey={'id'}
      />
    </>
  );
};

export default TransactionsTable;
