import { getLabel } from "../../../../../hooks/use-labels";
import "./styles.css";

const OverLayContent = () => {
  return (
    <div className="right-dashboard-overlay-container">
      <div className="right-dashboard-overlay-content">
        <div className="overylay-description">
          {getLabel("weAreLaunchingLabel")}
        </div>
        <div className="overylay-main-title">{getLabel("aalamDebitLabel")}</div>
        <div className="overylay-description">
          {getLabel("masterCardSoonLabel")}
        </div>
      </div>
    </div>
  );
};

export default OverLayContent;
