export const widthResizeFunc = (resizeWidth: any): number => {
  let windowWidth: number = 0;

  switch (true) {
    case resizeWidth < 350:
      windowWidth = 280;
      break;
    case resizeWidth < 375:
      windowWidth = 300;
      break;
    case resizeWidth < 400:
      windowWidth = 350;
      break;
    case resizeWidth < 575:
      windowWidth = 350;
      break;
    case resizeWidth < 767:
      windowWidth = 500;
      break;
    case resizeWidth < 992:
      windowWidth = 700;
      break;
    default:
      windowWidth = 700;
  }
  return windowWidth;
};
