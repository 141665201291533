import React from "react";
import useLabels from "../../../../hooks/use-labels";

const AddBankHeader = () => {

  const { addBankAccountTitle, bankTransferDescription } = useLabels([
    "addBankAccountTitle",
    "bankTransferDescription",
  ]);

  return (
    <div className="add-bank-header">
      <div className="friend-family-header">
        <div className="header-title">
          <p
            className="text-left text-2lg weight-600"
            style={{ color: "#2C2A29" }}
          >
            {addBankAccountTitle}
          </p>
          <p
            className="text-left text-sm weight-400"
            style={{ color: "#2c2a29b3" }}
          >
            {bankTransferDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddBankHeader;
