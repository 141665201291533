import { Pagination, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import useStyles from "../../../../hooks/use-styles";
import { Fragment } from "react";

interface prepareTransactionsTableProps {
  dataSource: Array<any>;
  onChange: (pageNumber: number, pageSize: number) => void;
  paginationProps: any;
  loading: boolean;
}

const TransactionsTable = (props: prepareTransactionsTableProps) => {
  const { dataSource, onChange, paginationProps } = props;

  const { pageNumber, totalRecords } = paginationProps;
  const { textColor } = useStyles(["textColor", "redColor", "redcolor"]);

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (record) => (
        <span className="table-regular-text" style={{ color: textColor }}>
          {record}
        </span>
      ),
    },
    {
      title: "Account No.",
      dataIndex: "ddaNumber",
      key: "ddaNumber",
      render: (record) => (
        <span className="table-regular-text" style={{ color: textColor }}>
          {record}
        </span>
      ),
    },
    {
      title: "Card No.",
      dataIndex: "lastFour",
      key: "lastFour",
      render: (record) => (
        <span className="table-regular-text" style={{ color: textColor }}>
          {record}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className:"amount-td",
      render: (record) => (
        <span className="table-bold-text" style={{ color: textColor }}>
          {record}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => <span className="table-status-text">{record}</span>,
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
      key: "merchant",
      render: (record) => (
        <span className="table-regular-text" style={{ color: textColor }}>
          {record}
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        className="table-container-box ant-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={props.loading}
        locale={{ emptyText: "No Records Found" }}
      />
      <Pagination
        className="custom-ant-pagination"
        size="small"
        current={pageNumber}
        pageSize={9}
        total={totalRecords}
        onChange={onChange}
        showSizeChanger={false}
      />
    </Fragment>
  );
};

export default TransactionsTable;
