import { getExchangeRates } from "../../../../../apis/b2c_api";
import { showErrorNotification } from "../../../../../utils/common-utils";
import { prepareExchangeRate } from "./transfer-form-utils";

export const validExchangeRates = (
  fromCurrency: string,
  toCurrency: string,
  amount: number
): Promise<any> => {
  return getExchangeRates()
    .then((response: any) => {
      const { exchangeRates } = response.definitions;

      return prepareExchangeRate(
        exchangeRates,
        fromCurrency,
        toCurrency,
        Number(amount)
      );
    })
    .catch((error) => {
      showErrorNotification(error);
    });
};

interface FieldValues {
  fromCurrency?: string;
  toCurrency?: string;
  convertedAmount?: number;
  exchangeRateLine?: string;
}

export const updateFieldValues = (
  fieldValues: FieldValues,
  selectedCurrency: string,
  toCurrency: string,
  convertedAmount: number,
  exchangeRateLine: string
): object => {
  const updatedFieldValue = { ...fieldValues };

  updatedFieldValue.fromCurrency = selectedCurrency;
  updatedFieldValue.toCurrency = toCurrency;
  updatedFieldValue.convertedAmount = convertedAmount;
  updatedFieldValue.exchangeRateLine = exchangeRateLine;

  return updatedFieldValue;
};

export const updateErrorField = (key: string, fieldErrors: any) => {
  const errors = { ...fieldErrors };
  delete errors[key];
  return errors;
};
