import { useEffect, useState } from "react";
import { Line } from "@ant-design/charts";
import "./styles.css";
import dayjs from "dayjs";
import { fetchAccountWalletStats } from "../../../../apis/b2c_api";
import Loader from "../../../shared/loader";
import { prepareGrpahData, WalletStats } from "./graph-utils";
import { showErrorNotification } from "../../../../utils/common-utils";
import _ from "lodash";


const DashboardGraph = ({ accounts, accountInfo }: any) => {
  
  const [loading, setLoading] = useState(true);
  const [currentWallet, setCurrentWallet] = useState(
    !_.isEmpty(accountInfo) ? accountInfo.accountWallets[0] : []
  );

  const [chartData, setChartData] = useState<WalletStats[]>([]);

  useEffect(() => {
    if (!_.isEmpty(accountInfo)) {
      getWalletBalance(accountInfo.accountWallets[0]);
    }else{
      setLoading(false)
    }
  }, [accountInfo, accounts]);

  const getWalletBalance = (wallet: any) => {

      const walletProps = {
        dateFrom: dayjs(new Date(new Date().getFullYear(), 0, 1)).format(
          "YYYY-MM-DD"
        ),
        dateTo: dayjs(new Date()).format("YYYY-MM-DD"),
        interval: "monthly",
        accountWalletId: wallet.id,
      };

      fetchAccountWalletStats(walletProps)
        .then(({ accountWalletStats }) => {
          setChartData(prepareGrpahData(accountWalletStats));
          setCurrentWallet(wallet);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          showErrorNotification(error);
          setLoading(false);
        });
 
  };

  const changeWallet = (wallet: any) => {
    if(wallet) getWalletBalance(wallet);
  };

  const config = {
    data: chartData,
    height: 234,
    autoFit: true,
    xField: "month",
    yField: "Balance",
    padding: [40, 0, 32, 0],
    color: "#006cf8",
    point: {
      size: 6,
      shape: "round",
      style: {
        lineWidth: 1,
      },
    },
    tooltip: {
      showMarkers: false,
      formatter: (datum: any) => {
        const { Balance } = datum;
        const { currency } = currentWallet || { currency: "" };
        return { name: currency, value: Balance };
      },
    },

    label: {
      offsetY: -15,
      style: {
        fontSize: 11,
        fontFamily: "Poppins",
        fontWeight: 600,
        fill: "#7c583890",
      },

      formatter: (datum: any) => {
        const { Balance } = datum;
        const { currency } = currentWallet || { currency: "" };
        return Balance > 0 ? `${currency} ${Balance}` : "";
      },
    },

    xAxis: {
      tickLine: { style: { lineWidth: 0 } },
      label: {
        style: {
          fill: "#7c583890",
          fontSize: 14,
          lineHeight: 60,
        },
      },
    },
    yAxis: {
      grid: { line: { style: { lineWidth: 0 } } },
      label: {
        style: {
          fill: "#000",
        },
      },
    },
  };

  if (loading) return <Loader />;

  return (
    <div className="dashboard-graph-container">
      <div className="graph-header">
        <div className="graph-title">Balance</div>
        <div className="currency-buttons">
          {accountInfo?.accountWallets?.map((wallet: any, index: any) => (
            <div
              key={index}
              className="currency"
              onClick={() => changeWallet(wallet)}
              style={{
                backgroundColor:
                  wallet?.currency === currentWallet?.currency
                    ? "#2C2A29"
                    : "transparent",
                color:
                  wallet?.currency === currentWallet?.currency
                    ? "#ffffff"
                    : "#2C2A29",
              }}
            >
              {wallet?.currency}
            </div>
          ))}
        </div>
      </div>
      <Line {...config} />
    </div>
  );
};

export default DashboardGraph;
