interface CustomHeaderProps {
  title?: string;
  titleColor?: string;
  subTitle?: string;
  subTitleColor?: string;
  className?: string;
  subTitleClass?: string;
  titleClass?: string;
}
const CardOperationHeader = (props: CustomHeaderProps): JSX.Element => {
  const {
    title,
    subTitle,
    className,
    subTitleClass,
    titleClass,
    titleColor,
    subTitleColor,
  } = props;

  return (
    <div className={`header ${className!}`}>
      {title && (
        <h2
          className={`h2 header-title text-left ${titleClass!}`}
          style={{ color: titleColor, width: "400px" }}
        >
          {title}
        </h2>
      )}

      {subTitle && (
        <p
          className={`header-sub-title text-left ${subTitleClass!}`}
          style={{
            color: props.subTitleColor || subTitleColor,
            width: "900px",
          }}
        >
          {subTitle}
        </p>
      )}
    </div>
  );
};

export default CardOperationHeader;
