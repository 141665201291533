import {Button, Col, Form, FormInstance, Row} from "antd";
import useStyles from "../../../hooks/use-styles";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React, {useState} from "react";
import {errorMessage} from "../../../utils/validation";
import moment from "moment/moment";

const IdentityVerification = (props: any) => {
  const { signUpTitleColor } = useStyles(["signUpTitleColor"]);
  const formRef = React.useRef<FormInstance>(null);
  const { formData, onFormSubmit } = props;
  const { dob } =
    formData;

  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [loading, setLoading] = useState(false) as any;

  const [fieldValues, setFieldValues] = useState({
    dob: dob || "",
  }) as any;

  const validate = (
    key: string,
    value: string,
    options?: any,
    addError?: boolean
  ): string => {
    const errors = {...fieldErrors};
    if (!value.trim() && options) {
      errors[key] = errorMessage.required;
    } else if (key === "dob") {
      var date = moment(value, 'DD/MM/YYYY').format("YYYY/MM/DD");
      var date1 = new Date(date);
      var date2 = moment().format("YYYY/MM/DD");
      var date3 = new Date(date2);

      var Difference_In_Time = date3.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days < 6574) {
        errors[key] = errorMessage.minAgeLimit;
      } else {
        delete errors[key];
      }
    } else {
      delete errors[key];
    }

    if (!addError) {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);

    return errors[key];
  }

  const onFieldChange = (key: string, value: any, options: any) => {
    validate(key, value, options, false);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleNextFn = () => {
    const requiredFields = [
      "dob",
    ];
    const errors = {} as any;
    let valid = true;

    requiredFields.forEach((field) => {
      const fieldError = validate(field, fieldValues[field], true, true);
      if (fieldError) {
        errors[field] = fieldError;
        if (valid) {
          valid = false;
        }
      }
    });

    setFieldErrors(errors);
    setLoading(!valid);

    if (valid) {
      window.scrollTo(0, 0);
      onFormSubmit({
        ...fieldValues,
      });
    }
  };


  return (
    <div className="identity-verification-container">
      <EnrollmentHeader
        title={"Verify Your ID"}
        titleColor={signUpTitleColor}
        subTitle={"Please Enter you DOB"}
      />

      <div className="login-form" style={{height: "50%"}}>
        <Form
          name="login"
          layout="vertical"
          initialValues={fieldValues}
          className="form-style"
          ref={formRef}
          style={{height: "100%"}}
        >
          <div className="identity-form-body">
            <Row className="dob-input-row">
              <Col span={24}>
                <Form.Item
                  name="dob"
                  className="custom-form-item mb-1 d-grid"
                  style={{ fontSize: "12px" }}
                  validateStatus={fieldErrors.dob ? "error" : ""}
                  help={fieldErrors.dob}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      defaultValue={dayjs(fieldValues.dob)}
                      disabled={false}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          placeholder: "DD/MM/YYYY",
                        },
                      }}
                      className="input-border date-field-style"
                      format={"DD/MM/YYYY"}
                      onChange={(date: any) => {
                        if (date) {
                          const formattedDate = date.format("DD/MM/YYYY");
                          onFieldChange("dob", formattedDate, true);
                        } else {
                          onFieldChange("dob", null, true);
                        }
                      }}
                      views={["year", "month", "day"]}
                      openTo="year"
                    />
                  </LocalizationProvider>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="identity-verification-subtitle">
                  <p style={{marginTop: "5px"}}>To help Aalam comply with the local and international laws and regulations, we need you to verify your ID.</p>
                  <p><span style={{ fontWeight: "500"}}>Verification only takes a few minutes,</span> helps secure your account & payments.</p>
                  <p><span style={{ fontWeight: "600"}}>Have your ID handy</span> and you'll be taken through ID verification process.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <div className="button-style">
                    <Button
                      className="verify-id-btn"
                      type="primary"
                      htmlType="button"
                      disabled={true}
                    >
                      Verify Your ID
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <SignUpFlexButtons className="layout-buttons justify-start action-buttons-container direction-row-reverse">
              <SignUpCustomButton
                type="primary"
                className="action-button next-button"
                onClick={handleNextFn}
                disabled={loading}
                htmltype="submit"
                text="Next"
              />

              <SignUpCustomButton
                type="outline"
                className="action-button cancel-button"
                onClick={props.openModal}
                text="Cancel"
              />
            </SignUpFlexButtons>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default IdentityVerification;
