import React from "react";
import AddBankFormConfirm from "./AddBankFormConfirm";
import AddBankHeader from "../AddBankHeader";

interface ConfirmTransferProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onPrevious: () => void;
}

const AddBank = ({
  onSubmit,
  transferValues,
  onPrevious,
}: ConfirmTransferProps) => {
  return (
    <div className="friend-family-section">
      <div className="card-item-white">
        <AddBankHeader />
        <AddBankFormConfirm
          transferValues={transferValues}
          onSubmit={onSubmit}
          onPrevious={onPrevious}
        />
      </div>
    </div>
  );
};

export default AddBank;
