import React from "react";
import "./styles.css";

const EmptyCard = () => {
  const imgPath = "/assets/images/empty-credit-card.png";

  return (
    <div className="empty-card-container">
      <div className="empty-card-content">
        <div className="empty-card-icon">
          <img src={imgPath} alt={imgPath} />
        </div>
        <div className="empty-card-title">No Card Available</div>
      </div>
    </div>
  );
};

export default EmptyCard;
