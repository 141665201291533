import useStyles from "../../../hooks/use-styles";
import { useEffect, useState } from "react";
import {
  endOfWeek,
  showErrorNotification,
  startOfWeek,
} from "../../../utils/common-utils";
import TransactionsFilter from "./TransactionsFilter";
import ContentBox from "../../../components/content-box";
import { fetchBankTransfer } from "../../../apis/b2c_api";
import TransactionsTable from "./TransactionsTable";
import { Col, Row } from "antd";
import type { PaginationProps } from "antd";
import { useLocation } from "react-router-dom";
import { getTransactionsProp } from "../../../utils/reUseFunction";
import dayjs from "dayjs";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]) as any;
  const [transaction, setTransaction] = useState(null) as any;
  const transaction_Props = getTransactionsProp();
  const { state } = useLocation();
  const { transactionProps, pageCrumbs }: any = state || {};
  const { accountItem, walletItem } = transactionProps
    ? transactionProps
    : transaction_Props;

  const [filterFields, setFilter] = useState({
    transactionStatus: null,
    transactionType: null,
    transactionDate: [startOfWeek(), endOfWeek()],
    mainAccount: accountItem ? accountItem.id : null,
    walletAccount: walletItem ? walletItem.id : null,
  });

  const [loading, setLoading] = useState(true);
  const [paginationProps, setPaginaton] = useState({
    pageNumber: 1,
    pageSize: 8,
  });

  const { lightBlueColor } = useStyles(["lightBlueColor"]);

  useEffect(() => {
    getTransactions(paginationProps.pageNumber, filterFields);
  }, []);

  const onTransactionClick = (transactionItem: any) => {
    setTransaction(transactionItem);
  };

  const applyHandler = (filterValues: any) => {
    getTransactions(paginationProps.pageNumber, filterValues);
  };

  const resetHandler = (filterValues: any) => {
    getTransactions(paginationProps.pageNumber, filterValues);
  };

  const getTransactions = (pageNumber: any, filterValues: any) => {
    const { transactionDate } = filterValues;

    const todayDate = new Date();

    const bankTransferProps = {
      pageNumber,
      pageSize: paginationProps.pageSize,
      fromDate: transactionDate
        ? dayjs(transactionDate[0]).format("YYYY-MM-DD")
        : dayjs(todayDate).format("YYYY-MM-DD"),
      toDate: transactionDate
        ? dayjs(transactionDate[1]).format("YYYY-MM-DD")
        : dayjs(todayDate).format("YYYY-MM-DD"),
    };

    setLoading(true);
    fetchBankTransfer(bankTransferProps)
      .then((bankTransfer: any) => {
        setLoading(false);
        const { nodes, pageInfo } = bankTransfer.bankTransfers;
        onTransactionClick(nodes);
        setFilter(filterValues);
        setTransactions(nodes);

        setPaginaton((prev) => ({
          ...prev,
          ...paginationProps,
          pageSize: pageInfo.limit,
          pageNumber,
        }));
      })
      .catch((error: any) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const onChange: PaginationProps["onChange"] = (newPage: number) => {
    getTransactions(newPage, filterFields);
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24} sm={24}>
          <ContentBox>
            <TransactionsFilter
              onApply={applyHandler}
              onReset={resetHandler}
              filterValues={filterFields}
              setTransactions={setTransactions}
              transactions={transactions}
            />
            <TransactionsTable
              dataSource={transactions}
              paginationProps={paginationProps}
              onChange={onChange}
              loading={loading}
            />
          </ContentBox>
        </Col>
      </Row>
    </div>
  );
};
export default Transactions;
