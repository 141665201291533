import { useState } from "react";

import { createSubAccounts } from "../../../../apis/b2c_api";
import useConfigs from "../../../../hooks/use-config";
import { getLabel } from "../../../../hooks/use-labels";
import { showErrorNotification } from "../../../../utils/common-utils";
import CustomButton from "../../../custom-button";
import Loader from "../../../shared/loader";
import customToast from "../../../shared/services/toaster-service";
import "./styles.css";

interface IProfile {
  accountInfo?: any;
  handleCancel: () => void;
  onAddAccount: () => void;
}

const imgPath = "/assets/images";

const AddModal = ({ accountInfo, handleCancel, onAddAccount }: IProfile) => {
  const { title } = accountInfo;

  const { addWalletSuuccessMessage } = useConfigs(["addWalletSuuccessMessage"]);

  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    const requestBody = {
      ...accountInfo,
    };

    setLoading(true);
    createSubAccounts(requestBody)
      .then(() => {
        customToast.success(addWalletSuuccessMessage);

        setLoading(false);
        handleCancel();
        onAddAccount();
      })
      .catch((error) => {
        setLoading(false);
        handleCancel();
        showErrorNotification(error);
      });
  };

  return (
    <div className="add-wallet-popup-container">
      <div className="add-wallet-popup-header">
        <div
          className="add-wallet-popup-close-icon"
          onClick={() => handleCancel()}
        >
          <img src={`${imgPath}/close-icon.png`} alt="close-icon" />
        </div>
      </div>
      <div className="add-wallet-popup-content">
        <div className="add-wallet-popup-meta">
          <div className="add-wallet-popup-meta-title">
            {getLabel("areYouSureTxt")}
          </div>
          <div className="add-wallet-popup-meta-description">
            {getLabel("confirmCurrencyTxt")} {title} {getLabel("walletLabel")}
          </div>
        </div>
      </div>

      {loading && <Loader className="logo-heigh-auto" />}
      <div className="add-walelt-pop-btn-container">
        <CustomButton
          className="button-md primary-black-btn"
          type="primary"
          text={getLabel("yesLabel")}
          onClick={onFinish}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default AddModal;
