import { Button, Col, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import {
  fetchProfileData,
  payerToPayerTransfer,
} from "../../../../../apis/b2c_api";
import { getLabel } from "../../../../../hooks/use-labels";
import {
  formatCurrency,
  showErrorNotification,
} from "../../../../../utils/common-utils";
import ContentBox from "../../../../content-box";
import FlexButtons from "../../../../flex-button";
import Loader from "../../../../shared/loader";
import customToast from "../../../../shared/services/toaster-service";

import { getAccountTitle } from "../TransferAmount/TransferForm/transfer-form-utils";
import { useDispatch } from "react-redux";
import { setUserProfile } from "../../../../../app-store/user/user-reducer";
import FieldHeading from "../TransferAmount/TransferForm/field-heading";
import TransferFundsHeader from "../TransferAmount/TransferForm/transferFundsHeader";
interface ConfirmTransferProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onPrevious: () => void;
  fromWalletCurrency: any;
  type: any;
}

const ConfirmTransfer = (props: ConfirmTransferProps) => {
  const [loading, setLoading] = useState(false);
  const { accounts, amount, fromMainAccount, toMainAccount, memo, nickName } =
    props.transferValues;

  const dispatch = useDispatch();

  const floatAmount = parseFloat(amount);

  const transferFunds = () => {
    const transferProps = {
      beneficiary: {
        id: toMainAccount,
        type: toMainAccount?.includes("cd_") ? "CARD" : "ACCOUNT",
      },
      description: memo,
      externalRefNo: "",
      payer: {
        id: fromMainAccount,
        type: fromMainAccount?.includes("cd_") ? "CARD" : "ACCOUNT",
      },
      transferAmount: {
        amount: floatAmount,
        currency: props.fromWalletCurrency,
      },
      transferType: "",
    };
    setLoading(true);
    payerToPayerTransfer(transferProps)
      .then((_) => {
        setLoading(false);
        customToast.success(getLabel("fundsTransferSuccessMessage"));
        props.onSubmit(amount);

        const profile = {
          transType: "",
          variableCode: "P2PTransferBeneficiaryAllowedProducts",
        };

        return fetchProfileData(profile);
      })
      .then(({ profile }: any) => {
        dispatch(setUserProfile(profile));
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const fromAccountPreview = {
    label: getLabel("transferMainAccountTitle"),
    content: getAccountTitle(fromMainAccount, accounts),
  };

  const toAccountPreview = {
    label: getLabel("transferMainAccountTitle"),
    content: nickName,
  };

  return (
    <ContentBox className="confirm-transfer-container-4">
      <div className="ml-3">
        <TransferFundsHeader
          heading={getLabel("transferConfirmationTitle")}
          subHeading={getLabel("transferDescription")}
        />

        <div className="d-flex justify-start mt-1 align-center">
          <FieldHeading
            heading={getLabel("transferAmountTitle")}
            textClass="transfer-funds-field-title"
          />
          <div className="to-account ml-2">
            {formatCurrency(amount, props.fromWalletCurrency)}
          </div>
        </div>
        <div className="d-flex justify-start mt-2 full-width">
          <FieldHeading
            heading={getLabel("transferFromTitle")}
            textClass="transfer-funds-field-title"
          />
          <Row className="full-width">
            <Col md={6} className="min-width-fit-content ml-2">
              <div className="text-left">
                <div className="to-account"> {fromAccountPreview.content} </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content mt-2">
          <FieldHeading
            heading={getLabel("transferToTitle")}
            textClass="transfer-funds-field-title"
          />

          <Row className="full-width">
            <Col md={6} className="min-width-fit-content ml-2">
              <div className="text-left">
                <div className="to-account"> {toAccountPreview.content} </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-2">
          <div className="break-word memo-preview">{memo}</div>
        </div>

        <Row gutter={[16, 16]} className="mt-3">
          <Col md={12} xs={24}>
            <div className="d-flex justify-between">
              <div className="calender-img-container">
                <img src={"/assets/icons/calendar.svg"} alt="calendar" />
              </div>

              <div>
                <div className="date-label">
                  {getLabel("transferTransferDateTitle")}
                </div>
                <div className="date-preview">
                  {" "}
                  {moment().format("MM/DD/YYYY")}{" "}
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} xs={24}>

            <FlexButtons className="justify-end mt-1 mb-4">
              {loading && <Loader className="custom-loader confirm-transfer-loader" />}
              <Button
                shape="round"
                className="transfer-screen-buttons"
                onClick={props.onPrevious}
                disabled={loading}
              >
                Back
              </Button>
              <Button
                shape="round"
                className="transfer-screen-buttons"
                style={{
                  backgroundColor: "rgba(44, 42, 41, 1)",
                  color: "white",
                }}
                onClick={transferFunds}
                disabled={loading}
              >
                Send
              </Button>
            </FlexButtons>
          </Col>
        </Row>
      </div>
    </ContentBox>
  );
};
export default ConfirmTransfer;
