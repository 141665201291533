import type { MenuProps } from "antd";
import { Badge, Dropdown, Space } from "antd";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetUserDetails } from "../../../app-store/user/user-action";
import { logoutUser } from "../../../app-store/user/user-reducer";
import useLabels from "../../../hooks/use-labels";
import { useWindowResize } from "../../../hooks/use-window-resize";
import { logOutIcon } from "../../../utils/SvgImage/Icons";
import { getSecureURLDetails } from "../../../utils/common-utils";
import { getAccessToken, parseURL } from "../../../utils/reUseFunction";

import "./styles.css";

const imgPath = "/assets/images";

const HeaderCom = (): JSX.Element => {
  const activeUser = getAccessToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const resizeWidth = useWindowResize() > 575;

  const pageTile = () => {
    let returnVal = "";
    switch (location?.pathname) {
      case "/wallet-transfer":
        returnVal = "Transfer Funds";
        break;

      case "/card-operations":
        returnVal = "Card Operations";
        break;
      case "/beneficiaries":
        returnVal = "Transfer Funds";
        break;
      case "/transfer-funds":
        returnVal = "Transfer Funds";
        break;
      case "/Bank-Transfer":
        returnVal = "Transfer Funds";
        break;
      case "/between-account-transfer":
        returnVal = "Transfer Funds";
        break;
      case "/transactions":
        returnVal = "Transactions";
        break;
      case "/transfer-history":
        returnVal = "Transfer History";
        break;

      case "/":
        returnVal = "Accounts Summary";
        break;

      default:
        returnVal = "";
        break;
    }

    return returnVal;
  };

  const { name } = jwtDecode(activeUser) as any;
  const firstName = name.split(" ");

  const logOut = () => {
    const secureURL = getSecureURLDetails() as any;

    if (secureURL) {
      resetUserDetails();
      const { callback_url } = JSON.parse(secureURL);
      window.location.href = parseURL(callback_url);
    } else {
      dispatch(logoutUser());
    }
  };
  const { helloUserText } = useLabels(["helloUserText"]);

  const menu: MenuProps["items"] = [
    ...(!resizeWidth
      ? [
          {
            key: "2",
            label: (
              <div className="headder-user-name">
                {firstName[0]} {firstName[2]}
              </div>
            ),
          },
        ]
      : []),
    {
      key: "1",
      label: (
        <div className="drpodown-logout" onClick={logOut}>
          {logOutIcon} LogOut
        </div>
      ),
    },
  ];

  return (
    <div className="header-layout-container">
      <div className="header-section">
        <div className="header-title">{pageTile()}</div>
        <div className="header-action-section">
          {resizeWidth && (
            <div className="headder-user-name">
              <span>{helloUserText}</span> {firstName[0]}
            </div>
          )}
          <div className="header-avatar-icon">
            <Space>
              <Badge className="pointer">
                <Dropdown
                  overlayClassName="logout-dropdown"
                  menu={{ items: menu }}
                  trigger={["click"]}
                >
                  <div className="profil-icon-img">
                    <img
                      src={`${imgPath}/profile-icon.png`}
                      alt="profile-icon"
                    />
                  </div>
                </Dropdown>
              </Badge>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCom;
