import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmTransfer from "./ConfirmTransfer";
import TransferAmount from "./TransferAmount";
import TransferSuccess from "./TransferSuccess";
import { getSendTransferProps } from "../../../../utils/reUseFunction";

const WalletTransfer = () => {
  const [step, setCurrentStep] = useState(1);
  const [transferInformation, setTransferInformation] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sendTransferProps }: any = state || getSendTransferProps()?.state;

  const handleSubmit = (transferValues: any) => {
    setTransferInformation((prev) => ({ ...prev, ...transferValues }));
    handleNext();
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleClose = () => {
    navigate("/Bank-Transfer");
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const accountData = (accountData: any) => {
    setSelectedAccount(accountData);
  };

  const makeAnother = () => {
    setTransferInformation({});
    setCurrentStep(1);
  };

  const walletSteps: any = {
    1: () => (
      <TransferAmount
        onSubmit={handleSubmit}
        transferValues={transferInformation}
        onClose={handleClose}
        sendTransferProps={sendTransferProps}
        accountData={accountData}
      />
    ),
    2: () => (
      <ConfirmTransfer
        transferValues={transferInformation}
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        sendTransferProps={sendTransferProps}
        selectedAccount={selectedAccount}
      />
    ),
    3: () => (
      <TransferSuccess
        {...transferInformation}
        onClose={handleClose}
        makeAnother={makeAnother}
      />
    ),
  };

  return <>{walletSteps[step]()}</>;
};
export default WalletTransfer;
