import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Form, Input } from "antd";
import {
  createRecipient,
  otpRequired,
  sendOtp,
} from "../../../../apis/b2c_api";
import useLabels from "../../../../hooks/use-labels";
import { showErrorNotification } from "../../../../utils/common-utils";
import {
  getConsumerId,
  getUserEmail,
  getUserPhoneNumber,
} from "../../../../utils/reUseFunction";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";
import { useState } from "react";

interface OPTModalProps {
  setNickNameModal: any;
  setOptModal: any;
  userProfileData: any;
  accountId: any;
  cardId: any;
  getRecipientDetails: Function;
  setOpenDrawer: any;
}

const NickNameModal = ({
  setNickNameModal,
  setOptModal,
  userProfileData,
  accountId,
  cardId,
  getRecipientDetails,
  setOpenDrawer,
}: OPTModalProps) => {
  const consumerId = getConsumerId();

  const { enterNickNameTitle, enterNickNamedespcri } = useLabels([
    "enterNickNameTitle",
    "enterNickNamedespcri",
  ]);

  const handleCancel = () => {
    setNickNameModal(false);
  };

  const [nickName, setNickName] = useState("");

  const handleInputChange = (event: any) => {
    if (event.target.value) {
      setNickName(event.target.value);
    }
  };

  const onFinish = (values: any) => {
    const createRecipientBody = {
      consumerId: consumerId,
      entityId: accountId ? accountId : cardId,
      entityType: accountId ? "ACCOUNT" : "CARD",
      nickName: nickName,
    };

    createRecipient(createRecipientBody)
      .then((res) => {
        otpRequired().then((res) => {
          if (res.otpRequired === true) {
            const sendOtpBody = {
              email: getUserEmail(),
              phoneNo: getUserPhoneNumber(),
            };
            sendOtp(sendOtpBody)
              .then((res) => {
                setNickNameModal(false);
                setOptModal(true);
              })
              .catch((error) => {
                showErrorNotification(error);
              });
          } else {
            setNickNameModal(false);
            setOpenDrawer(false);
            getRecipientDetails();
          }
        });
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  return (
    <div className="opt-modal">
      <div className="opt-modal-heading">
        <h3 style={{ margin: "0", padding: "0" }}>{enterNickNameTitle}</h3>
        <p style={{ margin: "0", padding: "0", color: "rgb(116, 127, 139)" }}>
          {enterNickNamedespcri}
        </p>
      </div>
      <div className="opt-user-detail">
        <div className="">
          <div className="nick-name-input">
            <Form onFinish={onFinish}>
              <Form.Item name="nickname">
                <Input
                  onChange={handleInputChange}
                  maxLength={30}
                  placeholder="Nickname"
                />
              </Form.Item>
              <Form.Item>
                <FlexButtons className=" gap-3">
                  <Button
                    shape="round"
                    style={{ height: "45px", padding: "4px 30px" }}
                    onClick={handleCancel}
                    className="primary-outline-btn"
                  >
                    Cancel
                  </Button>

                  <Button
                    shape="round"
                    style={{
                      height: "45px",
                      padding: "4px 25px",
                      backgroundColor: "#2C2A29",
                      color: "white",
                    }}
                    onClick={onFinish}
                  >
                    Continue
                  </Button>
                </FlexButtons>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NickNameModal;
