import { useEffect, useState } from "react";
import Loader from "./components/shared/loader";
import "./App.css";
import 'chart.js/auto';
import { setMetadata } from "./utils/metadata";
import AppContainer from "./views/AppContainer";
import { fetchMetaData } from "./apis/b2c_api";

// const setLanguage = () => {
//     const params = new URLSearchParams(window.location.search);
//     const languageCode = params.get("language_code") || "en";
//     changeLanguage(languageCode);
// };

const App = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // setLanguage();
    setLoading(false);
    fetchMetaData()
      .then((metadata: any) => {
        setMetadata(metadata?.metadata ? JSON.parse(metadata.metadata) : {});
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  if (loading) return <Loader />;
  return <AppContainer />;
};

export default App;
