import { FC } from "react";
import useStyles from "../../../../../../../hooks/use-styles";

interface FieldHeadingProps {
  heading: string;
  textClass?: string;
}

const FieldHeading: FC<FieldHeadingProps> = ({ heading, textClass }) => {
  const { tagColor } = useStyles(["tagColor"]);
  return (
    <div className="min-width-8">
      <p
        className={`text-left text-lg weight-500 full-width ${textClass}`}
        style={{
          color: tagColor,
          borderRight: `0.2px solid ${tagColor}`,
        }}
      >
        {heading}
      </p>
    </div>
  );
};

export default FieldHeading;
