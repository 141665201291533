import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Button } from "antd";
import {
  accountLastFour,
  getNetworkLogo,
  padLastFive,
  padLastFourHtmlTag,
} from "../../../../utils/common-utils";
import { fetchSpending } from "../../../../apis/b2c_api";
import { yyyymmddDate } from "../../../../utils/reUseFunction";
import useStyles from "../../../../hooks/use-styles";
import { CaretRightFilled } from "@ant-design/icons";
import CardModal from "./CardModal";
import EmptyCard from "./Card/EmptyCard";
import { getCardStatus } from "./dashboard-utils";
import OverLayContent from "./OverLayContent";
import { useWindowResize } from "../../../../hooks/use-window-resize";

const { Option } = Select;

const imgPath = "/assets/images";
const iconPath = "/assets/icons";

interface IProfile {
  accounts: any;
  accountInfo: any;
  openDrawer: boolean;
}

const disabled: boolean = true;

const RightDashboard = ({ accounts, accountInfo, openDrawer }: IProfile) => {
  const [spendingData, setSpendingData] = useState();
  const [showSecure, setshowSecure] = useState(false);
  const resizeWidth = useWindowResize() < 992;

  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  const account = accountInfo.length ? accountInfo : accounts[0];

  const cards = account?.cards;
  const card = cards && cards?.length > 0 && cards[0];

  const [selectedCard, setSelectedCard] = useState(card);

  const { verifyBtnGreenColor, statusRedColor, statusYellowColor } = useStyles([
    "verifyBtnGreenColor",
    "statusRedColor",
    "statusYellowColor",
  ]);

  useEffect(() => {
    if (accountInfo.length) {
      setSelectedCard(accountInfo.cards[0]);
    }
  }, [accountInfo]);

  const onDateChange = (value: any) => {
    if (value && account) {
      const spendingBody = {
        fromDate: yyyymmddDate(value[0]),
        toDate: yyyymmddDate(value[1]),
        accountId: account.id,
      };
      fetchSpending(spendingBody).then((response) => {
        response &&
          response.spending.length > 0 &&
          response.spending.map((data: any) =>
            setSpendingData(data.amount.toString().replace("-", ""))
          );
      });
    }
  };

  const getCardIndex = (card: any) => {
    return cards?.findIndex((item: any) => item?.id === card?.id);
  };

  const toggleCard = () => {
    setshowSecure(!showSecure);
  };

  const onSelectCard = (data: any) => {
    if (data) {
      setSelectedCard(data);
    }
  };

  const getStatusColor = (status?: string) => {
    if (!status) return "#CCC";
    return {
      OPEN: verifyBtnGreenColor,
      REISSUED: verifyBtnGreenColor,
      NEW: verifyBtnGreenColor,
      PURCHASED: verifyBtnGreenColor,

      CLOSED: statusRedColor,
      BLOCKED: statusRedColor,
      EXPIRED: statusRedColor,

      LOST: statusYellowColor,
      STOLEN: statusYellowColor,
      FRAUD_BLOCKED: statusYellowColor,
    }[status];
  };

  return (
    <div
      className={`right-dashboard ${
        openDrawer ? "right-dasbhoard-open-drawer-card" : ""
      }`}
    >
      <div className="card-item-white">
        {selectedCard && (
          <div className="card-view-content">
            {!resizeWidth ? (
              <div className="card-view-select">
                <Select
                  placeholder="Select Card"
                  optionFilterProp="children"
                  value={getCardIndex(selectedCard)}
                  defaultValue={[0]}
                  suffixIcon={
                    <img src={`${imgPath}/dropdown-arrow.png`} alt={imgPath} />
                  }
                  disabled
                >
                  {cards?.map((data: any, index: any) => (
                    <Option value={index} key={index}>
                      <div
                        className="options-meta"
                        key={index}
                        onClick={() => onSelectCard(data)}
                      >
                        <div className="option-label">Card</div>
                        <div className="option-value">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: padLastFourHtmlTag(
                                accountLastFour(data?.lastFour)
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </div>
            ) : (
              <div className="card-account-number-content">
                <div className="card-account-label">Account Number</div>
                {account.ddaNumber && (
                  <div
                    className="card-account-value"
                    dangerouslySetInnerHTML={{
                      __html: padLastFourHtmlTag(
                        accountLastFour(account.ddaNumber)
                      ),
                    }}
                  />
                )}
              </div>
            )}

            {!showSecure && (
              <div className="card-view-network">
                <div className="card-view-bg">
                  <div className="card-network-icons-container">
                    <div className="card-network-chip-icon">
                      <img
                        src={`${imgPath}/card-vc-chip.png`}
                        alt="chip-icon"
                      />
                    </div>
                    <div className="card-network-signal-icon">
                      <img
                        src={`${imgPath}/card-vc-signal.png`}
                        alt="signal-icon"
                      />
                    </div>
                  </div>
                  <div className="card-network-number">
                    <div
                      className="card-network-last-four"
                      dangerouslySetInnerHTML={{
                        __html: padLastFourHtmlTag(
                          accountLastFour(selectedCard?.lastFour)
                        ),
                      }}
                    />
                  </div>
                  <div className="card-network-name">
                    <div className="card-network-card-holder-name">
                      {selectedCard?.printedConsumerName}
                    </div>
                    <div className="card-network-logo-bottom">
                      <img
                        src={require("./../../../../statics/images/master_logo.png")}
                        alt="image"
                      />
                    </div>
                    <div
                      className="card-network-expiry"
                      style={{ display: "none" }}
                    >
                      <div className="expiry-title">
                        GOOD
                        <br />
                        THRU
                      </div>
                      <CaretRightFilled className="expiry-arrow" />
                      <span>
                        <div className="expiry-format">MONTH/YEAR</div>
                        <div className="expiry-value">
                          {selectedCard?.cardExpiry}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showSecure && (
              <div className="card-view card-background">
                <img
                  src={require("./../../../../statics/images/boc_blue.png")}
                  alt="boc-blue"
                ></img>
              </div>
            )}
          </div>
        )}

        {cards?.length < 1 && <EmptyCard />}

        {cards?.length > 0 && (
          <div className="card-info-container">
            {!resizeWidth && (
              <div className="card-info-status-text">Card Status</div>
            )}
            <div className="card-info-status-sign">
              <div
                className={`card-status-btn ${getCardStatus(
                  selectedCard?.status
                )}`}
              >
                {resizeWidth && "Card"} {getCardStatus(selectedCard?.status)}
              </div>
            </div>
            {cards?.length > 0 && !resizeWidth ? (
              <Button
                className={`card-operation-btn ${
                  disabled ? "card-operation-btn-disable" : ""
                }`}
                onClick={() =>
                  !disabled &&
                  navigate("/card-operations", {
                    state: {
                      pageTitle: "Card Operations",
                      selectedCard,
                      accountCards: cards,
                    },
                  })
                }
              >
                Card Operations
              </Button>
            ) : (
              <div
                className="card-opetions-setting-icon"
                onClick={() =>
                  !disabled &&
                  navigate("/card-operations", {
                    state: {
                      pageTitle: "Card Operations",
                      selectedCard,
                      accountCards: cards,
                    },
                  })
                }
              >
                <img
                  src={`${iconPath}/settings-icon.png`}
                  alt="settings-icon"
                />
              </div>
            )}
          </div>
        )}
        <OverLayContent />
      </div>
      <CardModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default RightDashboard;
