import { Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import useLabels from "../../../../hooks/use-labels";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";
import AddBankHeader from "../AddBankHeader";

interface TransferAmountProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
}

const AddBankForm = ({ onSubmit, onClose }: TransferAmountProps) => {
  let navigate = useNavigate();


  const {
    legalAccountNameLabel,
    bankNameLabel,
    accountNumberLabel,
    accountTypeLabel,
    routingNumberLabel,
    nickNameLabel,
    privacyPolicyCheckBoxText,
  } = useLabels([
    "legalAccountNameLabel",
    "bankNameLabel",
    "accountNumberLabel",
    "accountTypeLabel",
    "routingNumberLabel",
    "nickNameLabel",
    "privacyPolicyCheckBoxText",
  ]);

  const onFinish = (values: any) => {
    const addAccountBody = {
      accountNumber: values.AccountNumber,
      accountTitle: values.LegalAccountName,
      accountType: values.AccountType,
      bankName: values.BankName,
      country: "USA",
      currencyCode: "USD",
      initiateVerification:
        values.initiateVerification === undefined ? false : true,
      isPreVerified: values.isPreVerified === undefined ? false : true,
      label: values.NickName,
      routingNumber: values.RoutingNumber,
    };
    onSubmit(addAccountBody);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onCancelClick = () => {
    navigate("/Bank-Transfer");
  };

  return (
    <div className="friend-family-section">
      <div className="card-item-white">
        <AddBankHeader />

        <div className="add-bank-form">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={legalAccountNameLabel}
              name="LegalAccountName"
              rules={[
                {
                  required: true,
                  message: "Please input your Legal Account Name!",
                },
              ]}
            >
              <Input placeholder="Mark burg" />
            </Form.Item>
            <Form.Item
              label={bankNameLabel}
              name="BankName"
              rules={[
                { required: true, message: "Please input your Bank Name!" },
              ]}
            >
              <Input placeholder="Chase Bank" />
            </Form.Item>
            <Form.Item
              label={accountNumberLabel}
              name="AccountNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Account Number!",
                },
              ]}
            >
              <Input type="number" placeholder="00 000 00000000" />
            </Form.Item>
            <Form.Item
              label={accountTypeLabel}
              name="AccountType"
              rules={[
                { required: true, message: "Please input your Account Type!" },
              ]}
            >
              <Input placeholder="Saving" />
            </Form.Item>
            <Form.Item
              label={routingNumberLabel}
              name="RoutingNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Routing Number!",
                },
              ]}
            >
              <Input type="number" placeholder="6543 5678 8763" />
            </Form.Item>
            <Form.Item
              label={nickNameLabel}
              name="NickName"
              rules={[
                { required: true, message: "Please input your Nick Name!" },
              ]}
            >
              <Input placeholder="MarkB" />
            </Form.Item>
            <Form.Item
              name="isPreVerified"
              valuePropName="checked"
              wrapperCol={{ offset: 3, span: 16 }}
            >
              <div className="pl-22">
                <div className="mb-19">
                  <Checkbox>{privacyPolicyCheckBoxText}</Checkbox>
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <FlexButtons className="layout-buttons justify-end">
                <CustomButton
                  type="outline"
                  className="full-width"
                  style={{ Color: "#006CF8" }}
                  onClick={onCancelClick}
                  text="Cancel"
                />
                <CustomButton
                  type="primary"
                  className="full-width"
                  style={{ backgroundColor: "#006CF8" }}
                  htmltype="submit"
                  text="Continue"
                />
              </FlexButtons>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddBankForm;
