import ForgotPasswordLayout from "..";
import OTPForm from "../OTPForm";
import PasswordEmailForm from "../PasswordEmailForm";
import { useState } from "react";

const ForgotPassword = (): JSX.Element => {
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  return (
    <ForgotPasswordLayout>
      {emailSubmitted ? (
        <OTPForm setEmailSubmitted={setEmailSubmitted} email={email} />
      ) : (
        <PasswordEmailForm
          setEmailSubmitted={setEmailSubmitted}
          setEmail={setEmail}
        />
      )}
    </ForgotPasswordLayout>
  );
};

export default ForgotPassword;
