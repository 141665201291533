import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmTransfer from "./ConfirmTransfer";
import TransferAmount from "./TransferAmount";
import TransferSuccess from "./TransferSuccess";

const WalletTransfer = () => {
  const [step, setCurrentStep] = useState(1);
  const [transferInformation, setTransferInformation] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (transferValues: any) => {
    setTransferInformation((prev) => ({ ...prev, ...transferValues }));
    handleNext();
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleClose = () => {
    navigate("/");
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
    setTransferInformation({});
  };

  const makeAnother = () => {
    setTransferInformation({});
    setCurrentStep(1);
  };

  const walletSteps: any = {
    1: () => (
      <TransferAmount
        onSubmit={handleSubmit}
        transferValues={transferInformation}
        onClose={handleClose}
      />
    ),
    2: () => (
      <ConfirmTransfer
        transferValues={transferInformation}
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
      />
    ),
    3: () => (
      <TransferSuccess
        {...transferInformation}
        onClose={handleClose}
        makeAnother={makeAnother}
      />
    ),
  };

  return walletSteps[step]();
};
export default WalletTransfer;
