import { useEffect, useState } from "react";
import "./style.timer.css";
import { showErrorNotification } from "../../../utils/common-utils";
import useStyles from "../../../hooks/use-styles";
import useLabels from "../../../hooks/use-labels";
import Loader from "../../shared/loader";
import {sendCodeOnEmail, sendCodeOnPhoneAuth} from "../../../apis/auth_api";
import { Label } from "reactstrap";

interface TimerProps {
  seconds: number;
  resendPhone?: string;
  resendEmail?: string;
  reasonType: string;
  resetPinCodeField?: () => void;
}

const formatTime = (time: number) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};
const ResendTimer = (props: TimerProps) => {
  let [time, setTime] = useState(props.seconds);
  const [loading, setLoading] = useState(false);

  const { resendColor, timeColor } = useStyles([
    "resendColor",
    "timeColor",
  ]);

  const {resendText} = useLabels(['resendText']);

  const resetPinCodeField = () => {
    if (props.resetPinCodeField) {
      props.resetPinCodeField();
    }
  };

  useEffect(() => {
    let interval: any;
    interval = setInterval(() => {
      if (time > 0) {
        setTime((prev) => prev - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const resendCode = () => {
    setLoading(true);

    if(props.resendEmail) {
      sendEmailCode(props.resendEmail);
    } else if(props.resendPhone) {
      sendPhoneCode(props.resendPhone);
    }
  };

  const sendPhoneCode = (phone: string) => {
    sendCodeOnPhoneAuth(phone, props.reasonType)
      .then(() => {
        setLoading(false);
        setTime(props.seconds);
        resetPinCodeField();
      })
      .catch( error => {
        setLoading(false);
        showErrorNotification(error)
      });
  }

  const sendEmailCode = (email: string) => {
    sendCodeOnEmail(email, props.reasonType)
      .then(() => {
        setLoading(false);
        setTime(props.seconds);
        resetPinCodeField();
      })
      .catch( error => {
        setLoading(false);
        showErrorNotification(error)
      });
  }

  return (
    <div className="resend-label d-flex mt-1 mb-5">
      <Label
        for="resend"
        disabled={!!time}
        style={{ color: resendColor }}
        onClick={() => !time && resendCode()}
      >
        {resendText}
      </Label>

      {time > 0 && (
        <span className="timer ml-1" style={{ color: timeColor }}>
          {formatTime(time)}
        </span>
      )}
      <div className="mt-3">
        {loading && <Loader className="custom-loader"/>}
      </div>
      
    </div>
  );
};

export default ResendTimer;
