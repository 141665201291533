import { getLabel } from "../../../hooks/use-labels";

const imgPath = "/assets/images";

export const cardOptions = [
  {
    image: `${imgPath}/to-beneficiary.png`,
    title: getLabel("transferFundOptionOneTitle"),
    description: getLabel("transferFundOptionOneContent"),
    path: "/beneficiaries",
    colSizeMd: 8,
    colSizeSm: 24,
    disabled: false,
  },
  {
    image: `${imgPath}/bank-accounts.png`,
    title: getLabel("transferFundOptionTwoTitle"),
    description: getLabel("transferFundOptionTwoContent"),
    path: "",
    colSizeMd: 8,
    colSizeSm: 24,
    disabled: true,
  },
  {
    image: `${imgPath}/between-accounts.png`,
    title: getLabel("transferFundOptionThreeTitle"),
    description: getLabel("transferFundOptionThreeContent"),
    path: "/between-account-transfer",
    // path: "",
    colSizeMd: 8,
    colSizeSm: 24,
    disabled: false,
  },
];
