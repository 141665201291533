import { createSlice } from "@reduxjs/toolkit";

const publicSlice = createSlice({
  name: "Public",
  initialState: {
    openDrawer: false,
  },
  reducers: {
    setCardDrawerState(state, action) {
      state.openDrawer = action.payload;
    },
  },
});

export const publicSliceActions = publicSlice.actions;

export default publicSlice.reducer;
