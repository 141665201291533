import axios from 'axios'
import { INIT_APP_ID, INIT_KEY, INIT_URL } from '../../credentials'
import postMessageToParent from '../../utils/post-message-to-parent'
import { CookieService } from '../../service/cookie-service'

export const initialize = async () => {
  const initURL = INIT_URL()
  const { data } = await axios.get(`${initURL?.trim()}/init`, {
    headers: {
      'x-api-key': INIT_KEY(),
      'x-app-id': INIT_APP_ID()
    }
  })
  const initData = data?.data ? JSON.stringify(data?.data, undefined, 2) : '{}'
  const session_timeout = data?.data?.session_timeout || '300';
  const idle_timeout = data?.data?.idle_timeout || '300';
  localStorage.setItem('secure_cookie', data.secure_cookie || 'no');
  localStorage.setItem('init', initData)
  CookieService.setCookie('cc_idle_timeout', idle_timeout)
  CookieService.setCookie('cc_session_timeout', session_timeout)
  postMessageToParent('api.auth.success', data.data)
}
