import validator from "validator";
export const fieldValidator: any = {
  email: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  phoneNumber: (value: string) => {
    return validator.isMobilePhone(value.replace(/-|\s/g, ""), "any", {
      strictMode: true,
    });
  },
  postalCode: (value: string) => /^[\d-]+$/.test(value),
  strongPassword: (value: string, options: object) =>
    validator.isStrongPassword(value, options),
  matchPassword: (value: string, comparedValue: String) =>
    comparedValue ? comparedValue === value : true,
  ssn: (value: string) => validator.isNumeric(value),
  pin: (value: string) => validator.isNumeric(value),
  city: (value: string) => /^[a-zA-Z ]*$/.test(value),
  numeric: (value: string) => validator.isNumeric(value),
};

export const errorMessage: any = {
  email: "Invalid Email!",
  emptyEmail: "EMPTY_EMAIL",
  phoneNumber: "Invalid Phone Number!",
  weakPassword: "WEAK_PASSWORD",
  mismatchPassword: "MISMATCH_PASSWORD",
  shortPassword: "SHORT_PASSWORD",
  postalCode: "Invalid Postal Code",
  postalCodeLength: "Minimum length should be 5",
  maxPostalCodeLength: "Maximum length should be 15",
  ssn: "Invalid SSN!",
  ssnLength: "Minimum 9 digits!",
  city: "Invalid City!",
  cityLength: "Minimum length should 2!",
  stateLength: "Minimum length should 2!",
  required: "Required!",
  streetAddresss: "STREET_ADDRESS",
  maxAgeLimit: "MAX_AGE_LIMIT",
  minAgeLimit: "Minimum age limit is 18 years old",
  maxAmountLimit: "MAX_AMOUNT_LIMIT",
  minAmountLimit: "MIN_AMOUNT_LIMIT",
  invalidNumber: "INVALID_NUMBER",
  invalidDecimal: "INVALID_DECIMAL",
  codeLength: "Invalid code length!",
};
