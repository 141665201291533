import React, { useState } from "react";
import { Form } from "antd";
import useStyles from "../../../hooks/use-styles";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import ReactCodeInput from "react-code-input";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import { errorMessage } from "../../../utils/validation";
import {
  showErrorNotification,
} from "../../../utils/common-utils";
import {verifyOTP} from "../../../apis/auth_api";
import ResendTimer from "../resend-timer";
import Loader from "../../shared/loader";

const CodeVerification = (props: any) => {
  const [valid, setValid] = useState(true);
  const [key, setKey] = useState(0);
  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const [fieldValues, setFieldValues] = useState<any>({
    pinCode: "",
  });

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const { formData, onFormSubmit, handleDelete } = props;

  const { signUpTitleColor, signUpSubTitleColor, enterOTPDescrip, resendOTP } =
    useStyles([
      "signUpTitleColor",
      "signUpSubTitleColor",
      "enterOTPDescrip",
      "resendOTP",
    ]);

  const validate = (key: string, value: string) => {
    const errors = { ...fieldErrors };
    if (value?.length === 6) {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);
  };

  const onFieldChange = (value: any, options?: any) => {
    validate("pinCode", value);
    setFieldValues((prev: any) => ({ ...prev, ["pinCode"]: value }));
  };
  const resetPinCodeField = () => {
    setFieldValues((prev: any) => ({ ...prev, pinCode: "" }));
    setKey((prevKey) => prevKey + 1);
  };

  const verifyOTPPhone = () => {
    setLoader(true);

    verifyOTP(formData.email, fieldValues.pinCode)
      .then(({ data }) => {
        setLoader(false);
        const { confirmation_token: confirmationToken } = data;
        onFormSubmit({ confirmationToken, ...fieldValues });
      })
      .catch((error) => {
        setLoader(false);
        showErrorNotification(error);
      });
  };
  const handleNextFn = () => {
    const requiredFields = ["pinCode"];
    const errors = {} as any;
    let valid = true;

    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        valid = false;
        errors[field] = errorMessage.required;
      } else if (fieldValues[field].length < 6) {
        valid = false;
        errors[field] = errorMessage.codeLength;
      }
    });
    setFieldErrors(errors);
    setLoading(!valid);

    if (valid) {
      verifyOTPPhone();
    }
  };

  return (
    <div className="verification-otp-screen-container">
      <EnrollmentHeader
        title={"One Time Passcode"}
        subTitle={`Enter the 6 digit passcode sent at ${formData.email}`}
        titleColor={signUpTitleColor}
      />
      <div className="login-form">
        <Form
          name="login"
          layout="vertical"
          initialValues={fieldValues}
          className="form-style"
        >
          <div className="auth-form">
            <ReactCodeInput
              key={key}
              name="pinCode"
              inputMode="numeric"
              fields={6}
              value={fieldValues.pinCode}
              type="number"
              onChange={(value) => onFieldChange(value, true)}
              isValid={valid}
              className="pin-fields-container"
            />
            {Object.keys(fieldErrors).length > 0 && (
              <div style={{ color: "red" }}>
                {Object.values(fieldErrors).map((error, index) => (
                  <span key={index}>{error as React.ReactNode}</span>
                ))}
              </div>
            )}

            <ResendTimer
              seconds={30}
              resendEmail={formData.email}
              reasonType={"SU"}
              resetPinCodeField={resetPinCodeField}
            />
          </div>

          {loader && (
            <Loader className="custom-loader code-verification-loader" />
          )}

          <SignUpFlexButtons className="layout-buttons justify-start code-verification-buttons-container direction-row-reverse">
            <SignUpCustomButton
              type="primary"
              className="next-button action-button"
              disabled={loading || loader}
              onClick={handleNextFn}
              htmltype="submit"
              text="Verify"
            />

            <SignUpCustomButton
              type="outline"
              disabled={loader}
              className="cancel-button action-button"
              onClick={props.openModal}
              text="Cancel"
            />
          </SignUpFlexButtons>
        </Form>
      </div>
    </div>
  );
};
export default CodeVerification;
