import useStyles from "../../hooks/use-styles";

const PrimaryButton = (props: any) => {
  const { htmlType, size, className, style, onClick, text } = props;

  const {
    primaryButtonLabelColor,
    primaryButtonBackground,
    primaryButtonBorder,
  } = useStyles([
    "primaryButtonLabelColor",
    "primaryButtonBackground",
    "primaryButtonBorder",
  ]);

  const buttonProps = {
    ...(htmlType && { htmlType }),
  };
  return (
    <button
      disabled={props.disabled}
      className={`custom-button ${className!} ${size ? "button-" + size : ""}`}
      style={{
        backgroundColor: primaryButtonBackground,
        border: `1px solid ${primaryButtonBorder}`,
        color: primaryButtonLabelColor,
        ...(style && style),
      }}
      onClick={onClick}
      {...buttonProps}
    >
      <span>{text}</span>
    </button>
  );
};

export default PrimaryButton;
