export const transactionTypeOptions = () => {
  return [
    {
      label: "Credit",
      value: "CREDIT",
    },
    {
      label: "Debit",
      value: "DEBIT",
    },
  ];
};

export const statusOptions = () => {
  return [
    {
      label: "Processed",
      value: "P",
    },
    {
      label: "Pending",
      value: "H",
    },
  ];
};

export const accountOptions = (accounts: any) => {
  return accounts.map((accountItem: any) => ({
    label: accountItem.title,
    value: accountItem.id,
  }));
};

export const walletOptions = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);

  return account.accountWallets.map(({ title, id }: any) => ({
    label: title,
    value: id,
  }));
};
