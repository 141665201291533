import { FC } from "react";
import "./styles.css";

interface ImageBoxPorps {
  title?: string;
  image?: string;
  description?: string;
  className?: string;
  clickable?: boolean;
  onClick?: () => void;
  disalbed?: boolean;
  activeIcon?: string;
}

const ImageContentBox: FC<ImageBoxPorps> = ({
  title,
  image,
  description,
  className,
  clickable,
  onClick,
  disalbed,
  activeIcon,
}) => {
  return (
    <div
      className={`box-container ${
        clickable ? "pointer" : ""
      } ${className}-container `}
      onClick={onClick}
    >
      <div className={`${disalbed ? "disabled-container" : ""}`}></div>
      <div className={`box-image ${className}-image`}>
        <img src={image} alt={title} />
      </div>

      <div className={`box-content ${className}-content`}>
        <div className={`box-title ${className}-title`}>{title}</div>
        <div className={`box-subtitle ${className}-subtitle`}>
          {description}
        </div>
      </div>
      {activeIcon && (
        <div className={`box-active-icon ${className}-active-icon`}>
          <img src={activeIcon} alt={activeIcon} />
        </div>
      )}
    </div>
  );
};

export default ImageContentBox;
