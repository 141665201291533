import { Row, Col, Input, Select, Form } from "antd";
import { FC, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import {
  prepareAccountOptions,
  setCurrentAccOption,
} from "./transfer-form-utils";

import "./form-style.css";
import FieldHeading from "./field-heading";
import useLabels from "../../../../../../hooks/use-labels";
import useStyles from "../../../../../../hooks/use-styles";
import { getSendTransferProps } from "../../../../../../utils/reUseFunction";
import ContentBox from "../../../../../content-box";
import FlexButtons from "../../../../../flex-button";
import CustomButton from "../../../../../custom-button";
import { padLastFour } from "../../../../../../utils/common-utils";

interface TransferFormProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onClose: () => void;
  sendTransferProps: any;
  accountData: (accountData: any) => void;
}

const TransferForm: FC<TransferFormProps> = ({
  transferValues,
  onSubmit,
  onClose,
  sendTransferProps,
  accountData,
}) => {
  const { tagColor, darkBlueColor, blackColor } = useStyles([
    "tagColor",
    "darkBlueColor",
    "blackColor",
  ]);

  const localStorageSendMoneyData = getSendTransferProps();

  const { bankName, accountNumber } = sendTransferProps
    ? sendTransferProps
    : localStorageSendMoneyData.state.sendTransferProps;

  const {
    bankTransferTitle,
    bankTransferDescription,
    transferAmountTitle,
    transferFromTitle,
    transferToTitle,
    transferTransferDateTitle,
  } = useLabels([
    "bankTransferTitle",
    "bankTransferDescription",
    "transferAmountTitle",
    "transferFromTitle",
    "transferToTitle",
    "transferTransferDateTitle",
  ]);

  const { userProfile } = useSelector((state: any) => state.users);

  const { accounts } = userProfile;

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <ContentBox className="transfer-form-container-2">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="custom-form">
          <div className="header-title">
            <p
              className="text-left text-2lg weight-600 mb-0"
              style={{ color: blackColor }}
            >
              {bankTransferTitle}
            </p>
            <p
              className="text-left text-sm weight-400 mb-65"
              style={{ color: tagColor }}
            >
              {bankTransferDescription}
            </p>
          </div>

          <div className="d-flex justify-start align-center mt-2">
            <FieldHeading heading={transferAmountTitle} />

            <div className="ml-1">
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please input your Amount",
                  },
                ]}
              >
                <Input
                  type="number"
                  className="text-lg pt-0 pb-0 full-width"
                  pattern="/^-?\d*(\.\d*)?$/"
                  prefix={"$"}
                  placeholder="0.00"
                  bordered={false}
                  required={true}
                  id="accountId"
                />
              </Form.Item>
            </div>
          </div>
          <div className="d-flex justify-start align-center mt-1">
            <FieldHeading heading={transferFromTitle} textClass="mt-1" />

            <div className="full-width">
              <Row gutter={[16, 16]} style={{ width: "100%" }} className="ml-1">
                <Col md={10} xs={12}>
                  <Form.Item
                    name="fromAccount"
                    rules={[
                      {
                        required: true,
                        message: "Please input your From Account",
                      },
                    ]}
                    style={{ padding: 0 }}
                  >
                    <Select
                      className="text-left selectBoi custom-wallet-placeholder weight-600"
                      style={{
                        width: "60%",
                        color: darkBlueColor,
                      }}
                      placeholder="Select Account"
                      bordered={false}
                      value={0}
                      onChange={(value) =>
                        accountData(setCurrentAccOption(accounts, value))
                      }
                    >
                      {prepareAccountOptions(accounts).map(
                        (item: any, index: any) => {
                          return (
                            <Select.Option
                              style={{
                                color: blackColor,
                              }}
                              key={index}
                              value={index}
                            >
                              <span>{item.label}</span>
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>

          <div className="d-flex justify-start align-center mt-1">
            <FieldHeading heading={transferToTitle} textClass="mt-1" />
            <div className="full-width">
              <Row gutter={[16, 16]} className="ml-1 full-width">
                <Col className="mt10" md={10} xs={12}>
                  <p className="mb-0">
                    {bankName}
                    {padLastFour(
                      accountNumber
                        .toString()
                        .substring(accountNumber.toString().length - 4)
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <p
              className="text-left text-md mt-1 weight-500 pr-5"
              style={{
                fontSize: "22px",
                color: tagColor,
              }}
            >
              Memo (Optional)
            </p>
            <Form.Item name="memo">
              <Input
                bordered={false}
                maxLength={255}
                style={{
                  color: darkBlueColor,
                  borderBottom: `1.5px solid #CAD2D2`,
                  width: "89%",
                }}
                placeholder={"Type note here..."}
              />
            </Form.Item>

            <div className="d-flex mt-2 justify-between">
              <div style={{ width: "4rem" }}>
                <img
                  src={require("./../../calendar.png")}
                  width={"40px"}
                  height={"40px"}
                  className="mt-1"
                  alt="calendar"
                />
              </div>

              <div>
                <p style={{ color: tagColor }}>{transferTransferDateTitle}</p>
                <p> {moment().format("MM/DD/YYYY")} </p>
              </div>
            </div>
          </div>
        </div>
        <Form.Item>
          <FlexButtons className="layout-buttons justify-end pb-5 mt-2 pl-3">
            <CustomButton
              type="outline"
              className="full-width"
              style={{ Color: "#006CF8" }}
              text="Cancel"
              onClick={onClose}
            />
            <CustomButton
              type="primary"
              className="full-width"
              style={{ backgroundColor: "#006CF8" }}
              text="Continue"
              htmltype="submit"
            />
          </FlexButtons>
        </Form.Item>
      </Form>
    </ContentBox>
  );
};
export default TransferForm;
