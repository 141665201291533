import { Col, Form, Row } from "antd";
import useStyles from "../../../hooks/use-styles";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import { PreviewContent } from "./preview-content";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import { previewMaskSsn } from "../../../utils/common-utils";

const ReviewDetails = (props: any) => {
  const { formData } = props;

  const {
    firstName,
    lastName,
    email,
    address,
    address2,
    state,
    city,
    countryName,
    stateName,
    postalCode,
    dob,
    ssn,
  } = formData;

  const { signUpTitleColor, signUpSubTitleColor } = useStyles([
    "signUpTitleColor",
    "signUpSubTitleColor",
  ]);

  const handleReviewNext = () => {
    window.scrollTo(0, 0);
    props.handleNext();
  }

  return (
    <div className="address-info-container address-info-review-container">
      <EnrollmentHeader
        title={"Review Your Details"}
        titleColor={signUpTitleColor}
        subTitleColor={signUpSubTitleColor}
        backbutton={true}
        backClick={props.handlePrevious}
        titleColSize={24}
        className="address-info-title"
      />
      <div className="review-detail review-detail-container">
        <h4>Personal Information</h4>
        <Row>
          <Col span={24}>
            <div>
              <PreviewContent label="First Name" value={firstName} />
            </div>
          </Col>
          <Col span={24}>
            <div>
              <PreviewContent label="Last Name" value={lastName} />
            </div>
          </Col>
          <Col span={24}>
            <div>
              <PreviewContent label="Email" value={email} />
            </div>
          </Col>
        </Row>

        <h4>Address</h4>
        <Row>
          <Col span={24}>
            <div>
              <PreviewContent label="Address" value={address + ' ' + (address2 ?? '')} />
            </div>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <div>
                  <PreviewContent label="City" value={city} />
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <PreviewContent
                    label="State"
                    value={stateName ? stateName : state}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <div>
                  <PreviewContent label="Zip Code" value={postalCode} />
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <PreviewContent label="Country" value={countryName} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <h4>Identity</h4>
        <Row>
          <Col span={24}>
            <div>
              <PreviewContent label="Date of Birth" value={dob} />
            </div>
          </Col>
        </Row>

        <Form>
          <SignUpFlexButtons className="layout-buttons justify-start action-buttons-container direction-row-reverse">
            <SignUpCustomButton
              type="primary"
              className="action-button next-button"
              onClick={handleReviewNext}
              htmltype="submit"
              text="Next"
            />

            <SignUpCustomButton
              type="outline"
              className="action-button cancel-button"
              onClick={props.openModal}
              text="Cancel"
            />
          </SignUpFlexButtons>
        </Form>
      </div>
    </div>
  );
};

export default ReviewDetails;
