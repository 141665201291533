const ContentBox = (props: any): JSX.Element => {
  const { className } = props;
  return (
    <div
      className={`text-sm box-border-radius ${className}`}
      style={{
        height: "100%",
        backgroundColor: "#fff",
        padding: "1rem 2rem",
        boxShadow: "0 4px 16px 0 rgba(0,0,0,0.04)",
        border: "unset",
      }}
    >
      {props.children && props.children}
    </div>
  );
};

export default ContentBox;
