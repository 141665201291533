import { styles } from "../utils/metadata";
import defaults from "../utils/defaults";
import { getLanguage } from "../utils/language-helper";

const useStyles = (keys: Array<string>): any => {
  const stylesData = {} as any;

  const mdStyles = styles();
  const languageCode = getLanguage();

  keys.forEach((key) => {
    let styleInfo = mdStyles.find(
      (s: any) => s.config_code === key && (s.languageCode ? s.language_code === languageCode : true) 
    );

    if (!styleInfo) {
      styleInfo = mdStyles.find((s: any) => s.config_code === key);
    }

    stylesData[key] = styleInfo ? `#${styleInfo.config_value}` : defaults[key];
  });

  return stylesData;
};

export default useStyles;
