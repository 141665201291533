import { Row, Col, Input, Select, Button } from "antd";
import { FC, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import "./select-style.css";
import {
  prepareAccountOptions,
  prepareWalletOptions,
} from "./transfer-form-utils";
import FieldHeading from "./field-heading";
import { getLabel } from "../../../../../../hooks/use-labels";
import { invalidCharacter } from "../../../../../../utils/reUseFunction";
import customToast from "../../../../../shared/services/toaster-service";
import ContentBox from "../../../../../content-box";
import FlexButtons from "../../../../../flex-button";
import useStyles from "../../../../../../hooks/use-styles";
import TransferFundsHeader from "./transferFundsHeader";
import { TransferFormProps } from "./interface-utils";
import "./form-style.css";

const TransferForm: FC<TransferFormProps> = ({
  transferValues,
  onSubmit,
  onClose,
  id,
  nickName,
  fromWalletCurrency,
}) => {
  const { darkBlueColor, blackColor } = useStyles([
    "darkBlueColor",
    "blackColor",
  ]);

  const errMessage = "This is a required field";

  const { userProfile } = useSelector((state: any) => state.users);

  const { accounts } = userProfile;

  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const required = true;

  const errorMessage: any = {
    required: "REQUIRED",
    minimumAmount: "Minimum_Amount",
  };

  const [fieldValues, setFieldValues] = useState({
    amount: transferValues.amount || null,
    fromMainAccount: transferValues.fromMainAccount || null,
    toMainAccount: id || null,
    memo: transferValues.memo || null,
  }) as any;

  const getDefaultAccount = () => {
    if (accounts.length === 1) {
      return accounts[0].id;
    }
    return fieldValues.fromMainAccount;
  };

  useEffect(() => {
    const ammountInput: any = document.getElementById("accountId");
    invalidCharacter(ammountInput);

    if (accounts.length === 1) {
      setFieldValues((prev: any) => ({
        ...prev,
        fromMainAccount: accounts[0].id,
      }));
    }
  }, [accounts]);

  const onChange = (key: string, value: string, required: boolean) => {
    if (key === "amount") {
      const regex = /^\d{0,12}(\.\d{0,4})?$/;
      if (value && !regex.test(value)) return;
    }

    const walletOptions =
      ["fromMainAccount"].includes(key) &&
      prepareWalletOptions(value, accounts);

    const resetWalletValue: any = {
      fromMainAccount: {
        fromSubAccount:
          walletOptions.length === 1 ? walletOptions[0].value : null,
      },
    };

    const errors = { ...fieldErrors };
    if (typeof value === "string" && !value.trim() && required) {
      errors[key] = errorMessage.required;
    } else {
      delete errors[key];
    }

    setFieldErrors(errors);

    setFieldValues((prev: any) => ({
      ...prev,
      [key]: value,
      ...(resetWalletValue[key] && resetWalletValue[key]),
    }));
  };

  const isValidFields = () => {
    let isValid = true;

    const errors: any = { ...fieldErrors };

    const requiredFields = ["amount", "fromMainAccount"];

    requiredFields.forEach((field) => {
      if (!fieldValues[field as keyof typeof fieldValues]) {
        errors[field] = errorMessage.required;
        isValid = false;
      }
    });
    setFieldErrors(errors);
    return isValid;
  };

  const isValidAmount = () => {
    const errors: any = { ...fieldErrors };

    if (fieldValues.amount && +fieldValues.amount <= 0) {
      errors.amount = errorMessage.minimumAmount;
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const validFields = isValidFields();
    const validAmount = isValidAmount();

    if (validFields && validAmount) {
      onSubmit({ ...fieldValues, accounts, nickName });
    } else if (!validFields) {
      customToast.error("Please provide all required fields");
    } else if (!validAmount) {
      customToast.error("Amount must be greater than 0");
    }
  };

  return (
    <ContentBox className="transfer-form-container-3">
      <div className="custom-form ml-3 mr-3">
        <TransferFundsHeader
          heading={getLabel("transferFundsTitle")}
          subHeading={getLabel("transferDescription")}
        />

        <div className="d-flex justify-start mt-2 align-center">
          <FieldHeading
            heading={getLabel("transferAmountTitle")}
            textClass="transfer-funds-field-title"
          />
          <div className="ml-1">
            <Input
              type="number"
              className="transfer-funds-field-input"
              pattern="/^-?\d*(\.\d*)?$/"
              prefix={fromWalletCurrency}
              placeholder="0.00"
              bordered={false}
              required={true}
              id="accountId"
              onChange={(event: any) =>
                onChange("amount", event.target.value, required)
              }
              value={fieldValues.amount}
            />
          </div>
        </div>
        <div className="error-message">
          {fieldErrors["amount"] && (
            <div className="error-text">{errMessage}</div>
          )}
        </div>
        <div className="d-flex justify-start mt-1 align-center">
          <FieldHeading
            heading={getLabel("transferFromTitle")}
            textClass="mt-1 transfer-funds-field-title"
          />
          <div className="full-width">
            <Row
              gutter={[16, 16]}
              style={{ width: "100%" }}
              className="ml-1 mt-1"
            >
              <Col md={10} xs={12}>
                <Select
                  className="text-left selectBoi transfer-funds-from-select"
                  style={{
                    width: "60%",
                    color: darkBlueColor,
                  }}
                  placeholder="Select Account"
                  onSelect={(value: any) =>
                    onChange("fromMainAccount", value, required)
                  }
                  value={getDefaultAccount()}
                  bordered={false}
                >
                  {prepareAccountOptions(accounts).map(
                    (item: any, index: any) => (
                      <Select.Option
                        style={{
                          color: blackColor,
                        }}
                        key={index}
                        value={item.value}
                      >
                        {item.label}
                      </Select.Option>
                    )
                  )}
                </Select>
                {fieldErrors["fromMainAccount"] && (
                  <div className="error-text">{errMessage}</div>
                )}
              </Col>
            </Row>
          </div>
        </div>

        <div className="d-flex justify-start mt-1 align-center">
          <FieldHeading
            heading={getLabel("transferToTitle")}
            textClass="mt-1 transfer-funds-field-title"
          />
          <div className="full-width mt-1">
            <Row gutter={[16, 16]} className="ml-1 full-width">
              <Col md={10} xs={12} className="">
                <div className="to-account">{nickName}</div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="mt-5">
          <Input
            maxLength={255}
            placeholder={"Memo (Optional)"}
            value={fieldValues.memo}
            onChange={({ target }: any) =>
              onChange("memo", target.value, false)
            }
            className="memo-input"
          />
        </div>

        <Row gutter={[16, 16]} className="mt-3">
          <Col md={12} xs={24}>
            <div className="d-flex mt-1 justify-between">
              <div className="calender-img-container">
                <img src={"/assets/icons/calendar.svg"} alt="calendar" />
              </div>
              <div>
                <div className="date-label">
                  {getLabel("transferTransferDateTitle")}
                </div>
                <div className="date-preview">
                  {moment().format("MM/DD/YYYY")}
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <FlexButtons className="justify-end pb-5 mt-2 pl-3">
              <Button
                shape="round"
                className="transfer-screen-buttons"
                onClick={onClose}
              >
                {getLabel("cancelBtnLabel")}
              </Button>
              <Button
                shape="round"
                className="transfer-screen-buttons"
                style={{
                  backgroundColor: "rgba(44, 42, 41, 1)",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                {getLabel("nextBtnLabel")}
              </Button>
            </FlexButtons>
          </Col>
        </Row>
      </div>
    </ContentBox>
  );
};
export default TransferForm;
