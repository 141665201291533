
import ContentBox from "../../../../components/content-box";
import CustomButton from "../../../../components/custom-button";
import FlexButtons from "../../../../components/flex-button";

const TransferSuccess = (props: any) => {
  const { onClose, makeAnother } = props;

  return (
    <>
      <ContentBox>
        <div className="flex-col align-center justify-center full-height mt-2 pb-2">
          <img
            className="width-20 height-20"
            src={"/assets/successImage.png"}
            alt="success"
          />
          <p
            className="text-2lg mt-2 mb-0 poppins-semi-bold"
            style={{ color: "#2C3E50" }}
          >
            Congratulations!
          </p>
          <p style={{ color: "#2C3E50" }}>
            Bank Account has been Created successfully
          </p>

          <FlexButtons className="mt-5 pb-2 full-width">
            <CustomButton
              type="outline"
              className="text-center text-sm"
              style={{ width: "12rem", height: "2.5rem" }}
              text="Done"
              onClick={onClose}
            />

            <CustomButton
              type="primary"
              className="text-center text-sm"
              style={{ width: "12rem", height: "2.5rem" }}
              text="Make Another Account"
              onClick={() => makeAnother()}
            />
          </FlexButtons>
        </div>
      </ContentBox>
    </>
  );
};
export default TransferSuccess;
