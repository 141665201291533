import { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [resizeWidth, setResizeWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const handleResize = () => {
    setResizeWidth(window.innerWidth);
  };

  return resizeWidth;
};
