import { Button, Col, Collapse, Form, Input, Row } from "antd";
import ContentBox from "../../components/content-box";
import CustomHeader from "./CardOperationHeader";
import { Fragment, useState } from "react";
import { showErrorNotification } from "../../utils/common-utils";
import { updatePin } from "../../apis/b2c_api";
import CardRestrictions from "./CardRestrictions";
import CustomButton from "../../components/custom-button";
import MaterialModal from "../../components/Enrollment/Material-Modal";
import ConfirmationModal from "../../components/Enrollment/Material-Modal/Confirmation-Modal/confirmation-modal";
import customToast from "../../components/shared/services/toaster-service";
import { fieldValidator } from "../../utils/validation";
import { login } from "../../app-store/user/user-action";
import Loader from "../../components/shared/loader";
import { getProfileData } from "../../utils/reUseFunction";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { getLabel } from "../../hooks/use-labels";

const { Panel } = Collapse;

const imagePath = "/assets/images";

const CardOperations = () => {
  const [fieldValues, setFieldValues] = useState({
    pin: "",
    confirmPin: "",
    password: "",
  }) as any;
  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [loading, setLoading] = useState(true) as any;
  const [confirmOpen, showConfirm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [form] = Form.useForm();

  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);

  const handleAccordionChange = (key: any) => {
    setIsAccordionOpen(key.length && true);
  };

  const profileData = getProfileData();

  const { state } = useLocation();
  const { selectedCard: openedCard, accountCards }: any = state || {};
  const cards = accountCards || profileData?.cards;
  const card = cards[0];

  let selectedCard = openedCard ? openedCard : card

  const validate = (key: string, value: string, options?: any) => {
    const validator = fieldValidator;
    const { pin, confirmPin } = fieldValues;
    const errors = { ...fieldErrors };
    if (!value.trim() && options) {
      errors[key] = "Required";
    } else if (key === "pin" && value.length < 4) {
      errors[key] = "PIN length is 4 digits";
    } else if (key === "confirmPin" && value.length < 4) {
      errors[key] = "PIN length is 4 digits";
    } else if (key === "pin" && !validator.pin(fieldValues.pin)) {
      errors[key] = "Invalid PIN";
    } else if (key === "confirmPin" && !validator.pin(fieldValues.pin)) {
      errors[key] = "Invalid PIN";
    } else if (
      key === "pin" &&
      !fieldValidator.matchPassword(value, confirmPin)
    ) {
      errors[key] = "The two PINS that you entered do not match";
    } else if (
      key === "confirmPin" &&
      !fieldValidator.matchPassword(value, pin)
    ) {
      errors[key] = "The two PINS that you entered do not match";
    } else {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);
  };

  const onFieldChange = (key: string, value: any, options: any) => {
    validate(key, value, options);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const props = {
    selectedCard: selectedCard,
  };

  const resetFieldValues = () => {
    form.resetFields();
    setFieldValues({});
  };

  const pinChange = () => {
    const values = {
      email: profileData.email,
      password: fieldValues.password,
    };

    const pinProps = {
      cardId: selectedCard.id,
      newPin: fieldValues.confirmPin,
    };
    setLoader(true);
    openModal();
    login(values)
      .then(async (response: any) => {
        await updatePin(pinProps)
          .then((data: any) => {
            setLoader(false);
            customToast.success("PIN changed successfully");
            resetFieldValues();
          })
          .catch((error: any) => {
            setLoader(false);
            showErrorNotification(error);
          });
      })
      .catch((error) => {
        setLoader(false);
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const openModal = () => {
    const requiredFields = ["pin", "confirmPin", "password"];
    const errors = {} as any;
    let valid = true;

    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        valid = false;
        errors[field] = "Required";
      }
    });
    setFieldErrors(errors);
    setLoading(!valid);
    if (valid) {
      showConfirm((previousValue) => !previousValue);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationTitle: "Confirmation",
      confirmationText: "Do you want to proceed with PIN change?",
      closeLabel: "Cancel",
      confirmButtonText: "Yes",
      onClose: openModal,
      onConfirm: pinChange,
    };

    return (
      <MaterialModal open={true} handleClose={openModal} width={"25rem"}>
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  return cards && cards?.length > 0 ? (
    <ContentBox className="card-operations-container">
      {selectedCard && (
        <Fragment>
          <CardRestrictions props={props} />

          <Collapse
            className={`card-operations-collapse-container ${
              isAccordionOpen ? "collapse-card-open" : ""
            }`}
            defaultActiveKey="1"
            onChange={(accordion: any) => handleAccordionChange(accordion)}
            bordered={false}
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              isActive ? (
                <img src={`${imagePath}/panel-expanded-icon.png`} alt="icon" />
              ) : (
                <img src={`${imagePath}/panel-expand-icon.png`} alt="icon" />
              )
            }
          >
            <Panel
              header={
                <CustomHeader
                  title={getLabel("changePinLabel")}
                  subTitle={getLabel("changePinContent")}
                  className="card-operation-collapse-header"
                />
              }
              key="1"
            >
              {renderConfirmModal()}
              <Form
                layout="vertical"
                className="card-operations-form-container"
                form={form}
                onFinish={openModal}
              >
                <Row gutter={[24, 24]}>
                  <Col md={6} xs={24}>
                    <Form.Item
                      name="password"
                      className="card-operation-form-item"
                      validateStatus={fieldErrors.password ? "error" : ""}
                      help={fieldErrors.password}
                    >
                      <Input.Password
                        type="password"
                        className="password-input-field"
                        bordered={false}
                        value={fieldValues.password}
                        onChange={(event) =>
                          onFieldChange("password", event.target.value, true)
                        }
                        placeholder="Password"
                        visibilityToggle={{
                          visible: showPassword,
                          onVisibleChange: togglePasswordVisibility,
                        }}
                        iconRender={(visible) =>
                          visible ? (
                            <img
                              src={`${imagePath}/visibility-Copy.png`}
                              alt="Eye icon"
                            />
                          ) : (
                            <img
                              src={`${imagePath}/Shape.png`}
                              alt="Eye icon"
                            />
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      name="pin"
                      label=""
                      className="card-operation-form-item"
                      validateStatus={fieldErrors.pin ? "error" : ""}
                      help={fieldErrors.pin}
                    >
                      <Input.Password
                        className="password-input-field"
                        bordered={false}
                        type="text"
                        value={fieldValues.pin}
                        maxLength={4}
                        onChange={(event) =>
                          onFieldChange("pin", event.target.value, true)
                        }
                        placeholder="New PIN"
                        visibilityToggle={{
                          visible: showPassword,
                          onVisibleChange: togglePasswordVisibility,
                        }}
                        iconRender={(visible) =>
                          visible ? (
                            <img
                              src={`${imagePath}/visibility-Copy.png`}
                              alt="Eye icon"
                            />
                          ) : (
                            <img
                              src={`${imagePath}/Shape.png`}
                              alt="Eye icon"
                            />
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      name="confirmPin"
                      className="card-operation-form-item"
                      validateStatus={fieldErrors.confirmPin ? "error" : ""}
                      help={fieldErrors.confirmPin}
                    >
                      <Input.Password
                        className="password-input-field"
                        bordered={false}
                        type="text"
                        maxLength={4}
                        value={fieldValues.confirmPin}
                        onChange={(event) =>
                          onFieldChange("confirmPin", event.target.value, true)
                        }
                        placeholder="Confirm New PIN"
                        visibilityToggle={{
                          visible: showPassword,
                          onVisibleChange: togglePasswordVisibility,
                        }}
                        iconRender={(visible) =>
                          visible ? (
                            <img
                              src={`${imagePath}/visibility-Copy.png`}
                              alt="Eye icon"
                            />
                          ) : (
                            <img
                              src={`${imagePath}/Shape.png`}
                              alt="Eye icon"
                            />
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="change-pin-btn-container">
                  <CustomButton
                    type="outline"
                    htmltype="submit"
                    className="change-pin-btn"
                    disabled={loading}
                    text="Change PIN"
                  />
                  {loader && <Loader className="pin-loader" />}
                </div>
              </Form>
            </Panel>
          </Collapse>
        </Fragment>
      )}
    </ContentBox>
  ) : (
    <ContentBox>
      <div className="text-center mt-4">
        <h4>No Cards Available</h4>
        <Button type="link" size="large">
          Go Back
        </Button>
      </div>
    </ContentBox>
  );
};
export default CardOperations;
