import React, { useState } from "react";
import { Form, Input } from "antd";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import { errorMessage, fieldValidator } from "../../../utils/validation";
import {sendCodeOnEmail} from "../../../apis/auth_api";
import {
  getPlanePhoneNumber,
  phoneInstance,
  showErrorNotification,
} from "../../../utils/common-utils";
import MuiPhoneNumber from "material-ui-phone-number";
import Loader from "../../shared/loader";
import "react-phone-number-input/style.css";
import useStyles from "../../../hooks/use-styles";
import axiosAPI from "../../../utils/axios";
import customToast from "../../shared/services/toaster-service";

const Verification = (props: any) => {
  const { onFormSubmit, formData } = props;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [fieldValues, setFieldValues] = useState<any>({
    phoneNumber: formData.phoneNumber || "",
  });

  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const validate = (key: string, value: string, options?: any) => {
    const errors = { ...fieldErrors };
    if (!value && options) {
      errors[key] = errorMessage.required;
    } else {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);
  };
  
  const validFields = async () => {
    const requiredFields = [
      "phoneNumber",
      "email",
    ];
    const errors = {} as any;
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        valid = false;
        errors[field] = errorMessage.required;
      }
    });
    if (fieldValues.phoneNumber.startsWith('+974')) {
      if (fieldValues.phoneNumber.length < 12) {
        errors["phoneNumber"] = "Phone number should be at least 11 characters.";
        valid = false;
      } else {
        delete errors["phoneNumber"];
      }
    } else if (!fieldValidator.phoneNumber(fieldValues.phoneNumber)) {
      errors["phoneNumber"] = errorMessage["phoneNumber"];
      valid = false;
    }

    if (!fieldValidator.email(fieldValues.email)) {
      valid = false;
      errors["email"] = errorMessage.email;
    }

    if (valid) {
      const isValidEmail = await checkIfValidEmail(fieldValues.email);

      const phoneNumberInstance = phoneInstance().parseAndKeepRawInput(fieldValues.phoneNumber, "");
      const phoneNumber = getPlanePhoneNumber(phoneNumberInstance.getCountryCode(), phoneNumberInstance.getNationalNumber().toString());

      const isValidMobileNumber = await checkIfValidMobileNumber(phoneNumber);
      valid = isValidEmail && isValidMobileNumber;
    }

    setFieldErrors(errors);
    return valid;
  };

  const checkIfValidEmail = async (email: string): Promise<boolean> => {
    let isValid = false;
    setLoader(true);
    try {
      const resp = await axiosAPI.get(
        `/identity/email/${email}/inspect`
      );
      setLoader(false);

      if (resp.data.exist === "true") {
        customToast.error("Email already taken");
      } else {
        isValid = true;
      }
    } catch (error: any) {
      console.log("error", error);
      showErrorNotification(error);
      setLoader(false);
    }
    return isValid;
  }

  const checkIfValidMobileNumber = async (mobile: string): Promise<boolean> => {
    let isValid = false;
    setLoader(true);
    try {
      const resp = await axiosAPI.get(
        `/identity/mobile/${mobile}/inspect`
      );
      setLoader(false);

      if (resp.data.exist === "true") {
        customToast.error("Mobile number already taken");
      } else {
        isValid = true;
      }
    } catch (error: any) {
      console.log("error", error);
      showErrorNotification(error);
      setLoader(false);
    }
    return isValid;
  }
  
  const onFieldChange = (key: string, value: any, options?: any) => {
    validate(key, value, options);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const sendCodeAuth = () => {
    setLoader(true);
    sendCodeOnEmail(fieldValues.email, "SU")
      .then(() => {
        setLoader(false);
        const phoneNumberInstance = phoneInstance().parseAndKeepRawInput(
          fieldValues.phoneNumber,
          ""
        );
        const phoneNumber = getPlanePhoneNumber(phoneNumberInstance.getCountryCode(), phoneNumberInstance.getNationalNumber().toString());

        onFormSubmit({
          ...fieldValues,
          email: fieldValues.email,
          countryDialingCode: phoneNumberInstance.getCountryCode(),
          nationalPhoneNumber: phoneNumberInstance.getNationalNumber().toString(),
          phoneNumber,
          phoneType: "MOBILE",
        });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        showErrorNotification(error);
      });
  };

  const handleNextFn = async () => {
    if (await validFields()) {
      sendCodeAuth();
    }
  };
  const { signUpTitleColor } = useStyles([
    "signUpTitleColor",
  ]);

  return (
    <div className="verification-screen-container">
      <EnrollmentHeader
        title={"Lets Begin!"}
        titleColor={signUpTitleColor}
      />
      <div className="login-form">
        <Form
          name="login"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          className="form-style"
        >
          <Form.Item
            name="phoneNumber"
            className="custom-form-item mb-3"
            label={"Enter your mobile number"}
            validateStatus={fieldErrors.phoneNumber ? "error" : ""}
            help={fieldErrors.phoneNumber}
          >
            <div>
              <MuiPhoneNumber
                className="input.ant-input phone-number-container"
                defaultCountry={"ae"}
                onlyCountries={["gb", "ae", "ca", "in", "pk", "us", "qa", "sa", "my"]}
                countryCodeEditable={false}
                onChange={(value) => onFieldChange('phoneNumber', value, true)}
                value={fieldValues.phoneNumber}
                disableAreaCodes={true}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="email"
            label={"Enter you email"}
            className="custom-form-item mb-1"
            validateStatus={fieldErrors.email ? "error" : ""}
            help={fieldErrors.email}
          >
            <Input
              bordered={false}
              onChange={(event) =>
                onFieldChange("email", event.target.value, true)
              }
              value={fieldValues.email}
              autoComplete="off"
              placeholder="Email"
              maxLength={100}
            />
          </Form.Item>

          {loader && <Loader className="custom-loader verification-loader" />}

          <SignUpFlexButtons className="layout-buttons justify-start verification-buttons-container direction-row-reverse">
            <SignUpCustomButton
              type="primary"
              className="next-button action-button"
              disabled={loading || loader}
              onClick={handleNextFn}
              htmltype="submit"
              text="Next"
            />

            <SignUpCustomButton
              type="outline"
              disabled={loader}
              className="cancel-button action-button"
              onClick={props.openModal}
              text="Cancel"
            />
          </SignUpFlexButtons>
        </Form>
      </div>
    </div>
  );
};

export default Verification;
