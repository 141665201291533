import { FC, Fragment, useEffect, useState } from "react";
import { Col, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";

import CustomButton from "../../../../../components/custom-button";
import FlexButtons from "../../../../../components/flex-button";

import ContentBox from "../../../../../components/content-box";
import customToast from "../../../../../components/shared/services/toaster-service";
import {
  prepareAccountOptions,
  prepareWalletOptions,
  santizeInvalidCharacter,
} from "./transfer-form-utils";
import moment from "moment";

import useLabels, { getLabel } from "../../../../../hooks/use-labels";
import useStyles from "../../../../../hooks/use-styles";
import "./styles.css";
import {
  updateErrorField,
  updateFieldValues,
  validExchangeRates,
} from "./hooks";
import { showErrorNotification } from "../../../../../utils/common-utils";
import Loader from "../../../../../components/shared/loader";

interface TransferFormProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onClose: () => void;
}

const { Option } = Select;

const TransferForm: FC<TransferFormProps> = ({
  transferValues,
  onSubmit,
  onClose,
}) => {
  const { blackColor } = useStyles(["blackColor"]);

  const {
    transferTitle,
    betweenAccountsTagLine,
    transferAmountTitle,
    transferFromTitle,
    transferToTitle,
    transferTransferDateTitle,
  } = useLabels([
    "transferTitle",
    "betweenAccountsTagLine",
    "transferAmountTitle",
    "transferFromTitle",
    "transferToTitle",
    "transferTransferDateTitle",
  ]);

  const { userProfile } = useSelector((state: any) => state.users);

  const { accounts } = userProfile;

  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [loading, setLoading] = useState<boolean>(true);

  const [accountOptionList, setAccountOptionList] = useState([]) as any;
  const [fromWalletOptions, setFromWalletOptions] = useState([]) as any;
  const [toWalletOptions, setToWalletOptions] = useState([]) as any;

  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [toCurrency, setToCurrency] = useState<string>("");
  const [mainAccountId, setMainAccountId] = useState<string>("");

  const [toSubAccountId, setToSubAccountId] = useState("to");
  const [fromSubAccountId, setFromSubAccountId] = useState("from");

  const required = true;

  const isSubAccountSame = toSubAccountId === fromSubAccountId ? true : false;

  const errorMessage: any = {
    required: "REQUIRED",
    minimumAmount: "Minimum_Amount",
  };

  const [fieldValues, setFieldValues] = useState({
    amount: transferValues.amount || null,
    fromMainAccount: transferValues.fromMainAccount || null,
    fromSubAccount: transferValues.fromSubAccount || null,
    toMainAccount: transferValues.toMainAccount || null,
    toSubAccount: transferValues.toSubAccount || null,
    memo: transferValues.memo || null,
    fromCurrency: defaultCurrency || null,
    toCurrency: toCurrency || null,
    convertedAmount: null,
    exchangeRateLine: null,
  }) as any;

  useEffect(() => {
    if (Array.isArray(accounts) && accounts.length) {
      setAccountOptionList(prepareAccountOptions(accounts));
      setFromWalletOptions(prepareWalletOptions("", accounts));
      setMainAccountId(accounts[0].id);
      setLoading(false);
    }
  }, [accounts]);

  const errMessage = "This is a required field";

  const resetToMainAccount = (key?: string) => {
    if (key === "toMainAccount") {
      setFieldErrors({});
    } else {
      setToWalletOptions([]);
      setFieldValues((prev: any) => ({
        ...prev,
        toMainAccount: null,
        toSubAccount: null,
      }));

      if (key === "fromMainAccount") {
        setFieldErrors({});
        setToSubAccountId("to");
        setFromSubAccountId("from");
      }
    }
  };



  const onChange = (key: string, value: string, required: boolean) => {
   
    if(key === "amount"){
      const sanitizedValue = value.replace(/[+\-e]/gi, '');

      const regex = /^\d{0,12}(\.\d{0,4})?$/;
      if (value && !regex.test(sanitizedValue)) return;
    }

    let errors: any = { ...fieldErrors };

    let updateMainAccId = "";
   
    if (key === "fromMainAccount") {
      updateMainAccId = value;
      setMainAccountId(value);
      setDefaultCurrency("");
    } else {
      updateMainAccId = mainAccountId;
    }

    const walletOptions =
      ["fromMainAccount", "toMainAccount"].includes(key) &&
      prepareWalletOptions(
        value,
        accounts,
        key,
        defaultCurrency,
        updateMainAccId
      );

    if (
      key === "toMainAccount" &&
      Array.isArray(walletOptions) &&
      !walletOptions.length
    ) {
      setFieldErrors(updateErrorField("toSubAccount", errors));
      errors = updateErrorField("toSubAccount", errors);
    }

    const setWalletOptions: any = {
      fromMainAccount: () => setFromWalletOptions(walletOptions),
      toMainAccount: () => setToWalletOptions(walletOptions),
    };

    if (key === "toSubAccount") {
      setToSubAccountId(value);
    }

    if (key === "fromSubAccount") {
      setFromSubAccountId(value);
    }

    const resetWalletValue: any = {
      fromMainAccount: {
        fromSubAccount:
          walletOptions.length === 1 ? walletOptions[0].value : null,
      },
      toMainAccount: {
        toSubAccount:
          walletOptions.length === 1 ? walletOptions[0].value : null,
      },
    };

    setWalletOptions[key] && setWalletOptions[key]();

    if (typeof value === "string" && !value.trim() && required) {
      errors[key] = errorMessage.required;
    } else {
      delete errors[key];
    }

    setFieldErrors(errors);

    setFieldValues((prev: any) => ({
      ...prev,
      [key]: value,
      ...(resetWalletValue[key] && resetWalletValue[key]),
    }));
  };

  const setCurrencyHandler = (
    key: string,
    options: Array<any>,
    fieldKey: string
  ) => {
    if (key === "toCurrency") {
      setToCurrency(options[0].currency);
      setFieldErrors(updateErrorField(fieldKey, fieldErrors));
    }

    if (key === "fromCurrency") {
      setDefaultCurrency(options[0].currency);
      setFieldErrors(updateErrorField(fieldKey, fieldErrors));
    }
  };

  const isValidFields = () => {
    let isValid = true;

    const errors: any = { ...fieldErrors };

    const requiredFields = [
      "amount",
      "fromMainAccount",
      "fromSubAccount",
      "toMainAccount",
      "toSubAccount",
    ];

    requiredFields.forEach((field) => {
      if (!fieldValues[field as keyof typeof fieldValues]) {
        errors[field] = errorMessage.required;
        isValid = false;
      }
    });
    setFieldErrors(errors);
    return isValid;
  };

  const isValidAmount = () => {
    const errors: any = { ...fieldErrors };

    if (fieldValues.amount && +fieldValues.amount <= 0) {
      errors.amount = errorMessage.minimumAmount;
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (isSubAccountSame === false) {
      const validFields = isValidFields();
      const validAmount = isValidAmount();

      if (validFields && validAmount) {
        validExchangeRates(defaultCurrency, toCurrency, fieldValues.amount)
          .then((response: any) => {
            if (response === undefined) {
              throw new Error("Exchange rate not supported");
            }
            const { convertedAmount, exchangeRateLine } = response;
            const updatedFiedlValue = updateFieldValues(
              fieldValues,
              defaultCurrency,
              toCurrency,
              convertedAmount,
              exchangeRateLine
            );
            onSubmit({ ...updatedFiedlValue, accounts });
          })
          .catch((error: any) => {
            showErrorNotification(error);
          });
      } else if (!validFields) {
        customToast.error("Please provide all required fields");
      } else if (!validAmount) {
        customToast.error("Amount must be greater than 0");
      }
    }
    if (isSubAccountSame === true) {
      customToast.error("From and To subaccount must be different");
    }
  };

  if (loading) return <Loader />;

  return (
    <ContentBox className="between-account-container">
      <div className="between-account-header">
        <div className="between-account-header-title">{transferTitle}</div>
        <div className="between-account-header-subtitle">
          {betweenAccountsTagLine}
        </div>
      </div>

      <div className="between-account-form-container">
        <Row className="between-account-content">
          <Col lg={3} md={3} sm={3} className="between-account-label">
            {transferFromTitle}
          </Col>
          <Col
            lg={21}
            md={21}
            sm={21}
            className="between-account-select-container"
          >
            <Row gutter={[40, 40]}>
              <Col>
                {accountOptionList.length && (
                  <Select
                    id="fromMainAccount"
                    className="between-account-select-dropdown"
                    dropdownStyle={{
                      color: blackColor,
                    }}
                    placeholder="Select Account"
                    onSelect={(value: any) => {
                      const requiredField = value === "" ? false : required;
                      onChange("fromMainAccount", value, requiredField);
                      resetToMainAccount("fromMainAccount");
                    }}
                    bordered={false}
                    value={fieldValues.fromMainAccount}
                  >
                    {accountOptionList.map(
                      (option: any, index: number | string) => {
                        return (
                          <Option key={index} value={option.value}>
                            {option.label}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                )}
                {fieldErrors["fromMainAccount"] && (
                  <p className="error-text">{errMessage}</p>
                )}
              </Col>
              <Col>
                {fromWalletOptions.length > 1 ||
                !fieldValues.fromMainAccount ? (
                  <Select
                    id="fromSubAccount"
                    className="between-account-select-dropdown"
                    placeholder="Select Wallet"
                    onSelect={(value: any, record) => {
                      onChange("fromSubAccount", value, required);
                      setDefaultCurrency(record.currency);
                      resetToMainAccount("fromSubAccount");
                    }}
                    bordered={false}
                    value={fieldValues.fromSubAccount}
                    dropdownStyle={{ color: blackColor }}
                  >
                    {fromWalletOptions.map((item: any, index: any) => {
                      const { icon, value, currency, label } = item;
                      return (
                        <Option
                          style={{ color: blackColor }}
                          key={index}
                          value={value}
                          currency={currency}
                        >
                          {icon && (
                            <img
                              src={item.icon}
                              alt={item.currency}
                              className="wallet-option-flag"
                            />
                          )}
                          {label}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <div className="account-field-preview">
                    {fromWalletOptions.length ? (
                      <Fragment>
                        {fromWalletOptions[0] && (
                          <img
                            src={fromWalletOptions[0].icon}
                            alt={fromWalletOptions[0].currency}
                            className="wallet-option-flag"
                          />
                        )}
                        {fromWalletOptions[0].label}
                        {!defaultCurrency &&
                          setCurrencyHandler(
                            "fromCurrency",
                            fromWalletOptions,
                            "fromSubAccount"
                          )}
                      </Fragment>
                    ) : (
                      "-"
                    )}
                  </div>
                )}
                {fieldErrors["fromSubAccount"] && (
                  <p className="error-text">{errMessage}</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="between-account-content">
          <Col lg={3} md={3} sm={3} className="between-account-label">
            {transferToTitle}
          </Col>
          <Col
            lg={21}
            md={21}
            sm={21}
            className="between-account-select-container"
          >
            <Row gutter={[40, 40]}>
              <Col>
                <Select
                  id="toMainAccount"
                  className="between-account-select-dropdown"
                  placeholder="Select Account"
                  onSelect={(value: any) => {
                    const requiredField = value === "" ? false : required;
                    onChange("toMainAccount", value, requiredField);
                    resetToMainAccount("toMainAccount");
                  }}
                  bordered={false}
                  value={fieldValues.toMainAccount}
                  dropdownStyle={{ color: blackColor }}
                  options={prepareAccountOptions(userProfile.accounts)}
                ></Select>
                {fieldErrors["toMainAccount"] && (
                  <p className="error-text">{errMessage}</p>
                )}
              </Col>
              <Col>
                {toWalletOptions.length > 1 || !fieldValues.toMainAccount ? (
                  <Select
                    id="toSubAccount"
                    className="between-account-select-dropdown"
                    placeholder="Select Wallet"
                    onSelect={(value: any, record) => {
                      onChange("toSubAccount", value, required);
                      setToCurrency(record.currency);
                    }}
                    bordered={false}
                    value={fieldValues.toSubAccount}
                  >
                    {toWalletOptions.map((item: any, index: any) => {
                      const { icon, currency, value, label } = item;
                      return (
                        <Option
                          style={{ color: blackColor }}
                          key={index}
                          value={value}
                          currency={item.currency}
                          icon={icon}
                        >
                          {icon && (
                            <img
                              src={icon}
                              alt={currency}
                              className="wallet-option-flag"
                            />
                          )}
                          {label}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <div className="account-field-preview">
                    {toWalletOptions.length ? (
                      <Fragment>
                        {toWalletOptions[0] && (
                          <img
                            src={toWalletOptions[0].icon}
                            alt={toWalletOptions[0].currency}
                            className="wallet-option-flag"
                          />
                        )}
                        {toWalletOptions[0].label}
                        {!toCurrency &&
                          setCurrencyHandler(
                            "toCurrency",
                            toWalletOptions,
                            "toSubAccount"
                          )}
                      </Fragment>
                    ) : (
                      getLabel("noWalletExist")
                    )}
                  </div>
                )}
                {fieldErrors["toSubAccount"] && (
                  <p className="error-text">{errMessage}</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="between-account-content">
          <Col lg={3} md={3} sm={3} className="between-account-label">
            {transferAmountTitle}
          </Col>
          <Col
            lg={21}
            md={21}
            sm={21}
            className="between-account-input-container"
          >
            <Input
              type="number"
              className={`between-account-amount-input-field ${
                !defaultCurrency ? "disabled-account-amount-field" : ""
              }`}
              pattern="/^-?\d*(\.\d*)?$/"
              prefix={
                defaultCurrency ? (
                  <span className="prefix-default-currency">
                    {defaultCurrency}
                  </span>
                ) : (
                  <span />
                )
              }
              placeholder="0.00"
              bordered={false}
              required={true}
              id="accountId"
              onChange={(event: any) =>
                onChange("amount", event.target.value, required)
              }
              onKeyDown={santizeInvalidCharacter}
              value={fieldValues.amount}
              disabled={defaultCurrency ? false : true}
            />
            {fieldErrors["amount"] && (
              <div className="error-text">{errMessage}</div>
            )}
          </Col>
        </Row>

        <div className="memo-container">
          <Row>
            <Col lg={24} md={24}>
              <Input
                className="memo-input-field"
                bordered={false}
                maxLength={255}
                placeholder="Memo (Optional)"
                value={fieldValues.memo}
                onChange={({ target }: any) =>
                  onChange("memo", target.value, false)
                }
              />
            </Col>
          </Row>
        </div>
        <div className="transfer-date-container">
          <Row>
            <Col lg={18} md={18} sm={18}>
              <div className="transfer-date-content">
                <div className="transfer-date-icon">
                  <img src="/assets/images/calendar-solid.png" alt="calendar" />
                </div>

                <div className="transfer-date-meta">
                  <div className="transfer-date-meta-title">
                    {transferTransferDateTitle}
                  </div>
                  <div className="transfer-date-meta-format">
                    {moment().format("MM/DD/YYYY")}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <FlexButtons>
                <CustomButton
                  type="outline"
                  text="Cancel"
                  onClick={onClose}
                  className="button-md primary-outline-btn"
                />
                <CustomButton
                  type="primary"
                  text="Continue"
                  disabled={!!Object.keys(fieldErrors).length}
                  onClick={handleSubmit}
                  className="button-md primary-black-btn"
                />
              </FlexButtons>
            </Col>
          </Row>
        </div>
      </div>
    </ContentBox>
  );
};
export default TransferForm;
