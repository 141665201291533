import React from "react";
import useStyles from "../../hooks/use-styles";

const OutlineButton = (props: any) => {
  const { text, size, style, className, ...buttonProps } = props;

  const {
    secondaryButtonLabelColor,
    secondaryButtonBackground,
    secondaryButtonBorder,
  } = useStyles([
    "secondaryButtonLabelColor",
    "secondaryButtonBackground",
    "secondaryButtonBorder",
  ]);
  return (
    <button
      className={`custom-button ${className!} ${size ? "button-" + size : ""}`}
      style={{
        backgroundColor: secondaryButtonBackground,
        border: `1px solid ${secondaryButtonBorder}`,
        color: secondaryButtonLabelColor,
        ...style,
      }}
      {...buttonProps}
    >
      {text}
    </button>
  );
};

export default OutlineButton;
