import React from "react";
import "./style.header.css";
import useStyles from "../../../hooks/use-styles";
import { Col, Row } from "antd";

interface EnrollmentHeaderProps {
  title?: string;
  titleColor?: string;
  subTitle?: string;
  subTitleColor?: string;
  className?: string;
  subTitleClass?: string;
  titleClass?: string;
  backbutton?: boolean;
  backClick?: any;
  titleColSize?: number;
}
const EnrollmentHeader = (props: EnrollmentHeaderProps): JSX.Element => {
  const {
    titleColor,
    subTitleColor,
    title,
    subTitle,
    className,
    subTitleClass,
    titleClass,
    backbutton,
    titleColSize,
  } = props;
  const { titleColor: defaultTitleColor, subTitleColor: defaultSubtitleColor } =
    useStyles(["titleColor", "subTitleColor"]);

  return (
    <div className={`header ${className!}`}>
      {backbutton ? (
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col md={2} xs={24} className="text-align-last back-button-container">
            {backbutton && (
              <img
                src={require("./keyboard_arrow_left.png")}
                width={"12px"}
                height={"21px"}
                className="mt-1 cursor"
                onClick={props.backClick}
                alt="backarrow"
              />
            )}
          </Col>
          <Col md={titleColSize ? titleColSize : 22} xs={24}>
            {title && (
              <h2
                className={`h2 title-font text-left ${titleClass!}`}
                style={{ color: titleColor || defaultTitleColor }}
              >
                {title}
              </h2>
            )}
            {subTitle && (
              <p
                className={`subTitle-font text-left ${subTitleClass!}`}
                style={{ color: subTitleColor || defaultSubtitleColor }}
              >
                {subTitle}
              </p>
            )}
          </Col>
        </Row>
      ) : (
        <div>
          <h2
            className={`h2 title-font text-left ${titleClass!}`}
            style={{ color: titleColor || defaultTitleColor }}
          >
            {title}
          </h2>
          <p
            className={`subTitle-font text-left ${subTitleClass!}`}
            style={{ color: subTitleColor || defaultSubtitleColor }}
          >
            {subTitle}
          </p>
        </div>
      )}
    </div>
  );
};

export default EnrollmentHeader;
