import { getLabel } from "../../../hooks/use-labels";
import moment from "moment";
const currentYear = moment().year();

interface FooterProps {
  key?: string;
  label?: any;
  linksArray?: Array<object>;
  className?: string;
  colSize?: number | null;
}

export const footerMetaData = (): FooterProps[] => {
  return [
    {
      key: "copry-rights",
      label: `©${currentYear} ${getLabel("copyRightsText")}`,
      linksArray: [],
      className: "footer-text copyright-container",
      colSize: null,
    },
    {
      key: "powered-by",
      label: getLabel("poweredByContent"),
      linksArray: [],
      className: "footer-text powered-by",
      colSize: null,
    },
    {
      key: "footer-links",
      label: null,
      linksArray: footerLinks(),
      className: "footer-text footer-links-container",
      colSize: null,
    },
  ];
};

export const footerLinks = (): Array<object> => {
  return [
    {
      key: "privacy-policy",
      label: getLabel("privacyLinkLabel"),
      path: getLabel("privacyPolicyLink"),
      linkClass: "footer-text privacy-policy",
      divider: "|",
      footerSeparator: "footer-separator",
    },
    {
      key: "terms-and-conditions",
      label: getLabel("termsAndConditionsLinkLabel"),
      path: getLabel("termsAndConditionsLink"),
      linkClass: "footer-text terms-and-conditions",
      divider: "",
      footerSeparator: "footer-separator",
    },
  ];
};
