import { Row, Col } from "antd";
import { FC } from "react";

import TransferForm from "./TransferForm";

interface TransferAmountProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onClose: () => void;
  id: any;
  nickName: any;
  fromWalletCurrency: any;
  type: any;
}

const TransferAmount: FC<TransferAmountProps> = (props) => {
  return (
    <Row gutter={[16, 16]}>
      <Col md={24} xs={24}>
        <TransferForm {...props} />
      </Col>
    </Row>
  );
};
export default TransferAmount;
