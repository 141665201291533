import { Input, Modal, Form, Spin, Empty } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { fetchFriendFamilyData } from "../../../../apis/b2c_api";
import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import {
  accountLastFour,
  padLastFourHtmlTag,
  showErrorNotification,
} from "../../../../utils/common-utils";
import { getCountryCode } from "../../../../utils/reUseFunction";
import { AiFillPlusCircle } from "react-icons/ai";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";
import Congratulations from "./Congratulations";
import NickNameModal from "./NickNameModal";
import OPTModal from "./OPTModal";
import { useDispatch } from "react-redux";
import { selfAccountDetails } from "./add-recipient-utils";
import { logoutUser } from "../../../../app-store/user/user-reducer";

interface AddRecipientModalProps {
  setOpenDrawer: any;
  getRecipientDetails: Function;
}

const AddRecipientModal = ({
  setOpenDrawer,
  getRecipientDetails,
}: AddRecipientModalProps) => {
  const [optModal, setOptModal] = useState(false);
  const [congratulationsModal, setCongratulationsModal] = useState(false);
  const [nickNameModal, setNickNameModal] = useState(false);
  const [data, setData] = useState([]) as any;
  const [recipientData, setRecipientData] = useState([]) as any;
  const [userProfileData, setUserProfileData] = useState();
  const [accountId, setAccountId] = useState();
  const [cardId, setCardId] = useState();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const phoneNumberIntl = value && formatPhoneNumberIntl(value);
  const dispatch = useDispatch();

  const { greyColor, darkBlueColor, tagColor } = useStyles([
    "greyColor",
    "darkBlueColor",
    "tagColor",
  ]);

  const {
    addRecipientTitle,
    addRecipientDescription,
    searchRecipientButtonTitle,
  } = useLabels([
    "addRecipientTitle",
    "addRecipientDescription",
    "searchRecipientButtonTitle",
  ]);

  useEffect(() => {
    getRecipient();
  }, []);

  const getRecipient = () => {
    const recipientbody = {
      countryCode: "",
      email: "",
      firstName: "",
      lastName: "",
      limit: 0,
      offset: 0,
      phoneNumber: "",
      ddaNumber: "",
    };
    fetchFriendFamilyData(recipientbody)
      .then((RecipientList) => {
        setRecipientData(RecipientList.findConsumers.nodes);
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const getFriendFamilyDetails = (values: any) => {
    const countryCodeObj: any = getCountryCode(phoneNumberIntl);
    if ((!countryCodeObj || !countryCodeObj.number) && !values.dda) {
      if (data) {
        setData([]);
        setShowNoDataMessage(true);
        return;
      }
      return;
    } else {
      const body = {
        countryCode: countryCodeObj ? countryCodeObj.countryCode : "",
        limit: 0,
        offset: 0,
        phoneNumber: countryCodeObj
          ? countryCodeObj.number.replace(/\s/g, "")
          : "",
        ddaNumber: values.dda ? values.dda : "",
      };
      setLoading(true);

      fetchFriendFamilyData(body)
        .then((FriendFamily: any) => {
          const { findConsumers } = FriendFamily || {
            findConsumers: { nodes: [] },
          };

          const { nodes } = findConsumers || { nodes: [] };
          const { profileDeleted, filteredAccounts } =
            selfAccountDetails(nodes);

          if (profileDeleted === "profile-deleted") dispatch(logoutUser());

          if (filteredAccounts.length) {
            setData(filteredAccounts);
            setLoading(false);
          } else {
            setData([]);
            setShowNoDataMessage(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          showErrorNotification(error);
          setLoading(false);
        });
    }
  };

  const toggleModal = () => {
    setNickNameModal(false);
  };

  const accountCardFetch = (userData: any, data: any) => {
    if (data?.id) {
      setAccountId(data.id);
    }
    setUserProfileData(userData);
    setNickNameModal(true);
  };

  return (
    <Fragment>
      <div className="add-recipient-section">
        <div className="header-title">
          <p
            className="text-left text-2lg weight-600"
            style={{ color: "#2c2a29" }}
          >
            {addRecipientTitle}
          </p>
          <p
            className="text-left text-sm weight-400"
            style={{ color: "#2c2a29b3" }}
          >
            {addRecipientDescription}
          </p>
        </div>
        <div className="add-recipient-input">
          <Form onFinish={getFriendFamilyDetails}>
            <Form.Item name="dda">
              <Input type="number" placeholder="Enter your account number" />
            </Form.Item>
            <Form.Item name="number">
              <PhoneInput
                className="phone-number-input"
                international
                addInternationalOption={false}
                value={value}
                countryCallingCodeEditable={false}
                defaultCountry="AE"
                placeholder="+00 001 100100"
                initialValueFormat="national"
                error={
                  value
                    ? isValidPhoneNumber(value)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
                onChange={(e: any) => setValue(e)}
              />
            </Form.Item>
            <Form.Item>
              <FlexButtons className="search-recipient-button mb-32">
                <CustomButton
                  className="apply-filter-btn-add-friend ant-btn-default"
                  type="primary"
                  text={searchRecipientButtonTitle}
                  htmltype="submit"
                />
              </FlexButtons>
            </Form.Item>
          </Form>
        </div>
        <div className="recipient-list">
          <div className="recipient-list-style">
            {loading ? (
              <Spin tip="Loading...">
                <div className="content" />
              </Spin>
            ) : data && data.length > 0 ? (
              data?.map((items: any, index: number) => {
                const { firstName, lastName, email, phoneDetails } = items || {
                  phoneDetails: [],
                };

                const { length: phDetailLength } = phoneDetails || {
                  length: 0,
                };

                const { countryDialingCode, phoneNumber } = phoneDetails[0] || {
                  countryDialingCode: "",
                  phoneNumber: "",
                };

                const { accounts } = items || { accounts: [] };
                const { length: accLength } = accounts || { length: 0 };

                return (
                  <Fragment key={index}>
                    {accLength > 0 &&
                      accounts.map((account: any, index: number) => {
                        const { dda } = account;

                        return (
                          <div key={index} className="recipient-users-list">
                            <div className="recipient-users-list-style">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="initials-friends-and-family-add-recipient">
                                  {(firstName[0] + lastName[0]).toUpperCase()}
                                </div>
                              </div>

                              <div className="recipient-users-info">
                                <p style={{ margin: "0" }}>
                                  {firstName} {lastName}
                                </p>

                                {dda && (
                                  <span
                                    className="account-number-detail add-beneficiary-list-details"
                                    dangerouslySetInnerHTML={{
                                      __html: `Account Number: ${padLastFourHtmlTag(
                                        accountLastFour(dda)
                                      )}`,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              className="plus-image"
                              onClick={() => accountCardFetch(items, account)}
                            >
                              <AiFillPlusCircle fontSize={35} />
                            </div>
                          </div>
                        );
                      })}
                  </Fragment>
                );
              })
            ) : showNoDataMessage ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No beneficiary found"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {(optModal || congratulationsModal || nickNameModal) && (
        <Modal
          className="opt-ant-modal"
          open={true}
          onOk={toggleModal}
          centered
        >
          {optModal && (
            <OPTModal
              setOptModal={setOptModal}
              setCongratulationsModal={setCongratulationsModal}
              userProfileData={userProfileData}
            />
          )}

          {congratulationsModal && (
            <Congratulations
              setOpenDrawer={setOpenDrawer}
              setCongratulationsModal={setCongratulationsModal}
            />
          )}

          {nickNameModal && (
            <NickNameModal
              setNickNameModal={setNickNameModal}
              setOptModal={setOptModal}
              userProfileData={userProfileData}
              setOpenDrawer={setOpenDrawer}
              accountId={accountId}
              cardId={cardId}
              getRecipientDetails={getRecipientDetails}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default AddRecipientModal;
