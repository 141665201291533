import moment from "moment";
import { formatCurrency } from "../../../../utils/common-utils";

export const getTotalBalance = (account: any) => {
  if (account) {
    const { aggregatedAvailableBalance } = account;
    const { amount, currency } = aggregatedAvailableBalance;

    return formatCurrency(+amount, currency);
  }

  return "-";
};

export const getLastDebit = (account: any) => {
  if (account) {
    const { debit } = account;
    const { amount, currency } = debit;

    return amount ? formatCurrency(amount * -1, currency) : "-";
  }

  return "-";
};

export const getLastCredit = (account: any) => {
  if (account) {
    const { credit } = account;
    const { amount, currency } = credit;

    return amount ? formatCurrency(amount, currency) : "-";
  }

  return "-";
};

export const getTypeDate = (account: any, type: string) => {
  if (account) {
    const { date } = account[type];

    return date ? moment(date).format("MM/DD/YYYY") : "-";
  }

  return "-";
};

export const getCardStatus = (status: string) => {
  if (!status) {
    return status;
  }
  return status.toLowerCase();
}