import useStyles from "../../../hooks/use-styles";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import React, { useState } from "react";
import { createAccount } from "../../../apis/auth_api";
import { showErrorNotification } from "../../../utils/common-utils";
import {login, loginWithAuth} from "../../../app-store/user/user-action";
import { createConsumer } from "../../../apis/b2c_api";
import moment from "moment";
import Loader from "../../shared/loader";
import { setLoginUser } from "../../../app-store/user/user-reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import AuthResponse from "../../../interfaces/user";

const CardHolderAgreement = (props: any) => {
  const { signUpTitleColor } = useStyles(["signUpTitleColor"]);

  const [loader, setLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [agreementCheckbox, setAgreementCheckbox] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { formData, setCurrentForm, handleResetUserData } = props;
  const {
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    postalCode,
    country,
    email,
    dob,
  } = formData;

  const createAuthAccount = () => {
    if (agreementCheckbox) {
      const data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        email_confirmation_token: formData.confirmationToken,
        mobile_no: formData.phoneNumber,
      };

      const values = {
        email: formData.email,
        password: formData.password,
      };
      setLoader(true);

      createAccount(data)
        .then(async ({ data }) => {
          let userDetails: AuthResponse;
          if(data.auth_token) {
             userDetails = await loginWithAuth(data.auth_token);
          } else {
            userDetails = await login(values);
          }
          dispatch(setLoginUser(userDetails));
          setLoader(false);
          signup();
        })
        .catch((error) => {
          setLoader(false);
          showErrorNotification(error);
          handleResetUserData();
          setCurrentForm("emailForm");
        });
    } else {
      setHasError(true);
    }
  };

  const signup = () => {
    const noStateCountries = ["ARE", "QAT"];
    const ConcumerData = {
      addresses: [
        {
          address1: address,
          address2,
          addressType: "Billing",
          postalCode,
          state: noStateCountries.includes(country) ? city : state,
          country,
          city,
        },
      ],
      dateOfBirth: moment(dob, 'DD/MM/YYYY').format("YYYY-MM-DD"),
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneDetails: [
        {
          countryDialingCode: formData.countryDialingCode,
          phoneNumber: formData.nationalPhoneNumber,
          type: "Mobile",
        },
      ],
      // kyc data is manually added because currently we do not have support null kycData object  
      // we will remove the object as soon as processor and kyc apis are enhanced
      kycData: [
        {
            type: "NATIONAL_ID",
            issuanceId: "abc213142",
            issuanceCountry: "USA",
            issuanceDate: "2005-05-28",
            expiryDate: "2025-05-27"
        }
      ]
    };

    setLoader(true);
    createConsumer(ConcumerData)
      .then(({ createConsumer }) => {
        localStorage.removeItem("activeUser");
        navigate("/completion" , {
          state: {
            kycStatus: createConsumer?.profile?.kycStatus
          },
        });
      })
      .catch((error) => {
        localStorage.removeItem("activeUser");
        showErrorNotification(error);
        handleResetUserData();
        setCurrentForm("emailForm");
        setLoader(false);
      });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const isScrolledToBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight <= 50;

    if (isScrolledToBottom && !hasScrolledToBottom) {
      setHasScrolledToBottom(true);
      setScrolledToBottom(true);
    }
  };

  const handleAgreementChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAgreementCheckbox(event.target.checked);
    if (event.target.checked) {
      setHasError(false);
    }
  };

  return (
    <div className="agreement-container">
      <EnrollmentHeader
        title={"Cardholder Agreement"}
        titleColor={signUpTitleColor}
        backbutton={!loader}
        backClick={props.handlePrevious}
        className="address-info-title"
      />
      <div
        className="review-detail mt-3"
        style={{ width: "90%", color: "#2C3E50" }}
      >
        <div
          style={{ overflow: "auto", maxHeight: "500px" }}
          onScroll={handleScroll}
        >
          <div className="agreement-para">
            <h4> 1. Card Usage </h4>
            <p className="description">
              1.1. The prepaid debit, gift, or reloadable card is issued for use
              at establishments that accept such cards.
            </p>
            <p className="description">
              1.2. The Cardholder can access the funds loaded onto the card by
              using it for point-of-sale transactions and cash withdrawals at
              ATMs, subject to the card's limitations.{" "}
            </p>
          </div>

          <div className="agreement-para">
            <h4>2. Card Activation and Registration </h4>
            <p className="description">
              2.1. The Cardholder must activate the card before use as
              instructed by Aalam.
            </p>
            <p className="description">
              2.2. The Cardholder agrees to provide accurate and up-to-date
              personal information when registering the card.
            </p>
          </div>
          <div className="agreement-para">
            <h4>3. Card Loading and Reloads </h4>
            <p className="description">
              3.1. The Cardholder can load or reload funds onto the card as
              permitted by Aalam.
            </p>
            <p className="description">
              3.2. The Cardholder is responsible for any loading or reloading
              fees imposed by Aalam.
            </p>
          </div>
          <div className="agreement-para">
            <h4>4. Card Limitations </h4>
            <p className="description">
              4.1. The card has certain limitations, including maximum balances,
              transaction limits, and withdrawal limits. The Cardholder is
              responsible for adhering to these limitations.
            </p>
          </div>
          <div className="agreement-para">
            <h4>5. Fees and Charges</h4>
            <p className="description">
              5.1. The Cardholder may be subject to various fees and charges
              associated with the card, as detailed in the fee schedule provided
              by Aalam.{" "}
            </p>
          </div>
          <div className="agreement-para">
            <h4>6. Lost or Stolen Cards </h4>
            <p className="description">
              6.1. The Cardholder must promptly report a lost or stolen card to
              Aalam to minimize liability for unauthorized transactions.{" "}
            </p>
          </div>
          <div className="agreement-para">
            <h4>7. Card Expiry </h4>
            <p className="description">
              7.1. The card has an expiry date. The Cardholder may be issued a
              new card upon expiry if eligible, as per Aalam's policies.{" "}
            </p>
          </div>
          <div>
            <h4>8. Termination</h4>
            <p className="description">
              8.1. Aalam reserves the right to terminate this Agreement and the
              card at its discretion.
            </p>
          </div>
          <div>
            <h4>9. Governing Law </h4>
            <p className="description">
              9.1. This Agreement is governed by and construed in accordance
              with the laws of the applicable jurisdiction.{" "}
            </p>
          </div>
          <div>
            <h4
              style={{
                fontSize: "24px",
                margin: "10px 0 30px",
              }}
            >
              10. Contact Information{" "}
            </h4>
            <p className="description">
              10.1. For inquiries, support, or to report a lost or stolen card,
              please contact Aalam at [Contact Information].
            </p>
          </div>
        </div>
        <Row style={{ marginTop: "40px" }}>
          <Col md={24}>
            <FormControlLabel
              label="By accessing this website, you agree to abide by our terms and conditions."
              labelPlacement="end"
              className="agreement-checkbox-wrapper"
              control={
                <Checkbox
                  name="agreementCheckbox"
                  checked={agreementCheckbox}
                  onChange={handleAgreementChange}
                  sx={{
                    color: "#7C5838",
                    "&.Mui-checked": {
                      color: "#7C5838",
                    },
                  }}
                />
              }
            />
          </Col>
          <Col md={24}>
            {hasError && (
              <span style={{ color: "red" }}>
                Please agree to terms and conditions in order to continue.
              </span>
            )}
          </Col>
        </Row>
        <SignUpFlexButtons className="layout-buttons justify-start action-buttons-container direction-row-reverse">
          {loader && <Loader className="submit-loader" />}

          <SignUpCustomButton
            type="primary"
            className="action-button next-button"
            onClick={createAuthAccount}
            disabled={!agreementCheckbox && !scrolledToBottom || loader}
            htmltype="submit"
            text="Agree"
          />

          <SignUpCustomButton
            type="outline"
            className="action-button cancel-button"
            onClick={props.openModal}
            text="I don't agree"
            disabled={loader}
          />
        </SignUpFlexButtons>
      </div>
    </div>
  );
};

export default CardHolderAgreement;
