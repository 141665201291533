import React, { useState } from "react";
import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import useStyles from "../../../hooks/use-styles";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import { errorMessage, fieldValidator } from "../../../utils/validation";
import useConfigs from "../../../hooks/use-config";


const AddressAndIdentity = (props: any) => {
  const formRef = React.useRef<FormInstance>(null);
  const { formData, onFormSubmit } = props;
  const { address, address2, city, state, postalCode, country } =
    formData;

  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [loading, setLoading] = useState(false) as any;

  const [fieldValues, setFieldValues] = useState({
    address: address || "",
    address2: address2 || "",
    city: city || "",
    state: state || "",
    postalCode: postalCode || "",
    country: country || "ARE",
  }) as any;

  const { signUpTitleColor, signUpSubTitleColor } = useStyles([
    "signUpTitleColor",
    "signUpSubTitleColor",
  ]);

  const noStateCountries = ["ARE", "QAT"];

  const { states, countries } = useConfigs(["states", "countries"]);

  const validate = (
    key: string,
    value: string,
    options?: any,
    addError?: boolean
  ): string => {
    const errors = { ...fieldErrors };
    const keyValidator = fieldValidator[key];
    if (!value.trim() && options) {
      errors[key] = errorMessage.required;
    } else if (key === "postalCode" && !keyValidator(value)) {
      errors[key] = errorMessage.postalCode;
    } else if (key === "city" && value.length < 2) {
      errors[key] = errorMessage.cityLength;
    } else if (key === "state" && value.length < 2) {
      errors[key] = errorMessage.stateLength;
    } else if (key === "postalCode" && value.length < 5) {
      errors[key] = errorMessage.postalCodeLength;
    } else if (key === "postalCode" && value.length > 15) {
      errors[key] = errorMessage.maxPostalCodeLength;
    } else if (keyValidator && !keyValidator(value)) {
      errors[key] = errorMessage[key];
    } else {
      delete errors[key];
    }
    if (!addError) {
      delete errors[key];
    }
    if ((key === "state" && noStateCountries.includes(fieldValues?.country)) ||
      (key === "country" && noStateCountries.includes(value.trim()))) {
      delete errors["state"];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);

    return errors[key];
  };

  const onFieldChange = (key: string, value: any, options: any) => {
    if (key === "country" && (noStateCountries.includes(value) || value === "USA" || fieldValues?.country === "USA")) {
      formRef.current?.setFieldsValue({ state: "" });
      setFieldValues((prev: any) => ({ ...prev, ["state"]: "" }));
    }
    validate(key, value, options, false);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleNextFn = () => {
    const requiredFields = [
      "address",
      "city",
      "state",
      "postalCode",
      "country",
    ];
    const errors = {} as any;
    let valid = true;

    requiredFields.forEach((field) => {
      const fieldError = validate(field, fieldValues[field], true, true);
      if (fieldError) {
        errors[field] = fieldError;
        if (valid) {
          valid = false;
        }
      }
    });

    setFieldErrors(errors);
    setLoading(!valid);

    if (valid) {
      const countryName = countries.find(
        (item: any) => item.code === fieldValues.country
      )?.name;

      const stateName = states.find(
        (item: any) => item.code === fieldValues.state
      )?.name;
      window.scrollTo(0, 0);
      onFormSubmit({
        ...fieldValues,
        countryName: countryName,
        stateName: stateName,
      });
    }
  };

  return (
    <div className="address-info-container">
      <EnrollmentHeader
        title={"Physical Card Delivery"}
        subTitle={`Provide an address to receive your card`}
        titleColor={signUpTitleColor}
        subTitleColor={signUpSubTitleColor}
        backClick={props.handlePrevious}
        titleColSize={24}
        className="address-info-title"
      />
      <div className="login-form">
        <Form
          name="login"
          layout="vertical"
          initialValues={fieldValues}
          className="form-style"
          ref={formRef}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="address"
                className="custom-form-item mb-1"
                validateStatus={fieldErrors.address ? "error" : ""}
                help={fieldErrors.address}
              >
                <Input
                  bordered={false}
                  onChange={(event) =>
                    onFieldChange("address", event.target.value, true)
                  }
                  placeholder="Street Address"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address2"
                style={{ fontSize: "12px" }}
                className="custom-form-item mb-1"
              >
                <Input
                  bordered={false}
                  onChange={(event) =>
                    onFieldChange("address2", event.target.value, false)
                  }
                  placeholder="Apt, Suite, etc (Optional)"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="space-between" gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="city"
                    className="custom-form-item mb-1"
                    validateStatus={fieldErrors.city ? "error" : ""}
                    help={fieldErrors.city}
                  >
                    <Input
                      maxLength={50}
                      bordered={false}
                      onChange={(event) =>
                        onFieldChange("city", event.target.value, true)
                      }
                      placeholder="City"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="state"
                    className="custom-form-item mb-1"
                    validateStatus={fieldErrors.state ? "error" : ""}
                    help={fieldErrors.state}
                  >
                    {fieldValues.country === "USA" ? (
                      <Select
                        placeholder="Select State"
                        defaultValue=""
                        showSearch
                        bordered={false}
                        onChange={(value) =>
                          onFieldChange("state", value, true)
                        }
                      >
                        <Select.Option value="">Select State</Select.Option>
                        {states.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item.code}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <>
                        <Input
                          bordered={false}
                          onChange={(event) =>
                            onFieldChange("state", event.target.value, true)
                          }
                          placeholder="State"
                          maxLength={50}
                          disabled={noStateCountries.includes(fieldValues.country)}
                        />
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between" gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="postalCode"
                    className="custom-form-item mb-1"
                    validateStatus={fieldErrors.postalCode ? "error" : ""}
                    help={fieldErrors.postalCode}
                  >
                    <Input
                      maxLength={10}
                      bordered={false}
                      onChange={(event) =>
                        onFieldChange("postalCode", event.target.value, true)
                      }
                      placeholder="Postal Code"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    className="custom-form-item"
                    validateStatus={fieldErrors.country ? "error" : ""}
                    help={fieldErrors.country}
                  >
                    <Select
                      bordered={false}
                      defaultValue="ARE"
                      placeholder="Select Country"
                      onChange={(value) =>
                        onFieldChange("country", value, true)
                      }
                    >
                      <Select.Option value={fieldValues.country}>
                        Select Country
                      </Select.Option>
                      {countries.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.code}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <SignUpFlexButtons className="layout-buttons justify-start mt-6 action-buttons-container direction-row-reverse">
            <SignUpCustomButton
              type="primary"
              className="action-button next-button"
              onClick={handleNextFn}
              disabled={loading}
              htmltype="submit"
              text="Next"
            />

            <SignUpCustomButton
              type="outline"
              className="action-button cancel-button"
              onClick={props.openModal}
              text="Cancel"
            />
          </SignUpFlexButtons>
        </Form>
      </div>
    </div>
  );
};
export default AddressAndIdentity;
