import Cookies from "js-cookie";

export const CookieService = {
  
  setCookie(key: string, value: string, options?: object): any {
    Cookies.set(key, value,
      {
        path: '/',
        domain: cookieDomain(),
        secure: localStorage.getItem('secure_cookie') === 'yes',
        sameSite: 'Strict'
      }
    )
  },

  getCookie: (name: string) => {
    return Cookies.get(name);
  },

  removeCookie: (key:string, options?:any) => {
    Cookies.remove(key, {
      path: "/",
      domain: cookieDomain(),
      secure: localStorage.getItem('secure_cookie') === 'yes',
      sameSite: "Strict",
    });
  },

  idleTimeout: () => {
    return parseInt(CookieService.getCookie("cc_idle_timeout") || "300", 10);
  },

  sessionTimeout: () => {
    return parseInt(CookieService.getCookie("cc_session_timeout") || "300", 10);
  },
};

const cookieDomain = () => {
  const [domain] = (window.location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || []);
  if (domain) {
    return "." + domain;
  }
  return undefined;
};
