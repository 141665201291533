import { useState } from "react";
import {
  bankTransferIcon,
  homeMenuIcon,
  transferMenuIcon,
} from "../../../utils/SvgImage/Icons";

import { Layout, Menu, Modal } from "antd";
import { useNavigate } from "react-router";

import useConfigs from "../../../hooks/use-config";
import useLabels from "../../../hooks/use-labels";
import { isVisibleMenu } from "../../../utils/common-utils";
import { getPageUrl } from "../../../utils/reUseFunction";
import WithDrawerDepositModal from "../WithDrawerDepositModal";
import { useWindowResize } from "../../../hooks/use-window-resize";
import "./styles.css";

const { Sider } = Layout;

const assetPath = "/assets/images";

const Sidebar = () => {
  const resizeWindow = useWindowResize();
  const [collapsed, setCollapsed] = useState(true);
  const [depositModal, setDepositModal] = useState(false);
  const navigate = useNavigate();

  const { fnf_menu } = useConfigs(["fnf_menu"]);

  const { homeMenuTitle, transferMenuTitle } = useLabels([
    "homeMenuTitle",
    "transferMenuTitle",
  ]);

  const currentUrl = getPageUrl();

  const visible = isVisibleMenu(fnf_menu);

  const getKey = () => {
    const activeKey: any = {
      "": "1",
      transactions: "1",
      "wallet-transfer": "2",
      "card-operations": "3",
      "Friend-Family": "4",
      "transfer-funds": "4",
      "Bank-Transfer": "7",
      "Add-Bank": "7",
      "Bank-Transfer-History": "7",
      "Receive-Money": "7",
      "Send-Money": "7",
    };
    return activeKey[currentUrl];
  };

  const onMenuClick = (value: string) => {
    const menuAction: any = {
      home: () => {
        navigate("/");
      },
      transfer: () => {
        navigate("/wallet-transfer");
      },
      "Card-Operations": () => {
        navigate("/card-operations");
      },
      "Friend-Family": () => {
        navigate("/beneficiaries");
      },
      "Bank-Transfer": () => {
        navigate("/Bank-Transfer");
      },
    };

    return menuAction[value]();
  };

  const modalOpen = () => {
    setDepositModal(true);
  };

  const modalOpenClose = () => {
    setDepositModal(false);
  };

  const menuItems = [
    {
      key: "1",
      icon: homeMenuIcon,
      label: homeMenuTitle,
      onClick: () => onMenuClick("home"),
    },
    {
      key: "2",
      icon: transferMenuIcon,
      label: transferMenuTitle,
      onClick: () => onMenuClick("transfer"),
    },
  ];

  const menuItemsOne = [
    {
      key: "1",
      icon: homeMenuIcon,
      label: homeMenuTitle,
      onClick: () => onMenuClick("home"),
    },
    {
      key: "2",
      icon: transferMenuIcon,
      label: transferMenuTitle,
      onClick: () => onMenuClick("transfer"),
    },
    {
      key: "4",
      icon: visible ? (
        <span onClick={modalOpen}>
          <img
            src={`/assets/${
              collapsed ? "Dark deposit.png" : "light-deposit.png"
            }`}
            style={{ width: 24, height: 24 }}
            alt="deposit"
          />
        </span>
      ) : (
        <a
          rel="noreferrer"
          target="_blank"
          href="https://fair-4bc924.webflow.io/fair-deposit-form"
        >
          <img
            src={`/assets/${
              collapsed ? "Dark deposit.png" : "light-deposit.png"
            }`}
            style={{ width: 24, height: 24 }}
            alt="deposit"
          />
        </a>
      ),
      label: "Deposit",
    },
    {
      key: "5",
      icon: visible ? (
        <span onClick={modalOpen}>
          <img
            src={`/assets/${
              collapsed ? "Dark Withdraw.png" : "light-Withdraw.png"
            }`}
            style={{ width: 24, height: 24 }}
            alt="deposit"
          />
        </span>
      ) : (
        <a
          rel="noreferrer"
          target="_blank"
          href="https://fair-4bc924.webflow.io/fair-withdraw-form"
        >
          <img
            src={`/assets/${
              collapsed ? "Dark Withdraw.png" : "light-Withdraw.png"
            }`}
            style={{ width: 24, height: 24 }}
            alt="deposit"
          />
        </a>
      ),
      label: "Withdrawal",
    },
    {
      key: "6",
      icon: bankTransferIcon,
      label: "Bank Transfer",
      onClick: () => onMenuClick("Bank-Transfer"),
    },
  ];

  return (
    <div className="sidebar-content-container">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={`${
          resizeWindow > 575
            ? !collapsed
              ? "sidebar-container expanded-sidebar"
              : "sidebar-container shrink-sidebar"
            : "sidebar-container full-width-sidebar"
        } `}
      >
        <div className="sidebar-logo hide-sidebar-content">
          <img
            src={`${assetPath}/${
              collapsed ? "alim-logo" : "expanded-logo"
            }.png`}
            className={!collapsed ? "expand-logo" : ""}
            alt="Alim Logo"
          />
        </div>

        <div className="sidebar-menu-toggle hide-sidebar-content">
          <span
            className={collapsed ? "close-menu-icon" : ""}
            onClick={() => setCollapsed(!collapsed)}
          >
            <img src={`${assetPath}/left-burger-menu.png`} alt="left-menu" />
          </span>
        </div>

        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[getKey()]}
          items={visible ? menuItems : menuItemsOne}
          className="sidebar-menu-nav"
        />
      </Sider>
      {depositModal && (
        <Modal
          className="deposit-ant-modal"
          title="Basic Modal"
          open={true}
          onCancel={modalOpenClose}
        >
          <WithDrawerDepositModal />
        </Modal>
      )}
    </div>
  );
};

export default Sidebar;
