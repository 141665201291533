import { useState, useEffect, Fragment } from "react";
import { fetchBankAccounts } from "../../../apis/b2c_api";

import { showErrorNotification } from "../../../utils/common-utils";
import Loader from "../../shared/loader";
import BankTransferTable from "./BankTransferTable";
import BankTransferHeader from "./BankTransferHeader";
import { Drawer } from "antd";
import BankTransferVerify from "./BankTransferVerify";
import Congratulations from "../../User/Congratulations";

const BankTransfer = () => {
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [congrationDrawer, setCongrationDrawer] = useState(false);
  const [bankAccountData, setBankAccountData] = useState();
  const [bankAccountId, setBankAccountId] = useState();

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = () => {
    setLoading(true);
    fetchBankAccounts()
      .then((bankTransfers: any) => {
        setBankAccountData(bankTransfers);
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const showDrawer = (Account: any) => {
    setBankAccountId(Account);
    setOpenDrawer(true);
  };

  const congrationshowDrawer = () => {
    setCongrationDrawer(true);
    setOpenDrawer(false);
  };

  const onClose = () => {
    setOpenDrawer(false);
    setCongrationDrawer(false);
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      <div className="friend-family-section">
        <div className="card-item-white">
          <BankTransferHeader />
          <BankTransferTable
            bankAccountData={bankAccountData}
            showDrawer={showDrawer}
          />
        </div>
      </div>
      {(openDrawer || congrationDrawer) && (
        <Drawer
          className="main-drawer verify-drawer"
          placement="right"
          onClose={onClose}
          open={true}
        >
          {openDrawer && (
            <BankTransferVerify
              bankAccountId={bankAccountId}
              congrationshowDrawer={congrationshowDrawer}
            />
          )}
          {congrationDrawer && <Congratulations />}
        </Drawer>
      )}
    </Fragment>
  );
};

export default BankTransfer;
