import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import { BsArrowRight } from "react-icons/bs";

const SignUpNextButton = (props: any) => {
  const {
    signupPrimaryButtonLabelColor,
    signupPrimaryButtonBackground,
    signupPrimaryButtonBorder,
  } = useStyles([
    "signupPrimaryButtonLabelColor",
    "signupPrimaryButtonBackground",
    "signupPrimaryButtonBorder",
  ]);

  const { nextButtonLabel } = useLabels(["nextButtonLabel"]);
  const { text, style, className, ...buttonProps } = props;

  return (
    <button
      disabled={props.disabled}
      className={`custom-button next-button ${className!}`}
      style={{
        backgroundColor: signupPrimaryButtonBackground,
        border: `1px solid ${signupPrimaryButtonBorder}`,
        color: signupPrimaryButtonLabelColor,
        ...style,
      }}
      onClick={props.onClick}
      {...buttonProps}
    >
      <span>{text || nextButtonLabel}</span>
      <BsArrowRight />
    </button>
  );
};

export default SignUpNextButton;
