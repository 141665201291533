import moment from "moment";
import useStyles from "../../../hooks/use-styles";
import {
  accountLastFour,
  capitalizeTextTransform,
  formatCurrency,
  getCardLastFour,
  padLastFour,
} from "../../../utils/common-utils";
import { Fragment } from "react";

interface prepareTransactionsListProps {
  transactions: Array<any>;
  lightBlueColor: string;
  accounts?: Array<any>;
  cards?: Array<any>;
}

export const prepareTransactionsList = (
  props: prepareTransactionsListProps
) => {
  const { transactions, accounts, cards } = props;

  return transactions.map((item, index) => {
    const {
      cardLastFour,
      transactionStatus,
      transactionDatetime,
      approvedAmount,
      description,
      merchant,
      accountId,
      cardId,
    } = item;

    const { amount, currency } = approvedAmount;

    const { greenColor, redColor } = useStyles(["greenColor", "redColor"]);

    let color = transactionStatus === "PROCESSED" ? greenColor : redColor;

    let tAmount =
      amount < 0 ? (
        <span>-{formatCurrency(+amount * -1, currency || "USD")}</span>
      ) : (
        <span className="text-right">
          +{formatCurrency(+amount, currency || "USD")}
        </span>
      );

    const getDdaNumber = () => {
      let dda: string | undefined | null = undefined;
      dda = accounts && accounts.find(({ id }) => id === accountId)?.ddaNumber;
      if (!dda) return "-";
      return padLastFour(accountLastFour(dda));
    };

    let ddaNumber = getDdaNumber();
    return {
      key: index,

      lastFour: (
        <span>
          {(cardLastFour && padLastFour(cardLastFour)) ||
            (cardId && getCardLastFour(cardId, cards)) ||
            "-"}
        </span>
      ),

      date: (
        <span>
          {transactionDatetime
            ? moment(transactionDatetime).format("MMM DD, YYYY")
            : "-"}
        </span>
      ),

      amount: <span>{tAmount}</span>,

      merchant: (
        <span>
          {(description ? `${description}` : "") +
            (merchant && description ? " -" : "") +
            (merchant ? ` ${merchant?.name + ", " + merchant?.city}` : "")}
        </span>
      ),

      ddaNumber: <span>{ddaNumber}</span>,

      status: (
        <Fragment>
          <span
            style={{ backgroundColor: color }}
            className="status-badge"
          ></span>
          <span>{capitalizeTextTransform(transactionStatus)}</span>
        </Fragment>
      ),
    };
  });
};
