import LabelValueContainer from "../../../shared/LabelValueContainer";
import { countryIcon } from "./wallet-utils";

export const prepareWalletListData = (
  productWallets: any,
  walletCards: any,
  addAccount: any,
  accountId: object
) => {
  const walletList: any = [];

  const filteredArray = productWallets.filter((card: any) => {
    for (const wallet of walletCards) {
      if (wallet.currency === card.currencyCode) {
        return false;
      }
    }
    return true;
  });

  if (filteredArray.length) {
    filteredArray.forEach((wallet: any, index: number) => {
      const { currencyCode, name } = wallet;
      walletList.push({
        key: index,
        label: (
          <LabelValueContainer
            labelClass="wallet-currency-item-title"
            valueClass="wallet-currency-item-subtitle"
            label={currencyCode}
            value={name}
            onClick={() =>
              addAccount({
                accountId,
                currency: currencyCode,
                title: name,
              })
            }
          />
        ),
        icon: (
          <div className="wallet-currency-item-image">
            <img src={`${countryIcon}/${currencyCode}.png`} alt={countryIcon} />
          </div>
        ),
        className: "wallet-currency-item-container",
      });
    });
    return walletList;
  } else {
    return [
      {
        key: "no-account",
        label: (
          <LabelValueContainer
            labelClass="wallet-currency-item-title"
            label="No Wallet Available"
          />
        ),
        icon: "",
      },
    ];
  }
};
