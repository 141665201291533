import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "antd";
import { fetchProfileData } from "../../../apis/b2c_api";
import "./dashboard-style.css";
import RightDashboard from "./RightDashboard";

import DashboardAccountTabs from "./DashboardAccountTabs";
import EditModal from "./Modal/EditModal";
import AddModal from "./Modal/AddModal";
import { showErrorNotification } from "../../../utils/common-utils";
import { setUserProfile } from "../../../app-store/user/user-reducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../shared/loader";
import RecentTransactions from "./RecentTransactions";
import DashboardGraph from "./DashboardGraph";
import { getCardDrawerState } from "../../../app-store/public/public-action";
import _ from "lodash"

const MyAccountMain = () => {
  const [loading, setLoading] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Array<any>>([]);
  const [accountInfo, setAccountInfo] = useState<Array<any>>([]);
  const [accounts, setAccounts] = useState<Array<any>>([]);

  const dispatch = useDispatch<any>();
  const { userProfile: profileData } = useSelector((state: any) => state.users);
  const { openDrawer } = useSelector((state: any) => state.public);

  useEffect(() => {
    getProfileDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (_.isEmpty(selectedAccount) && accounts.length) {
      setSelectedAccount(accounts[0]);
    }
  }, [accounts, selectedAccount]);

  const getProfileDetails = () => {

    const profile = {
      transType: "",
      variableCode: "P2PTransferBeneficiaryAllowedProducts",
    };

    setLoading(true)

    fetchProfileData(profile)
      .then(({ profile }: any) => {
        dispatch(setUserProfile(profile));
        return profile;
      })
      .then((profile: any) => {
        setAccounts(
          [...profile?.accounts]?.sort((a: any, b: any) => {
            let l1 = a?.cards ? a?.cards?.length : 0;
            let l2 = b?.cards ? b?.cards?.length : 0;
            return l2 - l1;
          })
        );

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const editShowModal = (data: any) => {
    setEditModal(true);
    setAccountInfo(data);
  };

  const addShowModal = (data: any) => {
    setAddModal(true);
    setAccountInfo(data);
  };

  const toggleModal = () => {
    setEditModal(false);
    setAddModal(false);
  };

  const onAddAccount = () => {
    getProfileDetails();
    setSelectedAccount([]);
    setAccounts([])
  };

  const getSelectedAccount = (data: any) => {
    setSelectedAccount(data);
  };

  if (loading) return <Loader />;

  return (
    <div className="dashboard-main">
      {openDrawer && (
        <div
          className="right-dashboard-overlay-backdrop"
          onClick={() => {
            dispatch(getCardDrawerState(false));
            const bodyTag = document.getElementsByTagName("body");
            bodyTag[0].style.overflow = "visible";
          }}
        ></div>
      )}
      <Row gutter={[32, 32]} className="dashboard-main-row">
        <Col lg={18} md={24} sm={24} xs={24} className="dashbaord-main-col">
          <DashboardAccountTabs
            editAccount={editShowModal}
            addAccount={addShowModal}
            selectedAccount={getSelectedAccount}
            accounts={accounts}
          />
          <Row className="dashboard-bottom" gutter={[24, 24]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <RecentTransactions profileData={profileData} />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              {
                <DashboardGraph
                  accounts={accounts}
                  accountInfo={selectedAccount}
                />
              }
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24} className="dashbaord-main-col">
          {
            <RightDashboard
              accounts={accounts}
              accountInfo={selectedAccount}
              openDrawer={openDrawer}
            />
          }
        </Col>
      </Row>

      {(editModal || addModal) && (
        <Modal
          className="custom-ant-modal add-wallet-modal-container"
          title="Basic Modal"
          open={true}
          onOk={toggleModal}
          width={700}
          centered
        >
          {editModal && (
            <EditModal
              walletInfo={accountInfo}
              handleCancel={toggleModal}
              onAddAccount={onAddAccount}
            />
          )}

          {addModal && (
            <AddModal
              accountInfo={accountInfo}
              handleCancel={toggleModal}
              onAddAccount={onAddAccount}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default MyAccountMain;
