import { Col, Row } from "antd";
import ImageContentBox from "../../../components/shared/ImageContentBox";
import { useNavigate } from "react-router-dom";
import { cardOptions } from "./transfer-utils";
import { getLabel } from "../../../hooks/use-labels";
import "./styles.css";

const TransferFundCards = () => {
  const navigate = useNavigate();

  const handleCardClick = (path: string) => {
    navigate(path);
  };

  return (
    <div className="transfer-funds-container">
      <div className="transfer-funds-header">
        <Row gutter={[16, 16]}>
          <Col md={24} xs={24}>
            <div className="transfer-funds-header-content">
              <div className="transfer-funds-header-title">
                {getLabel("transferFundsTitle")}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="transfer-funds-content">
        <Row gutter={[32, 32]}>
          {cardOptions.map((item, index) => {
            const {
              title,
              image,
              path,
              description,
              colSizeMd,
              colSizeSm,
              disabled,
            } = item;

            return (
              <Col md={colSizeMd} sm={colSizeSm} key={index}>
                <ImageContentBox
                  title={title}
                  image={image}
                  description={description}
                  className="transfer-card"
                  onClick={() => path && handleCardClick(path)}
                  clickable={true}
                  disalbed={disabled}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default TransferFundCards;
