import useStyles from "../../../hooks/use-styles";
import { useEffect, useState } from "react";
import {
  endOfWeek,
  showErrorNotification,
  startOfWeek,
} from "../../../utils/common-utils";
import TransactionsFilter from "./TransactionsFilter";
import { prepareTransactionsList } from "./transactions-utlils";
import TransactionDetails from "./TransactionDetails";
import ContentBox from "../../../components/content-box";
import { fetchTransactions } from "../../../apis/b2c_api";
import TransactionsTable from "./TransactionsTable";
import { Col, Row } from "antd";
import type { PaginationProps } from "antd";
import { useLocation } from "react-router-dom";
import AntCrumbs from "../../../components/ant-crumbs";
import {
  getProfileData,
  getTransactionsProp,
} from "../../../utils/reUseFunction";
import dayjs from "dayjs";
import "./styles.css";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]) as any;
  const [transactionsList, setTransactionsList] = useState<Array<any>>([]);
  const [transaction, setTransaction] = useState(null) as any;
  const transaction_Props = getTransactionsProp();
  const profileData = getProfileData();
  const { state } = useLocation();
  const { transactionProps }: any = state || {};
  const { accountItem, walletItem } = transactionProps
    ? transactionProps
    : transaction_Props;

  const { accounts } = profileData;
  const { cards } = profileData;

  const [filterFields, setFilter] = useState({
    transactionStatus: null,
    transactionType: null,
    transactionDate: [dayjs().add(-7, "d"), dayjs()],
    mainAccount: accountItem ? accountItem.id : null,
    walletAccount: walletItem ? walletItem?.id : null,
    merchantName: null,
  });

  const [loading, setLoading] = useState(true);
  const [paginationProps, setPaginaton] = useState({
    pageNumber: 1,
    pageSize: 9,
  });

  const { lightBlueColor } = useStyles(["lightBlueColor"]);

  useEffect(() => {
    getTransactions(paginationProps.pageNumber, filterFields);
  }, []);

  const onTransactionClick = (transactionItem: any) => {
    setTransaction(transactionItem);
  };

  const applyHandler = (filterValues: any) => {
    getTransactions((paginationProps.pageNumber = 1), filterValues);
  };

  const resetHandler = (filterValues: any) => {
    getTransactions(paginationProps.pageNumber, filterValues);
  };

  const getTransactions = (pageNumber: any, filterValues: any) => {
    const { transactionDate, transactionType, ...restFilters } = filterValues;

    const transactionsProps = {
      pageNumber,
      pageSize: paginationProps.pageSize,
      fromDate: transactionDate
        ? dayjs(transactionDate[0]).format("YYYY-MM-DD")
        : "",
      toDate: transactionDate
        ? dayjs(transactionDate[1]).format("YYYY-MM-DD")
        : "",
      financialImpact: transactionType,
      ...restFilters,
    };

    setLoading(true);
    fetchTransactions(transactionsProps)
      .then(({ transactions }: any) => {
        setLoading(false);
        const { nodes, pageInfo } = transactions;
        setTransactionsList(nodes);
        const transactionListProps = {
          accounts,
          transactions: nodes,
          lightBlueColor,
          cards,
        };
        setFilter(filterValues);
        const preparedTransactions =
          prepareTransactionsList(transactionListProps);
        setTransactions(preparedTransactions);

        if (preparedTransactions.length === 0) {
          setTransaction(null);
        }

        setPaginaton((prev) => ({
          ...prev,
          ...paginationProps,
          totalRecords: pageInfo.totalRecords,
          pageSize: pageInfo.limit,
          pageNumber,
        }));
      })
      .catch((error: any) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const onChange: PaginationProps["onChange"] = (newPage: number) => {
    getTransactions(newPage, filterFields);
  };

  return (
    <div className="transaction-data-container">
      <Row gutter={[16, 16]}>
        <Col md={transaction ? 17 : 24} xs={24} sm={24}>
          <ContentBox className="transaction-content-box table-content-box">
            <TransactionsFilter
              onApply={applyHandler}
              onReset={resetHandler}
              filterValues={filterFields}
              transactions={transactionsList}
            />
            <TransactionsTable
              dataSource={transactions}
              paginationProps={paginationProps}
              onChange={onChange}
              loading={loading}
            />
          </ContentBox>
        </Col>

        {transaction && (
          <Col md={7} xs={24} sm={24}>
            <ContentBox className="full-height">
              <TransactionDetails transaction={transaction} />
            </ContentBox>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default Transactions;
