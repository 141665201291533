import { FC } from "react";
import useStyles from "../../../../../../../hooks/use-styles";

interface FieldHeadingProps {
  heading: string;
  textClass?: string;
}

const FieldHeading: FC<FieldHeadingProps> = ({ heading, textClass }) => {
  const { tagColor } = useStyles(["tagColor"]);
  return (
    <div className="min-width-8">
      <div
        className={`text-left full-width ${textClass}`}
        style={{
          color: tagColor,
          height: '40px',
          borderRight: `0.2px solid #CAD2D2`,
        }}
      >
        {heading}
      </div>
    </div>
  );
};

export default FieldHeading;
