import AddressAndIdentity from "./Enrollment-steps/addressAndIdentity";
import CardHolderAgreement from "./Enrollment-steps/cardholderAgreement";
import CodeVerification from "./Enrollment-steps/codeVerification";
import PersonalInformation from "./Enrollment-steps/personalInfo";
import ReviewDetails from "./Enrollment-steps/reviewDetails";
import Verification from "./Enrollment-steps/verification";
import IdentityVerification from "./Enrollment-steps/identityVerification";

export const enrollmentFormSteps: any = {
    emailForm: {
      next: "codeVerificationForm",
      previous: "",
      component: (props: any) => <Verification {...props} />,
      listStep: 1,
    },
    codeVerificationForm: {
      component: (props: any) => <CodeVerification {...props} />,
      listStep: 2,
      next: "personalInfo",
      previous: "emailForm",
    },
    personalInfo: {
      component: (props: any) => <PersonalInformation {...props} />,
      listStep: 3,
      next: "identityVerification",
      previous: "codeVerificationForm",
    },
    identityVerification: {
      component: (props: any) => <IdentityVerification {...props} />,
      listStep: 4,
      next: "addressForm",
      previous: "personalInfo",
    },
    addressForm: {
      component: (props: any) => <AddressAndIdentity {...props} />,
      listStep: 5,
      next: "reviewDetails",
      previous: "identityVerification",
    },
    reviewDetails: {
      component: (props: any) => <ReviewDetails {...props} />,
      listStep: 6,
      next: "cardholderAgreement",
      previous: "addressForm",
    },
    // loadAmount: {
    //   component: (props: any) => <LoadAmount {...props} />,
    //   listStep: 3,
    //   next: "paymentCardInfo",
    //   previous: "dobSSNForm",
    // },
    // paymentCardInfo: {
    //   component: (props: any) => <PaymentCardInfo {...props} />,
    //   listStep: 4,
    //   next: "paymentConfirmation",
    //   previous: "accountForm",
    // },
    // paymentConfirmation: {
    //   component: (props: any) => <PaymentConfirmation {...props} />,
    //   listStep: 5,
    //   next: "cardholderAgreement",
    //   previous: "previewForm",
    // },
    cardholderAgreement: {
      component: (props: any) => <CardHolderAgreement {...props} />,
      listStep: 7,
      next: "congratulations",
      previous: "reviewDetails",
    },
  };
