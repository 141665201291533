import { formatCurrency, padLastFour } from "../../../../../utils/common-utils";

const flagIconPath = "/assets/country-flags";

export const prepareAccountOptions = (accounts: any) => {
  const accountsList: Array<object> = [
    {
      label: "Select Account",
      value: "",
    },
  ];

  accounts.forEach(({ title, id, accountProxyNo }: any) => {
    const lastFourDigits = accountProxyNo
      .toString()
      .substring(accountProxyNo.toString().length - 4);

    accountsList.push({
      label: `${title} ${padLastFour(lastFourDigits)}`,
      value: id,
    });
  });

  return accountsList;
};

export const prepareWalletOptions = (
  accountId: string,
  accounts: any,
  accountType?: string,
  mainAccountcurrency?: string,
  fromMainAccountId?: string
) => {
  const account = accounts.find((item: any) => item.id === accountId);

  if (accountType === "toMainAccount" && account.id === fromMainAccountId) {
    return account.accountWallets
      .filter((wallet: any) => wallet.currency !== mainAccountcurrency)
      .map(({ id, availableBalance, currency }: any) => ({
        currency,
        label: `${formatCurrency(availableBalance, currency)}`,
        value: id,
        icon: `${flagIconPath}/${currency}.png`,
      }));
  } else {
    if (account) {
      if (account.length) {
        return accountWalletList(account);
      } else {
        return accountWalletList(account);
      }
    } else {
      return [];
    }
  }
};

const accountWalletList = (account: any) =>
  account.accountWallets.map(({ id, availableBalance, currency }: any) => ({
    currency,
    label: `${formatCurrency(availableBalance, currency)}`,
    value: id,
    icon: `${flagIconPath}/${currency}.png`,
  }));

export const prepareWalletCurrencyOptions = (
  accountId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  return account.accountWallets.map((resp: any) => ({
    currency: resp.currency,
  }));
};

export const getAccountTitle = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const lastFourDigits = account.accountProxyNo
    .toString()
    .substring(account.accountProxyNo.toString().length - 4);
  return `${account.title} ${padLastFour(lastFourDigits)}`;
};

export const getWalletTitle = (
  accountId: string,
  walletId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const wallet = account.accountWallets.find(
    (walletItem: any) => walletItem.id === walletId
  );

  return `${formatCurrency(wallet.availableBalance, wallet.currency)}`;
};

export const getWalletCurrency = (
  accountId: string,
  walletId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const wallet = account.accountWallets.find(
    (walletItem: any) => walletItem.id === walletId
  );
  return wallet.currency;
};

export const selectFromWalletDefaultValue = (wallets: Array<any>): any => {
  if (Array.isArray(wallets) && wallets.length) {
    const { value, currency } = wallets[0];
    return { walletAmount: value, currency };
  }

  return {
    walletAmount: "",
    currency: "",
  };
};

export const filterWalletOptions = (
  walletOptions: Array<any>,
  currency: string
): Array<any> => {
  const filteredArray = walletOptions.filter(
    (wallet: any) => wallet.currency !== currency
  );
  return filteredArray;
};

export const prepareExchangeRate = (
  rates: any,
  fromCurrency: string,
  toCurrency: string,
  amount: any
) => {
  if (fromCurrency === toCurrency) {
    return {
      convertedAmount: Number(amount) * 1,
      exchangeRateLine: `${fromCurrency} = ${1} ${toCurrency}`,
    };
  } else {
    const filteredArray = rates.filter(
      (item: any) => item.from === fromCurrency && item.to === toCurrency
    );

    if (filteredArray.length) {
      const { rate, from, to } = filteredArray[0];

      return {
        convertedAmount: Number(amount) * rate,
        exchangeRateLine: `${from} = ${rate} ${to}`,
      };
    }

    return undefined;
  }
};

export const santizeInvalidCharacter = (event: any) => {
  if (
    event.key === "+" ||
    event.key === "-" ||
    event.key === "e" ||
    event.key === "E"
  ) {
    event.preventDefault();
  }
};
