import React, { FC, useState } from "react";
import { Input, Form } from "antd";
import {
  americanFlag,
  audFlag,
  canadaFlag,
  euroFlag,
  gbdFlag,
} from "../../../../utils/SvgImage/Icons";
import FlexButtons from "../../../flex-button";
import CustomButton from "../../../custom-button";
import useStyles from "../../../../hooks/use-styles";
import {
  formatCurrency,
  showErrorNotification,
} from "../../../../utils/common-utils";
import { updateSubAccounts } from "../../../../apis/b2c_api";
import Loader from "../../../shared/loader";
import customToast from "../../../shared/services/toaster-service";
import useConfigs from "../../../../hooks/use-config";
import useLabels from "../../../../hooks/use-labels";

interface EditModalProps {
  walletInfo: any;
  handleCancel: () => void;
  onAddAccount: () => void;
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const EditModal: FC<EditModalProps> = ({
  walletInfo,
  handleCancel,
  onAddAccount,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { darkBlueColor } = useStyles(["darkBlueColor"]);
  const { updateSubAccountSuccessMessage } = useConfigs([
    "updateSubAccountSuccessMessage",
  ]);
  const { editSubAccountTitle, availableBalanceTitle, subAccountTitlelabel } =
    useLabels([
      "editSubAccountTitle",
      "availableBalanceTitle",
      "subAccountTitlelabel",
    ]);

  const {
    currentBalance,
    availableBalance,
    currency,
    title,
    accountId,
    id,
    isDefaultWallet,
  } = walletInfo;

  const onFinish = (values: any) => {
    const walletUpdateBody = {
      accountId: accountId,
      walletId: id,
      title: values.title,
      isDefault: isDefaultWallet,
    };
    setLoading(true);
    updateSubAccounts(walletUpdateBody)
      .then(() => {
        customToast.success(updateSubAccountSuccessMessage);

        setLoading(false);
        handleCancel();
        onAddAccount();
      })
      .catch((error) => {
        setLoading(false);
        handleCancel();
        showErrorNotification(error);
      });
  };

  return (
    <div className="pt-2 pb-2">
      <div className="modal-content">
        <p
          className="text-lg text-center weight-500"
          style={{ color: darkBlueColor }}
        >
          {editSubAccountTitle}
        </p>

        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          layout={"vertical"}
          initialValues={{title}}
        >
          <Form.Item name="title" label={subAccountTitlelabel}>
              <Input/>
          </Form.Item>

          <div className="list-transactions-edit-modal box-border-radius mt-2  ml-auto mr-auto pr-2 min-width-8">
            <div className="transactions-list pb-0 mb-0 pl-1">
              <div className="d-flex align-center">
                <div className="person-icon mr-1">
                  {(currency === "USD" && americanFlag) ||
                    (currency === "EUR" && euroFlag) ||
                    (currency === "AUD" && audFlag) ||
                    (currency === "CAD" && canadaFlag) ||
                    (currency === "GBP" && gbdFlag)}
                </div>
                {/* <div className="transaction-list-info">
                  <p>Current</p>
                  <h3>{formatCurrency(currentBalance, currency)}</h3>
                </div> */}
                <div className="edit-modal-transaction-list">
                  <div className="transaction-list-info ml-0">
                    <p>{availableBalanceTitle}</p>
                    <h3>{formatCurrency(availableBalance, currency)}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading && <Loader className="custom-loader" />}
          <FlexButtons className="mt-3 mb-3 gap-2">
            <CustomButton
              className="layout-button-small-width layout-button-small-height"
              type="outline"
              text="Cancel"
              onClick={handleCancel}
            />

            <CustomButton
              className="layout-button-small-width layout-button-small-height"
              type="primary"
              text="Save"
              htmltype="submit"
              disabled={loading}
            />
          </FlexButtons>
        </Form>
      </div>
    </div>
  );
};

export default EditModal;
