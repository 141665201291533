import { Fragment, useEffect, useState } from "react";
import useLabels from "../../../../../hooks/use-labels";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
} from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { restrictToParentElement } from "@dnd-kit/modifiers";

import WalletItem from "../walletItem";
import {
  getProductWallets,
  updateSubAccounts,
} from "../../../../../apis/b2c_api";
import DropdownContainer from "../../../../shared/DropdownContainer";
import DropdownLabelContainer from "../../../../shared/DropdownContainer/DropdownLabelComponent";
import { prepareWalletListData } from "../country-menu-list";
import { showErrorNotification } from "../../../../../utils/common-utils";
import { useWindowResize } from "../../../../../hooks/use-window-resize";

const imgPath = "/assets/images";

const WalletTab = ({
  accountItem,
  handleTransactionClick,
  addAccount,
  editAccount,
}: any) => {
  const [walletCards, setWalletCards] = useState<Array<any>>(
    [...accountItem.accountWallets].sort(
      (a, b) => a?.chainingPriority - b?.chainingPriority
    )
  );

  const [walletList, setWalletList] = useState<Array<any>>();
  const resizeWidth = useWindowResize() < 992;

  useEffect(() => {
    getWalletList(walletCards, addAccount, accountItem);
  }, [walletCards, addAccount, accountItem]);

  const getWalletList = (
    walletCards: Array<object>,
    addAccount: () => void,
    accountItem: any
  ) => {
    const { id } = accountItem;
    getProductWallets()
      .then(({ productWallets }) => {
        setWalletList(
          prepareWalletListData(productWallets, walletCards, addAccount, id)
        );
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const sortWalletAccounts = (dragIndex: number, dropIndex: number) => {
    const accountProps = {
      accountId: walletCards[dragIndex]?.accountId,
      walletId: walletCards[dragIndex]?.id,
      chainingPriority: dropIndex + 1,
      includedInChaining: true,
    };
    updateSubAccounts(accountProps);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = walletCards.findIndex(
        (item) => item?.id === active.id
      );
      const overIndex = walletCards.findIndex((item) => item?.id === over?.id);
      setWalletCards((items) => {
        return arrayMove(items, activeIndex, overIndex);
      });
      sortWalletAccounts(activeIndex, overIndex);
    }
  };

  const { addNewButtonTitle } = useLabels(["addNewButtonTitle"]);

  const sensors = useSensors(
    useSensor(PointerSensor),

    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const mobileSensor = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  const renderItems = () =>
    walletCards.map((walletItem: any, index: any) => (
      <WalletItem
        walletItem={walletItem}
        key={index}
        accountItem={accountItem}
        index={index}
        handleTransactionClick={handleTransactionClick}
        editAccount={editAccount}
      />
    ));

  return (
    <div className="wallet-section">
      <div className="wallet-section-header">
        <div className="wallet-seciton-header-title">Wallets</div>
        <Fragment>
          <DropdownContainer
            clickTrigger={true}
            overLayClass="currency-wallet-dropdown"
            menuList={walletList}
            placement="bottomLeft"
            dropdownComponent={
              <DropdownLabelContainer
                icon={`${imgPath}/plus-blue-icon.png`}
                label={addNewButtonTitle}
                dropdownIcon={`${imgPath}/dropdown-blue-icon.png`}
                className="add-wallet-dropdown"
              />
            }
          />
        </Fragment>
      </div>

      <DndContext
        sensors={resizeWidth ? mobileSensor : sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext
          items={walletCards}
          strategy={verticalListSortingStrategy}
        >
          <div className="sortable-context-container">
            {walletCards && renderItems()}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default WalletTab;
