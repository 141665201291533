import { Avatar, Empty, List } from "antd";
import { fetchRecentTransactions } from "../../../../apis/b2c_api";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import { useNavigate } from "react-router-dom";
import { recentTransactionUtils } from "./recent-transaction-utils";
import { useWindowResize } from "../../../../hooks/use-window-resize";

const RecentTransactions = ({ profileData }: { profileData: any }) => {
  const resizeWidth = useWindowResize() < 575;

  useEffect(() => {
    const getRecentTrans = () => {
      const transProps = { pageSize: 4, pageNumber: 1 };
      fetchRecentTransactions(transProps)
        .then((data) => {
          setRecentTrans(data?.transactions?.nodes);
        })
        .catch((error) => {
          console.log("recentTrans error", error);
          setRecentTrans([]);
        });
    };
    getRecentTrans();
  }, []);
  const navigate = useNavigate();
  const [recentTrans, setRecentTrans] = useState<undefined | null | any[]>(
    undefined
  );

  const { cards, accounts }: { cards?: Array<any>; accounts?: Array<any> } =
    profileData;

  const handleViewAll = () => {
    navigate("/transactions", {
      state: {
        pageTitle: "Transaction History",
        pageCrumbs: [
          { title: "Account Summary", link: "/" },
          { title: "Transactions" },
        ],
      },
    });
    localStorage.setItem(
      "Transactions_Props",
      JSON.stringify({
        state: {
          pageTitle: "Transaction History",
          pageCrumbs: [
            { title: "Account Summary", link: "/" },
            { title: "Transactions" },
          ],
        },
      })
    );
  };

  const getDataSource = () => {
    const transList = recentTransactionUtils(recentTrans, cards, accounts);
    const { dataArray, date: dateArray } = transList;

    const transactionDom: any = [];

    dateArray?.forEach((date: string, index: number) => {
      const transactionList: any = [];

      dataArray.forEach((trans: any) => {
        const { dateTime } = trans;
        if (date === dateTime) {
          transactionList.push(trans);
        }
      });

      transactionDom.push(
        <Fragment key={index}>
          <div className={`recent-transaction-list-title list-title-${index}`}>
            {date}
          </div>
          <List
            className="recent-transaction-list"
            dataSource={transactionList}
            renderItem={(item: any) => (
              <List.Item className="recent-transaction-list-item">
                <List.Item.Meta
                  avatar={
                    <Avatar src={item?.icon} className={item?.iconClass} />
                  }
                  title={
                    <div>
                      {resizeWidth && (
                        <div>
                          <span className="transaction-amount">
                            {item?.amount}
                          </span>
                        </div>
                      )}
                      <span className="transaction-title">{item?.title}</span>
                    </div>
                  }
                />
                {!resizeWidth && (
                  <div>
                    <span className="transaction-amount">{item?.amount}</span>
                  </div>
                )}
              </List.Item>
            )}
          />
        </Fragment>
      );
    });

    return transactionDom;
  };

  return (
    <div className="recent-transactions-container">
      {!recentTrans ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="recent-transactions-header">
            <div className="recent-title">Transactions</div>
            <div className="recent-view-all" onClick={handleViewAll}>
              View All
            </div>
          </div>
          {recentTrans?.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Data Available"
            />
          ) : (
            getDataSource()
          )}
        </Fragment>
      )}
    </div>
  );
};

export default RecentTransactions;
