import { DownOutlined } from "@ant-design/icons";
import { FC } from "react";

interface DropdownLabel {
  icon?: string;
  label?: string;
  className?: string;
  dropdownIcon?: string;
}

const DropdownLabelContainer: FC<DropdownLabel> = ({
  icon,
  label,
  className,
  dropdownIcon,
}) => {
  return (
    <div className={`dropdown-label-container ${className}`}>
      <span className="dropdown-action-icon">
        <img src={icon} alt={icon} />
      </span>
      <span className="dropdown-label">{label}</span>
      <span className="dropdown-arrow-image">
        {dropdownIcon ? (
          <img src={dropdownIcon} alt={dropdownIcon} />
        ) : (
          <DownOutlined />
        )}
      </span>
    </div>
  );
};

export default DropdownLabelContainer;
