import "./loading.css";

const Loader = ({
  className,
}: {
  message?: string;
  className?: string;
}): JSX.Element => {
  return (
    <div className={`logo-container ${className!}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
