import { formatCurrency, padLastFour } from "../../../../utils/common-utils";

export const transactionTypeOptions = () => {
  return [
    {
      label: "Credit",
      value: "CREDIT",
    },
    {
      label: "Debit",
      value: "DEBIT",
    },
  ];
};

export const statusOptions = () => {
  return [
    {
      label: "Processed",
      value: "P",
    },
    {
      label: "Pending",
      value: "H",
    },
    {
      label: "Declined",
      value: "D",
    },
    {
      label: "Completed",
      value: "C",
    },
    {
      label: "Expired",
      value: "E",
    },
    {
      label: "Reversed",
      value: "R",
    },
    {
      label: "Scheduled",
      value: "S",
    },
    {
      label: "Cancelled",
      value: "X",
    },
  ];
};

export const accountOptions = (accounts: any) =>
  accounts.map((accountItem: any) => ({
    label: `${accountItem?.title}  ${padLastFour(
      accountItem?.accountProxyNo
        ?.toString()
        ?.substring(accountItem?.accountProxyNo?.toString()?.length - 4)
    )}`,
    value: accountItem.id,
  }));

export const merchantOptions = (transactions: Array<any>) => {
  let options: Array<{ label?: string; value?: string }> = [];

  transactions.forEach((item: any) => {
    if (!options.find((opt) => item?.merchant?.name === opt?.value))
      options.push({
        label: item?.merchant?.name,
        value: item?.merchant?.name,
      });
  });
  return options;
};

export const walletOptions = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);

  return account.accountWallets.map((item: any) => ({
    label: `${item?.title} - ${formatCurrency(
      item?.availableBalance,
      item?.currency
    )}`,
    value: item?.id,
  }));
};
