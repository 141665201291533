import NextButton from "./next-button";
import PreviousButton from "./previous-button";
import PrimaryButton from "./primary-button";
import OutlineButton from "./outline-button";
import "./style.css";

interface buttonProps {
  type: string;
  text?: string;
  onClick?: any;
  disabled?: boolean;
  style?: any;
  className?: string;
  htmltype?: string;
}

const RenderButton = (props: buttonProps): JSX.Element => {
  const buttons: any = {
    next: () => <NextButton {...props} />,
    previous: () => <PreviousButton {...props} />,
    outline: () => <OutlineButton {...props} />,
    primary: () => <PrimaryButton {...props} />,
  };

  return buttons[props.type]();
};

const SignUpCustomButton = (props: buttonProps): JSX.Element => {
  return (
    <>
      <RenderButton {...props} />
    </>
  );
};

export default SignUpCustomButton;
