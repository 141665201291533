import { formatCurrency, padLastFour } from "../../../../../../utils/common-utils";


export const prepareAccountOptions = (accounts: any) => {
  return accounts.map(({ title, id, accountProxyNo, __typename }: any) => {
    const lastFourDigits = accountProxyNo
      .toString()
      .substring(accountProxyNo.toString().length - 4);
    return {
      label: `${title} ${padLastFour(lastFourDigits)}`,
      value: id,
      __typename: __typename,
    };
  });
};

export const prepareWalletOptions = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);
  return account.accountWallets.map(
    ({ title, id, availableBalance, currency }: any) => ({
      label: `${title} ${formatCurrency(
        availableBalance,
        currency
      )}`,
      value: id,
    })
  );
};

export const getAccountTitle = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const lastFourDigits = account.accountProxyNo
      .toString()
      .substring(account.accountProxyNo.toString().length - 4);
  return `${account.title} ${padLastFour(lastFourDigits)}`;
};

export const getWalletTitle = (
  accountId: string,
  walletId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const wallet = account.accountWallets.find(
    (walletItem: any) => walletItem.id === walletId
  );
  return `${wallet.title} ${formatCurrency(
    wallet.availableBalance,
    wallet.currency
  )}`;
};

export const getWalletCurrency= (
  accountId: string,
  walletId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const wallet = account.accountWallets.find(
    (walletItem: any) => walletItem.id === walletId
  );
  return wallet.currency
};
