import { Col, DatePicker, Input, Row, Select } from "antd";
import React, { useState } from "react";

import { useSelector } from "react-redux";
import CustomButton from "../../../../components/custom-button";
import useLabels from "../../../../hooks/use-labels";
import { endOfWeek, startOfWeek } from "../../../../utils/common-utils";
import {
  statusOptions,
  walletOptions
} from "./transaction-filter-utils";

const { RangePicker } = DatePicker;

interface TransactionsFilterProps {
  onApply: (fieldValues: any) => any;
  onReset: (fieldValues: any) => any;
  filterValues: any;
  setTransactions: any;
  transactions: any;
}

const TransactionsFilter: React.FC<TransactionsFilterProps> = ({
  onApply,
  onReset,
  filterValues,
  setTransactions,
  transactions,
}) => {
  const { userProfile } = useSelector((state: any) => state.users);

  const { accounts } = userProfile;

  const [fieldValues, setFieldValues] = useState({
    transactionStatus: filterValues.transactionStatus || null,
    transactionType: filterValues.transactionType || null,

    mainAccount: filterValues.mainAccount || null,
    walletAccount: filterValues.walletAccount || null,

    transactionDate: filterValues.transactionDate || [
      startOfWeek(),
      endOfWeek(),
    ],
  });

  const [filterWalletOptions, setWalletOptions] = useState(
    fieldValues.mainAccount
      ? walletOptions(fieldValues.mainAccount, accounts)
      : []
  ) as any;

  const { applyFilterText } = useLabels(["applyFilterText"]);

  const onChange = (key: string, value: string) => {
    if (key === "transactionType") {
      if (value) {
        const search = value.toLowerCase();
        const filteredEvents = transactions.filter((item: any) => {
          const bankName = item?.bankAccount?.bankName?.toLowerCase();
          return bankName.includes(search);
        });

        setTransactions(filteredEvents);
        setFieldValues((prev: any) => ({
          ...prev,
          [key]: value,
        }));
      }
    }

    if (key === "transactionStatus") {
      const searched = value === "H" ? "Pending" : "Processed";
      const filteredEvents = transactions.filter((item: any) => {
        const statusOptions =
          item?.status === "SCHEDULED" ? "Pending" : "Processed";
        return statusOptions.includes(searched);
      });

      setTransactions(filteredEvents);
      setFieldValues((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }

    setFieldValues((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const applyFiltersHandler = () => {
    onApply(fieldValues);
  };

  const resetFiltersHandler = () => {
    const resetValues = {
      transactionStatus: null,
      transactionType: null,
      mainAccount: null,
      walletAccount: null,
      transactionDate: null,
    };

    setFieldValues(resetValues);
    onReset(resetValues);
  };

  const hasFilter = () => {
    return Object.values(filterValues).filter((key) => Boolean(key)).length > 0;
  };

  return (
    <>
      {hasFilter() && (
        <span
          className="clear-filter pointer align-center mt-1"
          onClick={resetFiltersHandler}
        >
          X Clear Filter
        </span>
      )}
      <Row gutter={[5, 5]} className="mt-1">
        <Col md={7} xs={24}>
          <RangePicker
            className="full-width custom-placeholder"
            value={fieldValues.transactionDate}
            format={"YYYY-MM-DD"}
            onChange={(e: any) => onChange("transactionDate", e)}
          />
        </Col>

        <Col md={6} xs={24}>
          <Input
            value={fieldValues.transactionType}
            onChange={(e: any) => onChange("transactionType", e.target.value)}
            placeholder="Search by Bank"
          />
        </Col>

        <Col md={3} xs={24}>
          <Select
            className="full-width custom-placeholder"
            placeholder="Filter By Status"
            onSelect={(value: any) => onChange("transactionStatus", value)}
            value={fieldValues.transactionStatus}
          >
            {statusOptions().map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>

      <CustomButton
        className="layout-button-width button-height text-sm mt-1 mb-2"
        text={applyFilterText}
        type="primary"
        onClick={applyFiltersHandler}
      />
    </>
  );
};

export default TransactionsFilter;
