import { Pagination, Table } from "antd";
import { Fragment } from "react";
import moment from 'moment';
import type { ColumnsType } from "antd/es/table";
import { formatCurrency } from '../../../../../utils/common-utils'
import '../transfer-history-style.css';
import { getLabel } from "../../../../../hooks/use-labels";

interface TransferTableProps {
    dataSource: Array<any>;
    onChange: (pageNumber: number, pageSize: number) => void;
    paginationProps: any;
    loading: boolean;
}

const TransferHistoryTable = (props: TransferTableProps) => {
    const { dataSource, onChange, paginationProps } = props;

    const { limit, totalRecords, pageNumber } = paginationProps;

    const getStatusColor = (status: string) => {
      if (status === "PROCESSED") {
          return getLabel("processedStatusColor");
      } else if (status === "PENDING") {
          return getLabel("pendingStatusColor");
      } else if (status === "FAILED") {
        return getLabel("statusRedColor");
      } else {
          return "black";
      }
  };
  
    const columns: ColumnsType<any> = [
      {
        title: "Date",
        dataIndex: "transferDateTime",
        key: "transferDateTime",
        render: (record) => (
          <span className="transfer-table-regular-text">
            {moment(record).format('MMM DD, YYYY')}
          </span>
        ),
      },
      {
        title: "Beneficiary",
        dataIndex: "beneficiary",
        key: "beneficiary",
        render: (record) => (
          <span className="transfer-table-regular-text">
            {record.name}
          </span>
        ),
      },
      {
        title: "Amount",
        dataIndex: "transferAmount",
        key: "transferAmount",
        render: (record) => (
          <span className="transfer-table-bold-text">
            {formatCurrency(record.amount, record.currency)}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (record) => (
          <span className="transfer-table-regular-text">
          <span className="colored-circle" style={{ borderColor: getStatusColor(record), backgroundColor: getStatusColor(record) }} />
          {record}
      </span>
        ),
      },
    ];
  
    return (
      <Fragment>
        <Table
          className="transfer-table-container-box ant-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={props.loading}
          locale={{ emptyText: "No Records Found" }}
        />
        <Pagination
          className="custom-ant-pagination"
          size="small"
          current={pageNumber}
          pageSize={limit}
          total={totalRecords}
          onChange={onChange}
          showSizeChanger={false}
        />
      </Fragment>
    );
}

export default TransferHistoryTable