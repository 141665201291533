import useStyles from "../../../../hooks/use-styles";

const SignUpOutlineButton = (props: any) => {
  const {
    signupSecondaryButtonLabelColor,
    signupSecondaryButtonBackground,
    signupSecondaryButtonBorder,
  } = useStyles([
    "signupSecondaryButtonLabelColor",
    "signupSecondaryButtonBackground",
    "signupSecondaryButtonBorder",
  ]);
  const { text, style, className, ...buttonProps} = props;
  return (
    <button
    className={`custom-button ${className!}`}
      style={{
        backgroundColor: signupSecondaryButtonBackground,
        border: `1px solid ${signupSecondaryButtonBorder}`,
        color: signupSecondaryButtonLabelColor,
        ...style
      }}
      {...buttonProps}
    >
      {text}
    </button>
  );
};

export default SignUpOutlineButton;
