import { Fragment } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { formatCurrency } from "../../../../utils/common-utils";
import { countryIcon } from "./wallet-utils";
import { useWindowResize } from "../../../../hooks/use-window-resize";

const WalletItem = ({
  walletItem,
  index,
  handleTransactionClick,
  accountItem,
  editAccount,
}: any) => {
  const resizeWidth = useWindowResize() < 992;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: walletItem?.id });

  const mobileStyle = {
    boxShadow: isDragging ? "0 4px 24px 0 rgba(0, 0, 0, 0.16)" : "",
    backgroundColor: isDragging ? "#fff" : "",
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? "grabbing" : "grab",
    ...(resizeWidth ? mobileStyle : ""),
  };

  return (
    <Fragment>
      <div
        ref={setNodeRef}
        style={{ ...style }}
        {...attributes}
        {...listeners}
        className="wallets-tabs wallet-account-item position-relative"
        key={index}
      >
        <div className="wallet-account-meta">
          <div className="country-flag">
            <img
              src={`${countryIcon}/${walletItem?.currency}.png`}
              alt="flag"
            />
          </div>
          <div className="wallet-account-fields">
            <div className="wallet-field">
              <div className="wallet-field-label">Available</div>
              <div className="wallet-field-balance">
                {formatCurrency(
                  walletItem.availableBalance,
                  walletItem.currency
                )}
              </div>
            </div>
            <div className="wallet-field">
              <div className="wallet-field-label">Current</div>
              <div className="wallet-field-balance">
                {formatCurrency(walletItem.currentBalance, walletItem.currency)}
              </div>
            </div>
          </div>
        </div>

        <div className="more-options">
          <span
            onMouseDown={() =>
              handleTransactionClick({ accountItem, walletItem })
            }
            className="option-icon trasction-icon"
          >
            transaction
          </span>
          <span className="option-icon drag-icon">drag icon</span>
        </div>
      </div>
    </Fragment>
  );
};

export default WalletItem;
