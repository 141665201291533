import dayjs from "dayjs";
import {
  accountLastFour,
  formatCurrency,
  getCardLastFour,
  padLastFour,
} from "../../../../utils/common-utils";

const imgPath = "/assets/icons";

export const recentTransactionUtils = (
  recentTrans: undefined | null | any[],
  cards: any,
  accounts: any
): any => {
  const timeGroup: any = { date: [], dataArray: [] };

  recentTrans?.forEach(function (trans: any, index: number) {
    const { transactionDatetime: time } = trans || {
      transactionDatetime: "",
    };
    const timeStamp = dayjs(time).format("MMM DD, YYYY");

    const filteredDate = timeGroup?.date.filter(
      (time: any) => time === timeStamp
    );

    const checkDate = Array.isArray(filteredDate)
      ? filteredDate[0] === timeStamp
      : null;

    const { transactionType } = trans;

    const transactionCheck = transactionType === "TRDR" ? true : false;

    if (checkDate) {
      timeGroup.dataArray.push({
        key: index,
        icon: `${imgPath}/${
          transactionCheck ? "card-payment-icon" : "credit-payment-icon"
        }.png`,
        iconClass: `${transactionCheck ? "debit-icon" : "credit-icon"}`,
        dateTime: time && dayjs(time).format("MMM DD, YYYY"),
        title:
          (trans?.merchant?.name || "") +
            (trans?.merchant?.name && trans?.description ? " - " : "") +
            (trans?.description || "") || "",
        amount:
          trans?.transactionAmount?.amount > 0
            ? "+" +
              formatCurrency(
                trans?.transactionAmount?.amount,
                trans?.transactionAmount?.currency
              )
            : formatCurrency(
                trans?.transactionAmount?.amount,
                trans?.transactionAmount?.currency
              ),
        cardLastFour:
          trans?.cardLastFour ||
          (trans?.cardId && getCardLastFour(trans?.cardId, cards)),
        ddaLastFour:
          trans?.accountId && (getDdaNumber(trans?.accountId, accounts) || "-"),
      });
    } else {
      timeGroup.date.push(timeStamp);
      timeGroup.dataArray.push({
        key: index,
        icon: `${imgPath}/${
          transactionCheck ? "card-payment-icon" : "credit-payment-icon"
        }.png`,
        iconClass: `${transactionCheck ? "debit-icon" : "credit-icon"}`,
        dateTime: time && dayjs(time).format("MMM DD, YYYY"),
        title:
          (trans?.merchant?.name || "") +
            (trans?.merchant?.name && trans?.description ? " - " : "") +
            (trans?.description || "") || "",
        amount:
          trans?.transactionAmount?.amount > 0
            ? "+" +
              formatCurrency(
                trans?.transactionAmount?.amount,
                trans?.transactionAmount?.currency
              )
            : formatCurrency(
                trans?.transactionAmount?.amount,
                trans?.transactionAmount?.currency
              ),
        cardLastFour:
          trans?.cardLastFour ||
          (trans?.cardId && getCardLastFour(trans?.cardId, cards)),
        ddaLastFour:
          trans?.accountId && (getDdaNumber(trans?.accountId, accounts) || "-"),
      });
    }
  });

  return timeGroup;
};

const getDdaNumber = (accountId: string, accounts: any) => {
  let ddaNumber: string | undefined | null = undefined;

  if (accounts && accounts?.length > 0) {
    ddaNumber = accounts?.find(
      (account: any) => account?.id === accountId
    )?.ddaNumber;
  }
  if (!ddaNumber) return undefined;
  return padLastFour(accountLastFour(ddaNumber));
};
