import React from "react";
import { useState } from "react";
import { Col, Collapse, Row } from "antd";

import {
  accountLastFive,
  formatCurrency,
  padLastFive,
} from "../../../../utils/common-utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useLabels from "../../../../hooks/use-labels";
import WalletTab from "./WalletTabs";
import { useWindowResize } from "../../../../hooks/use-window-resize";
import { getCardDrawerState } from "../../../../app-store/public/public-action";

const { Panel } = Collapse;

interface IShowModal {
  editAccount: (account: any) => void;
  addAccount: (account: any) => void;
  selectedAccount: (account: any) => void;
  accounts: Array<any>;
}

const AccountTabs = ({
  editAccount,
  addAccount,
  selectedAccount,
  accounts,
}: IShowModal) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const resizeWidth = useWindowResize() > 992;
  const showCard = useWindowResize() < 992;

  const {
    accountTypeLabel,
    accountNumbertitle,
    totalBlanceTitle,
    routingNumbertitle,
  } = useLabels([
    "accountTypeLabel",
    "accountNumbertitle",
    "totalBlanceTitle",
    "routingNumbertitle",
  ]);

  const [activePanel, setActivePanel] = useState(0);

  const setCurrentAcc = ([index]: any) => {
    if (index) {
      selectedAccount(accounts[parseInt(index)]);
      setActivePanel(parseInt(index));
    }
  };

  const handleTransactionClick = (transactionProps: any) => {
    navigate("/transactions", {
      state: {
        transactionProps,
        pageTitle: "Transaction History",
        pageCrumbs: [
          { title: "Account Summary", link: "/" },
          { title: "Transactions" },
        ],
      },
    });
    localStorage.setItem(
      "Transactions_Props",
      JSON.stringify({
        state: {
          transactionProps,
          pageTitle: "Transaction History",
          pageCrumbs: [
            { title: "Account Summary", link: "/" },
            { title: "Transactions" },
          ],
        },
      })
    );
  };

  const tabHeaderText = (account: any) => {
    const { aggregatedAvailableBalance, routingNumber, ddaNumber, type } =
      account;

    const { currency, amount } = aggregatedAvailableBalance;

    const accountHeaderTabs = [
      totalBlanceTitle && {
        title: totalBlanceTitle,
        content: currency ? formatCurrency(+amount, currency) : "-",
        className: "available-balance full-flex-container",
      },
      ddaNumber && {
        title: accountNumbertitle,
        content: ddaNumber ? <AccNumber ddaNumber={ddaNumber} /> : "-",
        className: "account-number-title",
      },
      type && {
        title: accountTypeLabel,
        content: type[0].toUpperCase() + type.slice(1).toLowerCase() || "-",
        className: "account-type",
      },
      routingNumber && {
        title: routingNumbertitle,
        content: routingNumber || "-",
        className: "routing-title",
      },
    ];

    return (
      <Row
        className="account-info-content dashboard-account-panel"
        gutter={resizeWidth ? [50, 50] : [0, 0]}
      >
        {accountHeaderTabs.map((tab, index) => {
          const { content, title, className } = tab || {
            content: "",
            title: "",
            className: "",
          };

          return (
            content && (
              <Col className={`account-type ${className}`} key={index}>
                <div className="label">{title}</div>
                <div
                  className={
                    title === "Program Name" || title === "Account Name"
                      ? "line-clamp-1 wallet-title"
                      : "wallet-title value"
                  }
                >
                  {content}
                </div>
              </Col>
            )
          );
        })}
      </Row>
    );
  };

  return (
    <div className="accounts-collapse-cotainer">
      <Collapse
        bordered={false}
        activeKey={[activePanel]}
        expandIconPosition="end"
        accordion={true}
        onChange={setCurrentAcc}
        className="accounts-collapse-content"
      >
        {accounts &&
          accounts.map((accountItem: any, index) => (
            <Panel
              header={tabHeaderText(accountItem)}
              key={index}
              className="accounts-collapse-content-panel"
              extra={
                <div className="collapse-icon">
                  <span
                    className="option-icon trasction-icon"
                    onClick={() =>
                      handleTransactionClick({ accountItem, accounts })
                    }
                  >
                    transaction
                  </span>

                  <span
                    className={`option-icon wallet-icon ${
                      !showCard ? "disabled-cursor" : ""
                    }`}
                    onClick={() => {
                      if (showCard) {
                        dispatch(getCardDrawerState(true));
                        const bodyTag = document.getElementsByTagName("body");
                        bodyTag[0].style.overflow = "hidden";
                      }
                    }}
                  >
                    wallet
                  </span>
                </div>
              }
            >
              <WalletTab
                accountItem={accountItem}
                handleTransactionClick={handleTransactionClick}
                addAccount={addAccount}
                editAccount={editAccount}
              />
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

const AccNumber = ({ ddaNumber }: any) => {
  const [showAccount, setShowAccount] = useState(false);

  const eyeFilledImg = "/assets/icons/active-eye.png";
  const eyeHideImg = "/assets/icons/hide-eye.png";

  return (
    <div onClick={(e) => e.stopPropagation()} className="account-number">
      {showAccount ? (
        <span className="account-number-digits">{ddaNumber}</span>
      ) : (
        <span
          className="account-number-digits"
          dangerouslySetInnerHTML={{
            __html: padLastFive(accountLastFive(ddaNumber)),
          }}
        />
      )}

      {showAccount ? (
        <img
          className="show-hide-icon"
          src={eyeFilledImg}
          alt="eyeFilledImg"
          onClick={() => setShowAccount((prev) => !prev)}
        />
      ) : (
        <img
          className="show-hide-icon"
          src={eyeHideImg}
          alt="eyeHideImg"
          onClick={() => setShowAccount((prev) => !prev)}
        />
      )}
    </div>
  );
};

export default AccountTabs;
