import { Col, Row } from "antd";
import moment from "moment";
import { Fragment, useState } from "react";
import { fetchProfileData, walletTransfer } from "../../../../apis/b2c_api";
import ContentBox from "../../../../components/content-box";
import CustomButton from "../../../../components/custom-button";
import FlexButtons from "../../../../components/flex-button";
import Loader from "../../../../components/shared/loader";
import customToast from "../../../../components/shared/services/toaster-service";
import useLabels, { getLabel } from "../../../../hooks/use-labels";
import {
  formatCurrency,
  showErrorNotification,
} from "../../../../utils/common-utils";
import {
  getAccountTitle,
  getWalletCurrency,
} from "../TransferAmount/TransferForm/transfer-form-utils";
import { setUserProfile } from "../../../../app-store/user/user-reducer";
import { useDispatch } from "react-redux";
import "../TransferAmount/TransferForm/styles.css";

const flagIconPath = "/assets/country-flags";

interface ConfirmTransferProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onPrevious: () => void;
}

const ConfirmTransfer = ({
  transferValues,
  onSubmit,
  onPrevious,
}: ConfirmTransferProps) => {
  const {
    accounts,
    amount,
    fromMainAccount,
    toMainAccount,
    fromSubAccount,
    toSubAccount,
    memo,
    fromCurrency,
    toCurrency,
    convertedAmount,
    exchangeRateLine,
  } = transferValues;
  const [loading, setLoading] = useState(false);

  const {
    transferConfirmationTitle,
    transferFromTitle,
    transferToTitle,
    transferMainAccountTitle,
    transferSubAccountTitle,
    transferTransferDateTitle,
    fundsTransferSuccessMessage,
    bankTransferDescription,
  } = useLabels([
    "transferConfirmationTitle",
    "transferFromTitle",
    "transferToTitle",
    "transferMainAccountTitle",
    "transferSubAccountTitle",
    "transferTransferDateTitle",
    "fundsTransferSuccessMessage",
    "bankTransferDescription",
  ]);
  const floatAmount = parseFloat(amount);
  const dispatch = useDispatch();
  const fromWalletCurrency = getWalletCurrency(
    fromMainAccount,
    fromSubAccount,
    accounts
  );
  const transferFunds = () => {
    const transferProps = {
      memo,
      floatAmount,
      fromWalletCurrency,
      fromSubAccount,
      toSubAccount,
    };
    setLoading(true);
    walletTransfer(transferProps)
      .then((response) => {
        setLoading(false);
        customToast.success(fundsTransferSuccessMessage);
        onSubmit(amount);
        const profile = {
          transType: "",
          variableCode: "P2PTransferBeneficiaryAllowedProducts",
        };
        return fetchProfileData(profile);
      })
      .then(({ profile }: any) => {
        dispatch(setUserProfile(profile));
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };
  const fromAccountPreview = [
    {
      label: transferMainAccountTitle,
      content: getAccountTitle(fromMainAccount, accounts),
    },
    {
      label: transferSubAccountTitle,
      content: formatCurrency(amount, fromCurrency),
      icon: `${flagIconPath}/${fromCurrency}.png`,
    },
  ];
  const toAccountPreview = [
    {
      label: transferMainAccountTitle,
      content: getAccountTitle(toMainAccount, accounts),
    },
    {
      label: transferSubAccountTitle,
      content: formatCurrency(convertedAmount, toCurrency),
      icon: `${flagIconPath}/${toCurrency}.png`,
    },
  ];
  return (
    <ContentBox className="between-account-review-container between-account-container">
      <div className="between-account-header">
        <div className="between-account-header-title">
          {transferConfirmationTitle}
        </div>
        <div className="between-account-header-subtitle">
          {bankTransferDescription}
        </div>
      </div>
      <div className="between-account-form-container">
        <Row className="between-account-content">
          <Col lg={3} md={3} sm={3} className="between-account-label">
            {transferFromTitle}
          </Col>
          <Col
            lg={21}
            md={21}
            sm={21}
            className="between-account-select-container"
          >
            <Row gutter={[114, 114]}>
              {fromAccountPreview.map((item, index) => (
                <Col key={index}>
                  <div className="between-account-select-dropdown-review">
                    {item.icon && (
                      <img
                        src={item.icon}
                        alt="flag-icon"
                        className="wallet-option-flag"
                      />
                    )}
                    {item.content}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="between-account-content">
          <Col lg={3} md={3} sm={3} className="between-account-label">
            {transferToTitle}
          </Col>
          <Col
            lg={21}
            md={21}
            sm={21}
            className="between-account-select-container"
          >
            <Row gutter={[114, 114]}>
              {toAccountPreview.map((item, index) => (
                <Col key={index}>
                  <div className="between-account-select-dropdown-review">
                    {item.icon && (
                      <img
                        src={item.icon}
                        alt="flag-icon"
                        className="wallet-option-flag"
                      />
                    )}
                    {item.content}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>

      {exchangeRateLine && (
        <Row>
          <Col lg={18} md={18}>
            <div className="exhange-rate-container">
              <span className="exchange-rate-label">
                {getLabel("exchangeRateLabel")}
              </span>{" "}
              1 {exchangeRateLine}
            </div>
          </Col>
        </Row>
      )}

      {memo && (
        <div className="memo-container">
          <Row>
            <Col lg={24} md={24}>
              <div className="memo-input-field-review">{memo}</div>
            </Col>
          </Row>
        </div>
      )}
      <div className="transfer-date-container">
        <Row>
          <Col lg={18} md={18} sm={18}>
            <div className="transfer-date-content">
              <div className="transfer-date-icon">
                <img src="/assets/images/calendar-solid.png" alt="calendar" />
              </div>
              <div className="transfer-date-meta">
                <div className="transfer-date-meta-title">
                  {transferTransferDateTitle}
                </div>
                <div className="transfer-date-meta-format">
                  {moment().format("MM/DD/YYYY")}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <FlexButtons>
              {!loading ? (
                <Fragment>
                  <CustomButton
                    type="outline"
                    text="Cancel"
                    onClick={onPrevious}
                    className="button-md primary-outline-btn"
                  />
                  <CustomButton
                    type="primary"
                    text="Transfer"
                    onClick={transferFunds}
                    className="button-md primary-black-btn"
                  />
                </Fragment>
              ) : (
                <Loader className="confirm-review-loader" />
              )}
            </FlexButtons>
          </Col>
        </Row>
      </div>
    </ContentBox>
  );
};

export default ConfirmTransfer;
