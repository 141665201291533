import {configs} from "../utils/metadata";
import defaults from "../utils/defaults";
import {getLanguage} from "../utils/language-helper";
import { isJSON } from "../utils/common-utils";

const useConfigs = (keys: Array<string>): any => {
    const configData = {} as any;

    const configurations = configs();
    const languageCode = getLanguage();

    keys.forEach((key) => {
        let configInfo = configurations.find(
            (conf: any) =>
                conf.config_code === key &&
                conf.language_code &&
                conf.language_code === languageCode
        );

        if (!configInfo) {
            configInfo = configurations.find((conf: any) => conf.config_code === key);
        }

        let data = configInfo ? configInfo.config_value : defaults[key];

        if (isJSON(data)) {
            data = JSON.parse(data);
        }
        configData[key] = data;
    });

    return configData;
};

export default useConfigs;
