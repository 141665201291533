import axios from '../../utils/axios'
import AuthResponse from '../../interfaces/user'

interface LoginValues {
  email: string;
  password: string;
  device_info?: {
    device_unique_id?: string;
  };
};

interface MfaLoginValues {
  otpToken: string;
  otp: string;
  device_info?: {
    device_unique_id?: string;
  };
};

export const prepareLoginValues = (values: LoginValues) => {
  const { email, password, device_info } = values;
  return {
    username: email,
    password,
    device_info: device_info,
  };
};

export const login = async (values: LoginValues) => {
  const response = await axios.post(`/auth/login`, prepareLoginValues(values));

  return response.data as AuthResponse;
};

export const loginWithAuth = async (authToken: any) => {
  const response = await axios.post(`/auth/login`, {
    auth_token: authToken
  })

  return response.data as AuthResponse
};

export const prepareMfaLoginValues = (values: MfaLoginValues) => {
  const { otpToken, otp, device_info } = values;
  return {
    mfa_token: otpToken,
    otp,
    device_info: device_info
  }
};

export const mfaLogin = async (values: MfaLoginValues) => {
  const response = await axios.post(`/auth/login`, prepareMfaLoginValues(values))

  return response.data as AuthResponse
}

export const refreshToken = async (refresh_token: string) => {
  const response = await axios.get(`/auth/token`, {
    Authorization: `Bearer ${refresh_token}`
  })

  return response.data as AuthResponse
}

export const resetUserDetails = () => {
  const resetKeys =['activeUser', 'userProfile', 'Cloud_SecureURL'];
  resetKeys.forEach((key) =>
    localStorage.removeItem(key)
  )
}
