import { FC } from "react";

import TransferForm from "./TransferForm";

interface TransferAmountProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onClose: () => void;
}

const TransferAmount: FC<TransferAmountProps> = (props) => {
  return <TransferForm {...props} />;
};
export default TransferAmount;
