import useStyles from '../../../../hooks/use-styles'

const SignUpPrimaryButton = (props: any) => {
  const { htmlType } = props

  const {
    signupPrimaryButtonLabelColor,
    signupPrimaryButtonBackground,
    signupPrimaryButtonBorder
  } = useStyles([
    'signupPrimaryButtonLabelColor',
    'signupPrimaryButtonBackground',
    'signupPrimaryButtonBorder'
  ])

  const buttonProps = {
    ...(htmlType && { htmlType })
  }
  return (
    <button
      disabled={props.disabled}
      className={`custom-button ${props.className!}`}
      style={{
        backgroundColor: signupPrimaryButtonBackground,
        border: `1px solid ${signupPrimaryButtonBorder}`,
        color: signupPrimaryButtonLabelColor,
        ...(props.style && props.style)
      }}
      onClick={props.onClick}
      {...buttonProps}
    >
      <span>{props.text}</span>
    </button>
  )
}

export default SignUpPrimaryButton
