import React, { Fragment } from "react";
import { useState } from "react";
import { Col, Row, Select, DatePicker } from "antd";

import CustomButton from "../../../../components/custom-button";
import useLabels from "../../../../hooks/use-labels";
import { endOfWeek, startOfWeek } from "../../../../utils/common-utils";
import {
  accountOptions,
  statusOptions,
  walletOptions,
} from "./transaction-filter-utils";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

interface TransactionsFilterProps {
  onApply: (fieldValues: any) => any;
  onReset: (fieldValues: any) => any;
  filterValues: any;
  transactions: any;
}

const TransactionsFilter: React.FC<TransactionsFilterProps> = ({
  onApply,
  onReset,
  filterValues,
}) => {
  const { userProfile } = useSelector((state: any) => state.users);

  const { accounts } = userProfile;

  const [fieldValues, setFieldValues] = useState({
    transactionStatus: filterValues.transactionStatus || null,
    transactionType: filterValues.transactionType || null,
    merchantName: filterValues.merchantName || null,

    mainAccount: filterValues.mainAccount || null,
    walletAccount: filterValues.walletAccount || null,
    transactionDate: filterValues.transactionDate || [
      startOfWeek(),
      endOfWeek(),
    ],
  });

  const [currentWalletOptions, setCurrentWalletOptions] = useState(
    fieldValues.mainAccount
      ? walletOptions(fieldValues.mainAccount, accounts)
      : []
  ) as any;

  const onClear = (key: string) => {
    const updatedFieldValues: any = { ...fieldValues };
    updatedFieldValues[key] = null;
    if (key === "mainAccount") {
      updatedFieldValues["walletAccount"] = null;
    }

    setFieldValues(updatedFieldValues);
    if (key === "mainAccount") {
      setCurrentWalletOptions([]);
    }
  };

  const { applyFilterText } = useLabels(["applyFilterText"]);

  const onChange = (key: string, value: string) => {
    const setWalletKeys: any = {
      mainAccount: () => setCurrentWalletOptions(walletOptions(value, accounts)),
    };

    const resetWalletValue: any = {
      mainAccount: { walletAccount: null },
    };

    setWalletKeys[key] && setWalletKeys[key]();

    setFieldValues((prev: any) => ({
      ...prev,
      [key]: value,
      ...(resetWalletValue[key] && resetWalletValue[key]),
    }));
  };

  const applyFiltersHandler = () => {
    onApply(fieldValues);
  };

  const resetFiltersHandler = () => {
    const resetValues = {
      transactionStatus: null,
      transactionType: null,
      merchantName: "",
      mainAccount: null,
      walletAccount: null,
      transactionDate: null,
    };

    setFieldValues(resetValues);
    onReset(resetValues);
  };

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")] },
    { label: "Last Week", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  return (
    <Fragment>
      <Row
        gutter={[30, 30]}
        className="justify-content-between transaction-filters"
      >
        <Col
          xl={6}
          md={14}
          sm={14}
          xs={26}
          className="transaction-filter-column"
        >
          <RangePicker
            className="input-field-container"
            value={fieldValues.transactionDate}
            format={"YYYY-MM-DD"}
            onChange={(value: any) => onChange("transactionDate", value)}
            allowClear
            presets={rangePresets}
          />
        </Col>

        {/* <Col
          xl={4}
          md={12}
          sm={12}
          xs={24}
          className="transaction-filter-column"
        > */}
        {/* <Select
            className="input-field-container"
            placeholder="Merchant"
            // placeholder="Transaction Type"
            // onSelect={(value: any) => onChange('merchantName', value)}
            onSelect={(value: any) => onChange('transactionType', value)}
            value={fieldValues.merchantName}
            // value={fieldValues.transactionType}
            allowClear
          >
            {merchantOptions(transactions).map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.value} >
                  {item.label}
                </Select.Option>
              )
            })}
          </Select> */}
        {/* <Input
            className="input-field-container merchant-name-input-field"
            placeholder="Filter by Merchant Name"
            value={fieldValues.merchantName}
            name="merchantName"
            onChange={(ev) => onChange("merchantName", ev.target.value)}
          />
        </Col> */}

        <Col
          xl={6}
          md={14}
          sm={14}
          xs={26}
          className="transaction-filter-column"
        >
          <Select
            className="input-field-container merchant-name-input-field"
            placeholder="Filter by Status"
            onSelect={(value: any) => onChange("transactionStatus", value)}
            value={fieldValues.transactionStatus}
            onClear={() => {
              onClear("transactionStatus");
            }}
            allowClear
          >
            {statusOptions().map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Col>

        <Col
          xl={6}
          md={14}
          sm={14}
          xs={26}
          className="transaction-filter-column"
        >
          <Select
            className="input-field-container"
            placeholder="Filter by Account"
            onSelect={(value: any) => onChange("mainAccount", value)}
            value={fieldValues.mainAccount}
            onClear={() => {
              onClear("mainAccount");
            }}
            allowClear
          >
            {accountOptions(accounts).map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Col>

        {/* <Col
          xl={4}
          md={12}
          sm={12}
          xs={24}
          className="transaction-filter-column"
        >
          <Select
            className="input-field-container"
            placeholder="Filter by Account Wallet"
            onSelect={(value: any) => onChange("walletAccount", value)}
            value={fieldValues.walletAccount}
            onClear={() => {
              onClear("walletAccount");
            }}
            allowClear
            style={{ width: "max-content", maxWidth: "200px" }}
          >
            {filterWalletOptions.map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Col> */}
        <Col
          xl={6}
          md={14}
          sm={14}
          xs={26}
          className="transaction-filter-column"
        >
          <Row gutter={[10, 10]}>
            <Col xl={12} md={12} sm={12} xs={24}>
              <CustomButton
                className="apply-filter-btn"
                text={applyFilterText}
                type="primary"
                onClick={applyFiltersHandler}
              />
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <CustomButton
                className="clear-filter-btn"
                text="X Clear"
                type="outline"
                onClick={resetFiltersHandler}
              />
              {/* <Button shape="round" className="apply-filter-btn" >
              X clear
            </Button> */}
              {/* <span className="clear-filter" onClick={resetFiltersHandler}>
            X Clear
            </span> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TransactionsFilter;
