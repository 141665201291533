import ResetPasswordForm from "./ResetPasswordForm";
import ForgotPasswordLayout from "..";
import { useLocation } from "react-router-dom";

const ResetPassword = (): JSX.Element => {
  const location = useLocation();

  return (
    <ForgotPasswordLayout>
      <ResetPasswordForm state={location.state} />
    </ForgotPasswordLayout>
  );
};

export default ResetPassword;
