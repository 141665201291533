import { useState, useEffect, Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import {
  authTokenAvailable,
  redirectLogInPage,
  getAccessToken,
  callBackUrlAvailable,
  parseURL,
  getPageUrl,
  consumerIdAvailable,
} from "../utils/reUseFunction";
import { loginWithAuth } from "../app-store/user/user-action";
import RefreshAuth from "./RefreshAuth";
import Dashboard from "../components/ContentArea/Dashboard";

import AuthWrapper from "./AuthWrapper";
import Login from "./Login";
import WalletTransfer from "./Main/WalletTransfer";
import { setLoginUser } from "../app-store/user/user-reducer";
import Loader from "../components/shared/loader";
import { showErrorNotification } from "../utils/common-utils";
import TransactionHistory from "./Main/TransactionHistory";
import FriendFamily from "../components/ContentArea/Friend&Family";
import TransferFunds from "../components/ContentArea/Friend&Family/TransferFunds";
import BankTransfer from "../components/ContentArea/BankTransfer";
import BankTransactonHistory from "../components/ContentArea/BankTransactonHistory";
import AddBank from "../components/ContentArea/AddBank";
import Congratulations from "../components/User/Congratulations";
import SendMoney from "../components/ContentArea/BankTransfer/SendMoney";
import ReceiveMoney from "../components/ContentArea/BankTransfer/ReceiveMoney";
import "react-toastify/dist/ReactToastify.css";
import CardOperations from "./CardOperations";
import Enrollment from "../components/Enrollment/enrollment";
import SignupCongratulations from "../components/Enrollment/Enrollment-steps/congratulations";
import ForgotPassword from "./Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import IdleTimeoutComponent from "./IdleTimeoutComponent";
import TransferFundCards from "./Main/TransferCards";
import TransferHistory from "../components/ContentArea/Friend&Family/TransferHistory/TransferHistory";
import ConsumerVerification from "./Auth/ConsumerVerification";

const AppContainer = () => {
  let navigate = useNavigate();
  const authToken = authTokenAvailable();
  const callBackUrl: any = callBackUrlAvailable();
  const consumerId = consumerIdAvailable();

  const [loading, setLoading] = useState(authToken);

  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      loginWithAuthToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    const authTokenCallBackUrl = redirectLogInPage();
    const accessTokenInLogInAPI = getAccessToken();

    if (authTokenCallBackUrl || accessTokenInLogInAPI) {
      const currentUrl = getPageUrl();
      if (currentUrl.includes("auth_token")) {
        navigate("/");
      } else {
        navigate(`/${currentUrl}`);
      }
      if (consumerId) {
        navigate("/");
      } else {
        navigate(`/${currentUrl}`);
      }
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithAuthToken = async () => {
    await loginWithAuth(authToken)
      .then((response: any) => {
        dispatch(setLoginUser(response));
        const currentUrl = getPageUrl();
        const isAuthToken = currentUrl.includes("auth_token");
        if (isAuthToken) {
          navigate("/");
        } else {
          navigate(`/${currentUrl}`);
        }
        if (consumerId) {
          navigate("/");
        } else {
          navigate(`/${currentUrl}`);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error);
        if (error) {
          if (callBackUrl === false) {
            navigate("/login");
          }
          if (typeof callBackUrl === "string") {
            window.location.href = parseURL(callBackUrl);
          }
        }
      });
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<AuthWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="/wallet-transfer" element={<TransferFundCards />} />
          <Route
            path="/between-account-transfer"
            element={<WalletTransfer />}
          />
          <Route path="/transactions" element={<TransactionHistory />} />
          <Route path="/beneficiaries" element={<FriendFamily />} />
          <Route path="/transfer-history" element={<TransferHistory />} />
          <Route path="/transfer-funds" element={<TransferFunds />} />
          <Route path="/Bank-Transfer" element={<BankTransfer />} />
          <Route path="/Add-Bank" element={<AddBank />} />
          <Route path="/Congratulations" element={<Congratulations />} />
          <Route path="/Send-Money" element={<SendMoney />} />
          <Route path="/Receive-Money" element={<ReceiveMoney />} />
          <Route path="/card-operations" element={<CardOperations />} />
          <Route
            path="/Bank-Transfer-History"
            element={<BankTransactonHistory />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/consumer-verification"
          element={<ConsumerVerification />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/completion" element={<SignupCongratulations />} />
      </Routes>
      <ToastContainer />
      <RefreshAuth />
      <IdleTimeoutComponent />
    </Fragment>
  );
};

export default AppContainer;
