import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { getSecureURLDetails, timeDiffFromNow } from "../utils/common-utils";
import moment from "moment";
import { refreshToken, resetUserDetails } from "../app-store/user/user-action";
import { logoutUser, setLoginUser } from "../app-store/user/user-reducer";
import { parseURL } from "../utils/reUseFunction";

let timeoutRef: any;
const stopTokenRefresh = () => {
  if (timeoutRef) {
    clearTimeout(timeoutRef);
  }
};

const RefreshAuth = (props: any) => {
  const { activeUser } = useSelector((state: any) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeUser.access_token) {
      const { exp }: any = jwt_decode(activeUser.access_token);

      const expiryAt = moment(exp * 1000);
      const TIME_IN_SECONDS = timeDiffFromNow(expiryAt);
      const REFRESH_TIME = TIME_IN_SECONDS - 30;

      timeoutRef = setTimeout(() => {
        stopTokenRefresh();
        if (activeUser.refresh_token) {
          refreshToken(activeUser.refresh_token)
            .then((response: any) => {
              dispatch(setLoginUser(response))
            })
            .catch((error) => {
              if (error) {
                const secureURL = getSecureURLDetails();

                if (secureURL) {
                  resetUserDetails();
                  const { callback_url } = JSON.parse(secureURL);
                  window.location.href = parseURL(callback_url);
                } else {
                  dispatch(logoutUser());
                }
              }
            });
        }
      }, REFRESH_TIME * 1000);
    } else {
      stopTokenRefresh();
    }

    return () => {
      stopTokenRefresh();
    };
  }, [activeUser.access_token, activeUser.refresh_token, dispatch]);

  return <></>;
};

export default RefreshAuth;
