import { Col, Row } from "antd";
import "./styles.css";
import Footer from "../Main/Footer";
import { ReactNode } from "react";

const imgPath = "/assets/images";

const ForgotPasswordLayout = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <div className="forget-password-container">
      <Row gutter={[0, 0]}>
        <Col lg={9} md={9} sm={9}>
          <div className="forget-password-bg-container">
            <div className="forget-password-logo-container">
              <img src={`${imgPath}/login-page-main-logo.png`} alt="logo" />
            </div>
          </div>
        </Col>
        <Col lg={15} md={15} sm={15}>
          <div className="forget-password-content-container">
            <div className="forget-password-form-container">{children}</div>
            <Footer className="forget-password-footer" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPasswordLayout;
