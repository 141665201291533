import React from "react";
import { Modal } from "antd";

const CardModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sURL = "http://192.168.1.100:4101/secure-urls/surl_3my65x1lrnd";
  return (
    <Modal
      title={"Card Secure Details"}
      width={"1200px"}
      footer={null}
      centered
      open={open}
      onCancel={() => setOpen(false)}
      bodyStyle={{
        height: "700px",
        padding: "10px 16px",
        backgroundColor: "transparent",
      }}
    >
      <iframe
        src={sURL}
        name="card-widget"
        height={"100%"}
        width={"100%"}
        style={{ border: "none" }}
        title={"Card Details"}
      ></iframe>
    </Modal>
  );
};

export default CardModal;
