import { Layout } from "antd";
import Sidebar from "./Sidebar";
import TopBar from "./Header";
import Footer from "./Footer";
import "./main.style.css";

const { Content } = Layout;

const MainView = (props: { children?: JSX.Element }) => {
  return (
    <div className="main-wrapper">
      <div className="dashboard-style">
        <Layout>
          <Sidebar />
          <Layout className="site-layout site-background-image">
            <TopBar />
            <Content className="site-layout-background">
              {props.children && props.children}
            </Content>
            <Footer className="dashboard-footer-container" />
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

export default MainView;
