import { useEffect, useState } from "react";
import { Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useStyles from "../../../../hooks/use-styles";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";
import useLabels from "../../../../hooks/use-labels";
import { removeRecipient } from "../../../../apis/b2c_api";
import { getConsumerId } from "../../../../utils/reUseFunction";
import {
  accountLastFour,
  padLastFour,
  showErrorNotification,
} from "../../../../utils/common-utils";
import { useNavigate } from "react-router-dom";
import MaterialModal from "../../../Enrollment/Material-Modal";
import ConfirmationModal from "../../../Enrollment/Material-Modal/Confirmation-Modal/confirmation-modal";
import Meta from "antd/es/card/Meta";

interface FamilyDataProps {
  data: any;
  getRecipientDetails: Function;
}

const FriendFamilyTable = ({ data, getRecipientDetails }: FamilyDataProps) => {
  const consumerId = getConsumerId();
  let navigate = useNavigate();
  const { lightGrayColor } = useStyles(["lightGrayColor"]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<any | null>(null);

  const { nameTitle, emailTitle, phoneTitle, transferFundsButtonTitle } =
    useLabels([
      "nameTitle",
      "emailTitle",
      "phoneTitle",
      "transferFundsButtonTitle",
    ]);

  const transferFunds = (record: any) => {
    navigate("/transfer-funds", {
      state: {
        record,
        pageTitle: "Transfer Funds",
      },
    });
    localStorage.setItem(
      "TransferFunds_Props",
      JSON.stringify({
        state: {
          record,
        },
      })
    );
  };

  const openModal = (record: any) => {
    setConfirmOpen(true);
    setRecordToDelete(record);
  };

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationTitle: "Are you sure",
      confirmationText: "You want to delete this recipient.",
      closeLabel: "Cancel",
      confirmButtonText: "Yes, I am sure",
      onClose: () => setConfirmOpen(false),
      onConfirm: () => deleteRecipient(recordToDelete),
    };

    return (
      <MaterialModal
        open={true}
        handleClose={() => setConfirmOpen(false)}
        width={"25rem"}
      >
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  const deleteRecipient = (delectItem: any) => {
    const removebody = {
      consumerId: consumerId,
      recipientId: delectItem.id,
    };
    removeRecipient(removebody)
      .then((res) => {
        getRecipientDetails();
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const columns: any = [
    {
      title: "Nickname",
      dataIndex: "nickName",
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="initials-friends-and-family">
              {(record.firstName[0] + record.lastName[0]).toUpperCase()}
            </div>
            <div style={{ marginLeft: "10px" }}> {record.nickName}</div>
          </div>
        );
      },
    },
    {
      title: "Account Title",
      dataIndex: "title",
      render: (text: any, record: any) => {
        const { title } = record.entity;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>{title}</div>
        );
      },
    },
    {
      title: "Account Number",
      dataIndex: "ddaNumber",
      render: (text: any, record: any) => {
        const { ddaNumber } = record.entity;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {padLastFour(accountLastFour(ddaNumber))}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "x",
      render: (text: any, record: any) => (
        <FlexButtons className="w-100 h-36 justify-content-end">
          <CustomButton
            className="layout-button-small-width layout-button-small-height"
            type="primary"
            onClick={() => transferFunds(record)}
            text={transferFundsButtonTitle}
          />
          <span
            onClick={() => openModal(record)}
            className="delect-icon"
            style={{ color: lightGrayColor }}
          >
            <DeleteOutlined />
          </span>
        </FlexButtons>
      ),
    },
  ];

  return (
    <div className="friend-family-table">
      <Table
        columns={columns}
        dataSource={data}
        className="custom-ant-pagination"
        pagination={{ pageSize: 8 }}
        rowKey={"id"}
      />
      {renderConfirmModal()}
    </div>
  );
};

export default FriendFamilyTable;
