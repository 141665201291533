
interface PreviewProps {
  label: string;
  value: string;
  labelColor?: string;
  textColor?: string;
  labelClass?: string;
  maskValue?: boolean;
}

export const PreviewContent = (props: PreviewProps) => {
  const { label, value, labelClass, maskValue } = props;
  return (
    <>
      <div className="review-detail">
        <ul>
          <li className={`label ${labelClass}`}>
            <span>{label}</span>
            <span className="detail-value">
              {maskValue &&
                <span style={{fontSize: "7px", verticalAlign: "top"}}>
                  &#9679;&#9679;&#9679;&#9679;&#9679;
                </span>
              }
              {value}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};
