import { Col, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import {
  fetchProfileData,
  sendMoneyAccounts,
} from "../../../../../apis/b2c_api";
import useLabels from "../../../../../hooks/use-labels";
import {
  formatCurrency,
  padLastFour,
  showErrorNotification,
} from "../../../../../utils/common-utils";
import { getSendTransferProps } from "../../../../../utils/reUseFunction";
import ContentBox from "../../../../content-box";
import CustomButton from "../../../../custom-button";
import FlexButtons from "../../../../flex-button";
import Loader from "../../../../shared/loader";
import customToast from "../../../../shared/services/toaster-service";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../../../../../app-store/user/user-reducer";
import { setCurrentAccOption } from "../TransferAmount/TransferForm/transfer-form-utils";

interface ConfirmTransferProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onPrevious: () => void;
  sendTransferProps: any;
  selectedAccount: any;
}

const ConfirmTransfer = (props: ConfirmTransferProps) => {
  const { userProfile } = useSelector((state: any) => state.users);
  const { accounts } = userProfile;

  const [loading, setLoading] = useState(false);
  const { amount, memo, fromAccount } = props.transferValues;
  const localStorageSendMoneyData = getSendTransferProps();
  const {
    bankName,
    accountNumber,
    accountType,
    accountTitle,
    label,
    country,
    currencyCode,
    id,
    routingNumber,
  } = props.sendTransferProps
    ? props.sendTransferProps
    : localStorageSendMoneyData.state.sendTransferProps;
  const { value, __typename } = props.selectedAccount;

  const {
    transferConfirmationTitle,
    transferDescription,
    transferAmountTitle,
    transferFromTitle,
    transferToTitle,
    transferTransferDateTitle,
    fundsTransferSuccessMessage,
    grayBorderColor,
    darkBlueColor,
  } = useLabels([
    "transferConfirmationTitle",
    "transferDescription",
    "transferAmountTitle",
    "transferFromTitle",
    "transferToTitle",
    "transferTransferDateTitle",
    "fundsTransferSuccessMessage",
    "grayBorderColor",
    "darkBlueColor",
  ]);

  const titleColor = darkBlueColor;
  const labelColor = "#747F8B";

  const optionColor = "#000000";

  const dispatch = useDispatch();

  const transferFunds = () => {
    setLoading(true);
    const sendMoneyBody = {
      bankAccount: {
        accountNumber: accountNumber ? accountNumber : "",
        accountTitle: accountTitle ? accountTitle : "",
        accountType: accountType ? accountType : "",
        additionalData: {},
        bankName: bankName ? bankName : "",
        country: country ? country : "",
        currencyCode: currencyCode ? currencyCode : "",
        id: id ? id : "",
        initiateVerification: true,
        label: label ? label : "",
        routingNumber: routingNumber ? routingNumber : "",
      },
      companyId: "",
      description: memo ? memo : "",
      entity: {
        id: value,
        type: __typename === "Account" && "ACCOUNT",
      },
      externalRefNo: "",
      individualId: "",
      sameDayTransfer: true,
      transferAmount: {
        amount: parseFloat(amount),
        currency: currencyCode,
      },
      transferDate: moment().format("YYYY-MM-DD"),
    };
    sendMoneyAccounts(sendMoneyBody)
      .then((response) => {
        setLoading(false);

        const profile = {
          transType: "",
          variableCode: "P2PTransferBeneficiaryAllowedProducts",
        };

        customToast.success(fundsTransferSuccessMessage);
        props.onSubmit(response);
        return fetchProfileData(profile);
      })
      .then(({ profile }: any) => {
        dispatch(setUserProfile(profile));
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  return (
    <ContentBox className="confirm-transfer-container-2">
      <div>
        <p
          className="text-left text-2lg weight-600"
          style={{ color: titleColor }}
        >
          {transferConfirmationTitle}
        </p>
        <p
          className="text-left text-sm weight-400"
          style={{ color: labelColor }}
        >
          {transferDescription}
        </p>
      </div>

      <div className="d-flex justify-start mt-2 align-center">
        <p
          className="text-left weight-500 min-width-8 mb-0"
          style={{
            fontSize: "22px",
            color: labelColor,
          }}
        >
          {transferAmountTitle}
        </p>

        <p
          className="text-left text-2lg weight-500 mb-0"
          style={{ color: optionColor, fontSize: "40px" }}
        >
          {formatCurrency(amount, "USD")}
        </p>
      </div>
      <div className="d-flex justify-start mt-2 full-width">
        <p
          className="text-left weight-500 pr-3 mt-1 min-width-8 mb-0"
          style={{
            fontSize: "22px",
            color: labelColor,
          }}
        >
          {transferFromTitle}
        </p>
        <Row className="full-width">
          <Col md={6}>
            <div className="text-left">
              <p className="weight-600 mt-1" style={{ color: optionColor }}>
                {setCurrentAccOption(accounts, fromAccount).label}
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content mt-2">
        <p
          className="text-left  weight-500 pr-3 mt-1 min-width-8"
          style={{
            fontSize: "22px",
            color: labelColor,
          }}
        >
          {transferToTitle}
        </p>

        <Row className="full-width">
          <Col md={6}>
            <div className="text-left">
              <p className="weight-600 mt-1" style={{ color: optionColor }}>
                {bankName}
                {padLastFour(
                  accountNumber
                    .toString()
                    .substring(accountNumber.toString().length - 4)
                )}
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <p
          className="text-left text-md mt-1 weight-400 pr-5"
          style={{
            color: grayBorderColor,
          }}
        >
          Memo (Optional)
        </p>

        <p
          className="full-width break-word"
          style={{
            borderBottom: `1.5px solid ${grayBorderColor}`,
            color: labelColor,
          }}
        >
          {memo}
        </p>

        <div className="d-flex mt-2 justify-between">
          <div style={{ width: "4rem" }}>
            <img
              src={require("./../calendar.png")}
              width={"40px"}
              height={"40px"}
              className="mt-1"
              alt="calendar"
            />
          </div>

          <div>
            <p style={{ color: labelColor }}>{transferTransferDateTitle}</p>
            <p> {moment().format("MM/DD/YYYY")} </p>
          </div>
        </div>
      </div>

      <FlexButtons className="layout-buttons justify-end mt-1 mb-4">
        {!loading ? (
          <>
            <CustomButton
              type="outline"
              className="mt-5 full-width"
              style={{ Color: "#006CF8" }}
              text="Back"
              onClick={props.onPrevious}
            />
            <CustomButton
              type="primary"
              className="mt-5 full-width"
              style={{ backgroundColor: "#006CF8" }}
              onClick={transferFunds}
              text="Send"
            />
          </>
        ) : (
          <Loader className="custom-loader" />
        )}
      </FlexButtons>
    </ContentBox>
  );
};
export default ConfirmTransfer;
