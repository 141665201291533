import { Form, Input } from "antd";
import { verifyBankAccount } from "../../../../apis/b2c_api";
import useLabels from "../../../../hooks/use-labels";
import { showErrorNotification } from "../../../../utils/common-utils";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";

interface VerifyModalProps {
  congrationshowDrawer: Function;
  bankAccountId: any;
}

const BankTransferVerify = ({
  congrationshowDrawer,
  bankAccountId,
}: VerifyModalProps) => {
  const { bankVerifyTitle, bankVerifydescription } = useLabels([
    "bankVerifyTitle",
    "bankVerifydescription",
  ]);

  const onFinish = (values: any) => {
    const verifyBody = {
      amount1: parseFloat(values.amount1),
      amount2: parseFloat(values.amount2),
      bankAccountId: bankAccountId.id,
    };

    verifyBankAccount(verifyBody)
      .then((res) => {
        congrationshowDrawer();
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="bank-transfer-verify">
        <div className="desposit-amounts-title">
          <h1>{bankVerifyTitle}</h1>
          <p>{bankVerifydescription}</p>
        </div>
        <div className="deposit-amount-input">
          <Form
            name="verify"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <p>Deposit amount #1</p>
            <Form.Item
              name="amount1"
              className="mb-34"
              rules={[
                { required: true, message: "Please input your amount #1!" },
              ]}
            >
              <Input type="number" prefix={"$"} placeholder="0.00" />
            </Form.Item>
            <p>Deposit amount #2</p>
            <Form.Item
              name="amount2"
              className="mb-80"
              rules={[
                { required: true, message: "Please input your amount #2!" },
              ]}
            >
              <Input type="number" prefix={"$"} placeholder="0.00" />
            </Form.Item>
            <Form.Item className="verify-button">
              <FlexButtons className="ayout-buttons justify-center">
                <CustomButton
                  type="primary"
                  className="w-160"
                  style={{ backgroundColor: "#006CF8" }}
                  htmltype="submit"
                  text="Verify"
                />
              </FlexButtons>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BankTransferVerify;
