import FlexButtons from "../../../../components/flex-button";
import CustomButton from "../../../../components/custom-button";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import customToast from "../../../../components/shared/services/toaster-service";
import axiosAPI from "../../../../utils/axios";
import EnrollmentHeader from "../../../../components/Enrollment/header/header";
import useStyles from "../../../../hooks/use-styles";
import React, { useState } from "react";
import { errorMessage, fieldValidator } from "../../../../utils/validation";
import { showErrorNotification } from "../../../../utils/common-utils";
import MaterialModal from "../../../../components/Enrollment/Material-Modal";
import ConfirmationModal from "../../../../components/Enrollment/Material-Modal/Confirmation-Modal/confirmation-modal";
import { getLabel } from "../../../../hooks/use-labels";
import Loader from "../../../../components/shared/loader";

const ResetPasswordForm = ({ state }: { state: any }): JSX.Element => {
  const { confirmationToken, email } = state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false) as any;
  const [loader, setLoader] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { signUpTitleColor, signUpSubTitleColor } = useStyles([
    "signUpTitleColor",
    "signUpSubTitleColor",
  ]);

  const [fieldValues, setFieldValues] = useState({
    password: "",
    confirmPassword: "",
  }) as any;

  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const backToLogin = () => navigate("/login");

  const validate = (key: string, value: string, options?: any) => {
    const errors = { ...fieldErrors };
    if (!value.trim() && options) {
      errors[key] = errorMessage.required;
    } else if (key === "password" && value.length < 8) {
      errors[key] = "Password must be at least 8 characters long.";
    } else if (key === "password" && value === fieldValues.confirmPassword) {
      delete errors["password"];
      delete errors["confirmPassword"];
    } else if (key === "password" && !fieldValidator.strongPassword(value)) {
      errors[key] =
        "Password must contain at least one uppercase letter, one digit, and one special character.";
    } else {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);
  };

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const openModal = () => {
    setConfirmOpen(true);
  };
  console.log(fieldErrors, "field errors");

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationTitle: "Are you sure",
      confirmationText: "Are you sure you want to cancel reset password flow.",
      closeLabel: "Cancel",
      confirmButtonText: "Yes, I am sure",
      onClose: () => setConfirmOpen(false),
      onConfirm: () => backToLogin(),
    };

    return (
      <MaterialModal
        open={true}
        handleClose={() => setConfirmOpen(false)}
        width={"25rem"}
      >
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  const submitPW = async () => {
    const requiredFields = ["password", "confirmPassword"];
    const errors = {} as any;
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        valid = false;
        errors[field] = errorMessage.required;
      }
    });

    if (fieldValues.password !== fieldValues.confirmPassword) {
      valid = false;
      errors["confirmPassword"] =
        "The two passwords that you entered do not match";
    }
    if (fieldValues.password && fieldValues.password.length < 8) {
      valid = false;
      errors["password"] = "Password must be at least 8 characters long.";
    } else if (
      fieldValues.password &&
      !fieldValidator.strongPassword(fieldValues.password)
    ) {
      valid = false;
      errors["password"] =
        "Password must contain at least one uppercase letter, one digit, and one special character.";
    }

    setFieldErrors(errors);
    setLoading(!valid);
    if (valid) {
      if (fieldValues.confirmPassword === fieldValues.password) {
        setLoader(true);
        try {
          await axiosAPI.post(`/identity/password`, {
            email: email,
            password: fieldValues.password,
            email_confirmation_token: confirmationToken,
          });
          customToast.success("Password Updated Successfully");
          setLoader(false);
          backToLogin();
        } catch (error) {
          showErrorNotification(error);
          setLoader(false);
        }
      }
    }
  };

  return (
    <div className="reset-password-form-container enter-password-form">
      <EnrollmentHeader
        title={getLabel("passwordRest")}
        subTitle={getLabel("newPasswordSubtitleText")}
        titleColor={signUpTitleColor}
        subTitleColor={signUpSubTitleColor}
        className="reset-password-title"
      />
      <Form
        name="resetPassword"
        className="password-form form-style"
        layout="vertical"
      >
        <Form.Item
          name="password"
          validateStatus={fieldErrors.password ? "error" : ""}
          help={fieldErrors.password}
        >
          <Input.Password
            className="reset-icon"
            type="password"
            onChange={(event) =>
              onFieldChange("password", event.target.value, true)
            }
            autoComplete="off"
            value={fieldValues.password}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          validateStatus={fieldErrors.confirmPassword ? "error" : ""}
          help={fieldErrors.confirmPassword}
          rules={[
            {
              message: getLabel("confirmPasswordMsg"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(getLabel("passwordDoNotMatch"))
                );
              },
            }),
          ]}
        >
          <Input.Password
            type="password"
            className="reset-icon"
            onChange={(event) =>
              onFieldChange("confirmPassword", event.target.value, true)
            }
            value={fieldValues.confirmPassword}
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <FlexButtons className="mt-5 justify-start direction-row-reverse">
            {loader && (
              <Loader className="custom-loader enter-password-loader" />
            )}
            <CustomButton
              text="Submit"
              className="next-button action-button"
              type="primary"
              disabled={loader || loading}
              onClick={submitPW}
            />
            <CustomButton
              text="Cancel"
              type="outline"
              className="cancel-button action-button"
              disabled={loader}
              onClick={() => openModal()}
            />
          </FlexButtons>
        </Form.Item>
      </Form>
      {renderConfirmModal()}
    </div>
  );
};

export default ResetPasswordForm;
