export const urlParameters = () => {
  let str = window.location.search;

  const secureURLLocal = localStorage.getItem("Cloud_SecureURL");

  if (str) {
    const queryString = str;
    const queryStringObj = Object.fromEntries(new URLSearchParams(queryString));
    localStorage.setItem("Cloud_SecureURL", JSON.stringify(queryStringObj));

    return queryStringObj;
  }

  if (secureURLLocal) {
    return JSON.parse(secureURLLocal);
  }
};

export const getSecureUrl = () => {
  let secureUrls = window.location.pathname;
  if (secureUrls) {
    return secureUrls;
  }
};

export const getLandingPage = () => {
  const urlPage = window.location.href;
  let url = urlPage.split("&");
  const queryString = url[2];
  return queryString;
};

export const getPageUrl = () => {
  const urlPage = window.location.href;
  let url = urlPage.split("/");
  const queryString = url[3];
  return queryString;
};

export const firstNameLastName = (name: any) => {
  if (name) {
    let firstName = name.split(" ");
    return {
      firstName: firstName[0],
      lastName: firstName[1],
    };
  }
};

export const parseURL = (callback_url: any) => {
  return callback_url.includes("https://")
    ? callback_url
    : `https://${callback_url}`;
};

export const getCountryCode = (phoneNumberIntl: any) => {
  const str = phoneNumberIntl;
  if (str) {
    const countryCode = str.substring(0, str.indexOf(" "));
    const number = str.substring(str.indexOf(" ") + 1);
    if (countryCode || number) {
      return {
        countryCode: countryCode,
        number: number,
      };
    } else {
      return null;
    }
  }
};

export const secondsToHms = (d: any) => {
  d = Number(d);

  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var mDisplay = m > 0 ? formatTime(m) + ":" : "00:";
  var sDisplay = s > 0 ? formatTime(s) : "00";
  return mDisplay + sDisplay;
};

export const formatTime = (time: number) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};

export const addEventMessage = (eventName: string, message: string) => {
  window.parent.postMessage({ eventName, message: { data: message } }, "*");
};

export const redirectLogInPage = () => {
  let parameters = urlParameters();
  if (!parameters) {
    return;
  }
  let callBack_Url = parameters.callback_url;
  let auth_Token = parameters.auth_token;

  if (auth_Token && callBack_Url) {
    return true;
  }

  if (!auth_Token && !callBack_Url) {
    return false;
  }
};

export const authTokenAvailable = () => {
  let parameters = urlParameters();
  if (!parameters) {
    return;
  }
  let auth_Token = parameters.auth_token;

  if (auth_Token) {
    return auth_Token;
  }
};

export const consumerIdAvailable = () => {
  let parameters = urlParameters();
  if (!parameters) {
    return;
  }
  let consumer_id = parameters.consumer_id;

  if (consumer_id) {
    return consumer_id;
  }
};

export const callBackUrlAvailable = () => {
  const landingPage = getLandingPage();
  let parameters = urlParameters();
  if (!parameters) {
    return;
  }
  let callBack_Url = parameters.callback_url;

  if (callBack_Url) {
    return `${callBack_Url}?${landingPage}`;
  } else {
    return false;
  }
};

export const getAuthKey = () => {
  const items = JSON.parse(localStorage.getItem("InitData") || "{}");

  return items && items.auth_key;
};

export const getTransactionsProp = () => {
  const items = JSON.parse(localStorage.getItem("Transactions_Props") || "{}");

  return items;
};

export const getSendTransferProps = () => {
  const items = JSON.parse(localStorage.getItem("SendTransfer_Props") || "{}");

  return items;
};

export const getBaseUrl = () => {
  const items = JSON.parse(localStorage.getItem("InitData") || "{}");

  return items && items.auth_url;
};

export const getAccessToken = () => {
  const items = JSON.parse(localStorage.getItem("activeUser") || "{}");

  return items && items.access_token;
};

export const getProfileData = () => {
  return JSON.parse(localStorage.getItem("userProfile") || "{}");
};

export const getConsumerId = () => {
  const items = getProfileData();

  return items && items?.id;
};

export const getUserEmail = () => {
  const items = getProfileData();

  return items?.email;
};

export const getTransferFundsTo = () => {
  const items = JSON.parse(localStorage.getItem("TransferFunds_Props") || "{}");

  return items?.state?.record;
};

export const getUserPhoneNumber = () => {
  const items = getProfileData();

  return `${items?.phoneDetails[0]?.countryDialingCode}${items?.phoneDetails[0]?.phoneNumber}`;
};

export const invalidCharacter = (accountInput: any) => {
  const invalidChars = ["-", "+", "e"];
  accountInput &&
    accountInput.addEventListener("keydown", function (e: any) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
};

export const getToken = () => {
  const items = JSON.parse(localStorage.getItem("activeUser") || "{}");

  return items;
};

export const getRefreshToken = () => {
  const items = JSON.parse(localStorage.getItem("activeUser") || "{}");

  return items && items.refresh_token;
};

export const getB2cKey = () => {
  const items = JSON.parse(localStorage.getItem("InitData") || "{}");
  return items && items.b2c_key;
};

export const getB2cUrl = () => {
  const items = JSON.parse(localStorage.getItem("InitData") || "{}");

  return items && items.b2c_url;
};

export function yyyymmddAndTimeFormate(date: string) {
  let dateNew = new Date(date);
  let dd = dateNew.getDate();
  let mm = dateNew.getMonth() + 1;
  let yyyy = dateNew.getFullYear();

  let hours = dateNew.getHours();
  let minutes = dateNew.getMinutes();
  return `${dd < 10 ? `0${dd}` : dd}-${mm < 10 ? `0${mm}` : mm}-${yyyy} ${
    hours < 10 ? `0${hours}` : hours
  }:${minutes < 10 ? `0${minutes}` : minutes}`;
}

export function yyyymmddDate(date: string) {
  let dateNew = new Date(date);
  let dd = dateNew.getDate();
  let mm = dateNew.getMonth() + 1;
  let yyyy = dateNew.getFullYear();

  return `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
}

export function getOnlyMiliSeconds(date: string) {
  const d = new Date(date);
  let ms = d.getTime();

  return ms;
}
