import { Button } from "antd";
import { PlusOutlined, CalendarOutlined } from "@ant-design/icons";
import useStyles from "../../../../hooks/use-styles";
import useLabels from "../../../../hooks/use-labels";
import { useNavigate } from "react-router-dom";

const BankTransferHeader = () => {
  let navigate = useNavigate();

  const { blueColor, blackColor, tagColor } = useStyles([
    "blueColor",
    "blackColor",
    "tagColor",
  ]);

  const {
    bankTransferTitle,
    bankTransferDescription,
    addBankTransferButtonTilte,
    historyTitle,
  } = useLabels([
    "bankTransferTitle",
    "bankTransferDescription",
    "addBankTransferButtonTilte",
    "historyTitle",
  ]);

  const onHistoryClick = () => {
    navigate("/Bank-Transfer-History", { state: { pageTitle: 'Transaction History' } });
  };

  const onAddBankClick = () => {
    navigate("/Add-Bank", { state: { pageTitle: 'Transfer Funds' } });
  };

  return (
    <div className="friend-family-header">
      <div className="header-title">
        <p
          className="text-left text-2lg weight-600"
          style={{ color: "#2C2A29" }}
        >
          {bankTransferTitle}
        </p>
        <p className="text-left text-sm weight-400" style={{ color: "#2c2a29b3" }}>
          {bankTransferDescription}
        </p>
      </div>
      <div className="right-side-buttons">
        <div className="add-account-btn">
          <Button
            onClick={onAddBankClick}
            style={{ color: blueColor }}
            type="link"
          >
            <PlusOutlined /> {addBankTransferButtonTilte}
          </Button>
        </div>
        <div className="add-account-btn">
          <Button
            onClick={onHistoryClick}
            style={{ color: blueColor }}
            type="link"
          >
            <CalendarOutlined /> {historyTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankTransferHeader;
