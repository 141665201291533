import React, { useState, useEffect } from "react";
import ContentBox from "../../../content-box";
import TransferHistoryTable from "./TransferHistoryTable/TransferHistoryTable";
import "./transfer-history-style.css";
import { fetchRecentTransfers } from "../../../../apis/b2c_api";
import { showErrorNotification } from "../../../../utils/common-utils";
import type { PaginationProps } from "antd";
import Loader from "../../../shared/loader";

const TransferHistory = () => {
  const [loading, setLoading] = useState(true);
  const [trasnferData, setTrasnferData] = useState([]) as any;
  const [paginationProps, setPaginaton] = useState({
    pageNumber: 1,
    limit: 10,
  });

  useEffect(() => {
    getTransferData(paginationProps);
  }, []);

  const getTransferData = ({ pageNumber, limit }: any) => {
    setLoading(true);
    const paginationProps = {
      offset: pageNumber,
      limit,
    };
    fetchRecentTransfers(paginationProps)
      .then(({ transfers }: any) => {
        setLoading(false);
        const { nodes, pageInfo } = transfers;
        setTrasnferData(nodes);

        if (nodes.length === 0) {
          setLoading(false);
          setTrasnferData([]);
        }

        setPaginaton((prev) => ({
          ...prev,
          ...paginationProps,
          totalRecords: pageInfo.totalRecords,
          limit,
          pageNumber,
        }));
      })
      .catch((error: any) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber: number) => {
    const pageDetails = { ...paginationProps, pageNumber };
    getTransferData(pageDetails);
  };

  if (loading) return <Loader />;

  return (
    <div className="transfer-data-container">
      <ContentBox className="transfer-content-box table-content-box">
        <TransferHistoryTable
          dataSource={trasnferData}
          paginationProps={paginationProps}
          onChange={onChange}
          loading={loading}
        />
      </ContentBox>
    </div>
  );
};
export default TransferHistory;
