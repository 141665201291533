import { Col, Row } from "antd";
import { enrollmentFormSteps } from "./enrollmen-steps";
import { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import MaterialModal from "./Material-Modal";
import ConfirmationModal from "./Material-Modal/Confirmation-Modal/confirmation-modal";
import Footer from "../../views/Main/Footer";

const imgPath = "/assets/images";

const Enrollment = () => {
  const [currentForm, setCurrentForm] = useState("emailForm");
  const [userData, setUserData] = useState({});
  const [confirmOpen, showConfirm] = useState(false);

  let navigate = useNavigate();

  const getCurrentFormDetails = () => {
    return enrollmentFormSteps[currentForm];
  };

  const handleNext = () => {
    setCurrentForm(getCurrentFormDetails().next);
  };

  const handlePrevious = () => {
    currentForm === "personalInfo" && setUserData({ ...userData, code: "" });
    setCurrentForm(getCurrentFormDetails().previous);
  };

  const handleDelete = () => {
    navigate("/login");
  };

  const onFormSubmit = (formValues: any) => {
    setUserData((prev) => ({ ...prev, ...formValues }));
    setCurrentForm(getCurrentFormDetails().next);
  };

  const handleResetUserData = () => {
    setUserData({});
  };

  const openModal = () => {
    showConfirm((previousValue) => !previousValue);
  };

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationTitle: "Are you sure",
      confirmationText:
        "You want to cancel this, it will delete everything you have filled",
      closeLabel: "Cancel",
      confirmButtonText: "Yes I am sure",
      onClose: openModal,
      onConfirm: handleDelete,
    };

    return (
      <MaterialModal open={true} handleClose={openModal} width={"25rem"}>
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  const formProps = {
    formData: userData,
    setCurrentForm,
    handleNext,
    handlePrevious,
    handleDelete,
    onFormSubmit,
    openModal,
    handleResetUserData
  };

  const { component } = getCurrentFormDetails();

  return (
    <div className="enrollment-verification-container">
      <Row gutter={[0, 0]}>
        <Col lg={9} md={9} sm={9}>
          <div className="enrollment-bg-container">
            <div className="enrollment-logo-container">
              <img src={`${imgPath}/login-page-main-logo.png`} alt="logo" />
            </div>
          </div>
        </Col>
        <Col lg={15} md={15} sm={15}>
          <div className="enrollment-content-container">
            <div className="enrollment-form-container">
              {component(formProps)}
              {renderConfirmModal()}
            </div>
            <Footer />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Enrollment;
