import { getStorageItem } from "../../../../utils/common-utils";

export const selfAccountDetails = (beneficiaryList: any): any => {
  let filteredArray: Array<any> = [];
  let profileDeleted: string = "";

  const userProfile = getStorageItem("userProfile");
  if (userProfile) {
    const { id: chId } = userProfile;

    filteredArray = beneficiaryList.filter(
      (beneficiary: any) => beneficiary.id !== chId
    );
  } else {
    profileDeleted = "profile-deleted";
  }

  return { profileDeleted, filteredAccounts: filteredArray };
};
