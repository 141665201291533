import React, { useState, useEffect, Fragment } from "react";
import { Drawer } from "antd";
import { getRecipientList } from "../../../apis/b2c_api";
import { isEmpty, showErrorNotification } from "../../../utils/common-utils";
import { useSelector } from "react-redux";
import Loader from "../../shared/loader";
import FriendFamilyTable from "./Friend&FamilyTable";
import FriendFamilyHeader from "./Friend&FamilyHeader";
import AddRecipientModal from "./AddRecipientModal";
import { getConsumerId } from "../../../utils/reUseFunction";
import "./styles.css";

const FriendFamily = () => {
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState([]);

  const consumerId = getConsumerId();
  const { userProfile: profileData } = useSelector((state: any) => state.users);

  useEffect(() => {
    getRecipientDetails();
  }, []);

  const handleSearchName = (searchText: string) => {
    if (searchText) {
      const search = searchText.toLowerCase();
      const filteredEvents = data.filter((item: any) => {
        const name = item.nickName.toLowerCase();
        return name.includes(search);
      });

      setData(filteredEvents);
    } else {
      const body = {
        consumerId: consumerId,
      };
      getRecipientList(body).then((FriendFamily: any) => {
        setData(FriendFamily.recipientsList.nodes);
      });
    }
  };

  const handleSearchEmail = (searchText: string) => {
    if (searchText) {
      const search = searchText.toLowerCase();
      const filteredEvents = data.filter((item: any) => {
        if (item?.email) {
          return item.email.includes(search);
        }
      });

      setData(filteredEvents);
    } else {
      const body = {
        consumerId: consumerId,
      };
      getRecipientList(body).then((FriendFamily: any) => {
        setData(FriendFamily.recipientsList.nodes);
      });
    }
  };

  const handleSearchNumber = (searchText: string) => {
    if (searchText) {
      const filteredEvents = data.filter((item: any) => {
        if (item?.phoneDetails) {
          const numberCode = item?.phoneDetails[0]?.countryDialingCode;
          const phoneNumber = item?.phoneDetails[0]?.phoneNumber;
          const fullNumber = numberCode + phoneNumber;
          return fullNumber?.includes(searchText);
        }
      });

      setData(filteredEvents);
    } else {
      const body = {
        consumerId: consumerId,
      };
      getRecipientList(body).then((FriendFamily: any) => {
        setData(FriendFamily.recipientsList.nodes);
      });
    }
  };

  const getRecipientDetails = () => {
    const body = {
      consumerId: consumerId,
    };
    setLoading(true);
    getRecipientList(body)
      .then((FriendFamily: any) => {
        setData(FriendFamily.recipientsList.nodes);
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  if (isEmpty(profileData) || loading) return <Loader />;

  return (
    <Fragment>
      <div className="friend-family-section">
        <div className="card-item-white">
          <FriendFamilyHeader
            handleSearchName={handleSearchName}
            handleSearchEmail={handleSearchEmail}
            handleSearchNumber={handleSearchNumber}
            showDrawer={showDrawer}
            setData={setData}
          />
          <FriendFamilyTable
            data={data}
            getRecipientDetails={getRecipientDetails}
          />
        </div>
      </div>
      {openDrawer && (
        <Drawer
          className="main-drawer add-beneficiary-drawer"
          placement="right"
          onClose={onClose}
          open={true}
          width="480px"
        >
          <AddRecipientModal
            getRecipientDetails={getRecipientDetails}
            setOpenDrawer={setOpenDrawer}
          />
        </Drawer>
      )}
    </Fragment>
  );
};

export default FriendFamily;
