import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import { BsArrowLeft } from "react-icons/bs";

const SignUpPreviousButton = (props: any): JSX.Element => {
  const {
    signupSecondaryButtonLabelColor,
    signupSecondaryButtonBackground,
    signupSecondaryButtonBorder,
  } = useStyles([
    "signupSecondaryButtonLabelColor",
    "signupSecondaryButtonBackground",
    "signupSecondaryButtonBorder",
  ]);
  const { previousButtonLabel } = useLabels(["previousButtonLabel"]);
  const { text,style, className, ...buttonProps } = props;
  return (
    <button
      className={`custom-button back-button ${className!}`}
      style={{
        backgroundColor: signupSecondaryButtonBackground,
        border: `1px solid ${signupSecondaryButtonBorder}`,
        color: signupSecondaryButtonLabelColor,
        ...style
      }}
      {...buttonProps}
    >
      <BsArrowLeft />
      <span>{text || previousButtonLabel}</span>
    </button>
  );
};

export default SignUpPreviousButton;
