import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddBankForm from "./AddBankForm";
import AddBankFormConfim from "./AddBankFormConfim";
import TransferSuccess from "./TransferSuccess";

const AddBank = () => {
  const [step, setCurrentStep] = useState(1);
  const [transferInformation, setTransferInformation] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (transferValues: any) => {
    setTransferInformation((prev) => ({ ...prev, ...transferValues }));
    handleNext();
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleClose = () => {
    navigate("/");
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const makeAnother = () => {
    setTransferInformation({});
    setCurrentStep(1);
  };

  const walletSteps: any = {
    1: () => <AddBankForm onSubmit={handleSubmit} onClose={handleClose} />,
    2: () => (
      <AddBankFormConfim
        transferValues={transferInformation}
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
      />
    ),
    3: () => (
      <TransferSuccess
        {...transferInformation}
        onClose={handleClose}
        makeAnother={makeAnother}
      />
    ),
  };

  return <>{walletSteps[step]()}</>;
};

export default AddBank;
