import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { Outlet } from "react-router-dom";
import Main from "./Main";

const AuthWrapper = () => {
  const { activeUser } = useSelector((state: any) => state.users);

  const location = useLocation();

  if (activeUser.access_token) {
    return (
      <Main>
        <Outlet/>
      </Main>
    );
  }

  return <Navigate to={`/login`} replace state={{ from: location }} />;
};

export default AuthWrapper;
