import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import useStyles from "../../../../hooks/use-styles";
import useLabels from "../../../../hooks/use-labels";
import { getRecipientList } from "../../../../apis/b2c_api";
import { getConsumerId } from "../../../../utils/reUseFunction";
import CustomButton from "../../../custom-button";

interface FamilyHeaderProps {
  showDrawer: () => void;
  handleSearchName: Function;
  handleSearchEmail: Function;
  handleSearchNumber: Function;
  setData: any;
}

const FriendFamilyHeader = ({
  showDrawer,
  handleSearchName,
  handleSearchEmail,
  handleSearchNumber,
  setData,
}: FamilyHeaderProps) => {
  const [openSearchBar, setOpenSearchBar] = useState(false);

  const consumerId = getConsumerId();
  const navigate = useNavigate();

  const { blackColor, tagColor } = useStyles(["blackColor", "tagColor"]);

  const { fnfMenuTitle, friendFamilyDescription, friendTitle, transferHistoryButtonTitle } = useLabels([
    "fnfMenuTitle",
    "friendFamilyDescription",
    "friendTitle",
    "transferHistoryButtonTitle"
  ]);

  const resetFiltersHandler = () => {
    setOpenSearchBar(false);
    const body = {
      consumerId: consumerId,
    };
    getRecipientList(body).then((FriendFamily: any) => {
      setData(FriendFamily.recipientsList.nodes);
    });
  };

  const searchBar = () => {
    setOpenSearchBar(true);
  };

  const transferHistoryClick = () => {
    navigate('/transfer-history')
  }

  return (
    <div className="friend-family-header">
      <div className="header-title">
        <p
          className="text-left text-2lg weight-600"
          style={{ color: "#2C2A29", fontSize: "24px"}}
        >
          {fnfMenuTitle}
        </p>
        <p className="text-left text-sm weight-400" style={{ color: "#2c2a29b3" }}>
          {friendFamilyDescription}
        </p>
      </div>
      <div className="right-side-buttons">
        {openSearchBar ? (
          <div className="search-consumer">
            <Input
              onChange={(e: any) => handleSearchName(e.target.value)}
              placeholder="Search by name"
            />
            <Input
              onChange={(e: any) => handleSearchEmail(e.target.value)}
              placeholder="Search by email"
            />
            <Input
              onChange={(e: any) => handleSearchNumber(e.target.value)}
              placeholder="Search by number"
            />
            <span
              className="clear-filter pointer align-center ml-15"
              onClick={resetFiltersHandler}
            >
              X
            </span>
          </div>
        ) : (
          <>
            {/* <div className="add-account-btn">
              <Button style={{ color: blueColor }} type="link">
                <SearchOutlined onClick={searchBar} />
              </Button>
            </div> */}
            <div className="add-account-btn fnf-header">
              <Button
                shape="round" 
                className="transfer-history-btn"
                onClick={transferHistoryClick}
              >
                {transferHistoryButtonTitle}
              </Button>
              <Button
                className="add-friend-btn-friends-and-family"
                onClick={showDrawer}
              >
                <PlusOutlined /> {friendTitle}
              </Button>

              {/* <Button
                onClick={showDrawer}
                style={{ color: blueColor }}
                type="link"
              >
                <PlusOutlined /> {friendTitle}
              </Button> */}
            </div>
            {/* <div className="add-account-btn">
              <Button style={{ color: blueColor }} type="link">
                <CalendarOutlined /> {historyTitle}
              </Button>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default FriendFamilyHeader;