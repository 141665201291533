import React, { useState } from "react";
import { Checkbox, Col } from "antd";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";
import useLabels from "../../../../hooks/use-labels";
import customToast from "../../../shared/services/toaster-service";
import { showErrorNotification } from "../../../../utils/common-utils";
import { createBankAccounts, fetchProfileData } from "../../../../apis/b2c_api";
import { setUserProfile } from "../../../../app-store/user/user-reducer";
import { useDispatch } from "react-redux";
import Loader from "../../../shared/loader";

interface ConfirmTransferProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onPrevious: () => void;
}

const AddBankForm = ({
  onSubmit,
  transferValues,
  onPrevious,
}: ConfirmTransferProps) => {
  const [loading, setLoading] = useState(false);
  const {
    accountNumber,
    accountTitle,
    accountType,
    bankName,
    routingNumber,
    label,
    initiateVerification,
    isPreVerified,
    country,
    currencyCode,
  } = transferValues;

  const {
    legalAccountNameLabel,
    bankNameLabel,
    accountNumberLabel,
    accountTypeLabel,
    routingNumberLabel,
    nickNameLabel,
    verificationRequiredTitle,
    privacyPolicyCheckBoxText,
    BankAccountSuccessMessage,
  } = useLabels([
    "legalAccountNameLabel",
    "bankNameLabel",
    "accountNumberLabel",
    "accountTypeLabel",
    "routingNumberLabel",
    "nickNameLabel",
    "verificationRequiredTitle",
    "privacyPolicyCheckBoxText",
    "BankAccountSuccessMessage",
  ]);

  const dispatch = useDispatch();

  const onContinueClick = () => {
    const transferProps = {
      accountNumber,
      accountTitle,
      accountType,
      bankName,
      routingNumber,
      label,
      initiateVerification,
      isPreVerified,
      country,
      currencyCode,
    };
    setLoading(true);
    createBankAccounts(transferProps)
      .then((response) => {
        setLoading(false);

        const profile = {
          transType: "",
          variableCode: "P2PTransferBeneficiaryAllowedProducts",
        };

        customToast.success(BankAccountSuccessMessage);
        onSubmit(response);

        return fetchProfileData(profile);
      })
      .then(({ profile }: any) => {
        dispatch(setUserProfile(profile));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  if (loading) return <Loader />;

  return (
    <div className="add-bank-form-confirm">
      <div className="form-data">
        <p>{legalAccountNameLabel}</p>
        <span>{accountTitle}</span>
      </div>
      <div className="form-data">
        <p>{bankNameLabel}</p>
        <span>{bankName}</span>
      </div>
      <div className="form-data">
        <p>{accountNumberLabel}</p>
        <span>{accountNumber}</span>
      </div>
      <div className="form-data">
        <p>{accountTypeLabel}</p>
        <span>{accountType}</span>
      </div>
      <div className="form-data">
        <p>{routingNumberLabel}</p>
        <span>{routingNumber}</span>
      </div>
      <div className="form-data">
        <p>{nickNameLabel}</p>
        <span>{label}</span>
      </div>
      <Col offset={4} span={16}>
        <div className="mb-19">
          <Checkbox checked={initiateVerification}>
            {verificationRequiredTitle}
          </Checkbox>
        </div>
        <div className="mb-19">
          <Checkbox checked={isPreVerified}>
            {privacyPolicyCheckBoxText}
          </Checkbox>
        </div>
      </Col>
      <FlexButtons className="layout-buttons justify-end mb-20">
        <CustomButton
          type="outline"
          className="full-width"
          style={{ Color: "#006CF8" }}
          text="Cancel"
          onClick={onPrevious}
        />
        <CustomButton
          type="primary"
          className="full-width"
          style={{ backgroundColor: "#006CF8" }}
          onClick={onContinueClick}
          text="Continue"
        />
      </FlexButtons>
    </div>
  );
};

export default AddBankForm;
