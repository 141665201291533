import "./style.css";

const FlexButtons = (props: any) => {
  return (
    <div className={`d-flex w-100 flex-button ${props.className}`}>
      {props.children && props.children}
    </div>
  );
};

export default FlexButtons;
