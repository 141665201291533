import { getLabel } from "../../../../../hooks/use-labels";
import CustomButton from "../../../../custom-button";
import FlexButtons from "../../../../flex-button";

const imgPath = "/assets/images";

const TransferSuccess = (props: any) => {
  const { onClose, makeAnother } = props;

  return (
    <div className="transfers-fund-success-container">
      <div className="success-image-container">
        <img src={`${imgPath}/between-success-img.png`} alt="success" />
      </div>
      <div className="success-content">
        <div className="success-headliner">{getLabel("successfulRequest")}</div>
        <div className="success-description">
          {getLabel("sentMoneyDescription")}
        </div>
      </div>

      <FlexButtons className="full-width">
        <CustomButton
          type="primary"
          className="primary-black-btn Poppins-Medium"
          text={getLabel("makeAnotherTransferLabel")}
          onClick={() => makeAnother()}
          size="xl"
        />

        <CustomButton
          type="outline"
          className="primary-outline-btn Poppins-Medium"
          text={getLabel("noThanksLabel")}
          onClick={() => onClose(false)}
          size="xl"
        />
      </FlexButtons>
    </div>
  );
};
export default TransferSuccess;
