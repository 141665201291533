import React from "react";
import useLabels from "../../hooks/use-labels";
import useStyles from "../../hooks/use-styles";
// import { BsArrowLeft } from "react-icons/bs";

const PreviousButton = (props: any): JSX.Element => {
  const {
    secondaryButtonLabelColor,
    secondaryButtonBackground,
    secondaryButtonBorder,
  } = useStyles([
    "secondaryButtonLabelColor",
    "secondaryButtonBackground",
    "secondaryButtonBorder",
  ]);
  const { previousButtonLabel } = useLabels(["previousButtonLabel"]);
  const { text,style, className, ...buttonProps } = props;
  return (
    <button
      className={`custom-button back-button ${className!}`}
      style={{
        backgroundColor: secondaryButtonBackground,
        border: `1px solid ${secondaryButtonBorder}`,
        color: secondaryButtonLabelColor,
        ...style
      }}
      {...buttonProps}
    >
      {/* <BsArrowLeft /> */}
      <span>{text || previousButtonLabel}</span>
    </button>
  );
};

export default PreviousButton;
