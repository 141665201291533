import { createSlice } from "@reduxjs/toolkit";
import User from "../../interfaces/user";
import postMessageToParent from "../../utils/post-message-to-parent"
import { CookieService } from "../../service/cookie-service";

const getLocalState = (key: string) => {
  const localState = localStorage.getItem(key);
  if (localState) {
    return JSON.parse(localState);
  }

  return {};
};

const userState = {
  activeUser: getLocalState('activeUser'),
  userProfile: getLocalState('userProfile'),
};

const userSlice = createSlice({
  name: "users",
  initialState: userState,
  reducers: {
    setLoginUser: (state, action) => {
      localStorage.setItem("activeUser", JSON.stringify(action.payload));
      CookieService.setCookie(
        'cc_currentUser',
        JSON.stringify(action.payload),
        {
          path: '/',
          domain: undefined,
          secure: localStorage.getItem('secure_cookie') === 'yes',
          sameSite: 'Strict'
        }
      );
      state.activeUser = action.payload;
      postMessageToParent("api.auth.success", action.payload);
    },
    setUserProfile: (state, action) => {
      localStorage.setItem("userProfile", JSON.stringify(action.payload));
      state.userProfile = action.payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem("activeUser");
      localStorage.removeItem("userProfile");
      CookieService.removeCookie("cc_currentUser")
      state.activeUser = {} as User;
      state.userProfile = {};
    },
  },
});

export const { setLoginUser, logoutUser, setUserProfile } = userSlice.actions;
export default userSlice.reducer;
