import { Button, Form } from "antd";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/custom-button";
import FlexButtons from "../../../components/flex-button";
import axiosAPI from "../../../utils/axios";
import customToast from "../../../components/shared/services/toaster-service";
import React, { useEffect, useState } from "react";
import EnrollmentHeader from "../../../components/Enrollment/header/header";
import useStyles from "../../../hooks/use-styles";
import MaterialModal from "../../../components/Enrollment/Material-Modal";
import ConfirmationModal from "../../../components/Enrollment/Material-Modal/Confirmation-Modal/confirmation-modal";
import { getLabel } from "../../../hooks/use-labels";
import ReactCodeInput from "react-code-input";
import {errorMessage} from "../../../utils/validation";
import Loader from "../../../components/shared/loader";

interface ForgotPWFormProps {
  setEmailSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
}

const OTPForm = ({ setEmailSubmitted, email }: ForgotPWFormProps) => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);
  const [codeSent, setCodeSent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { signUpTitleColor, signUpSubTitleColor } = useStyles([
    "signUpTitleColor",
    "signUpSubTitleColor",
  ]);
  const [formOtp, setFormOtp] = useState("");
  const [valid, setValid] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const validate = (key: string, value: string) => {
    const errors = { ...fieldErrors };
    if (value) {
      delete errors[key];
    }
    if(value?.length === 6) {
      delete errors[key];
      setValid(true);
    }
    setFieldErrors(errors);
    setLoading(false);
  };

  const onFieldChange = (value: any, options?: any) => {
    validate("pinCode", value);
    setFormOtp(value);
  };

  useEffect(() => {
    if (seconds > 0 && codeSent) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (seconds === 0) {
      setSeconds(60);
      setCodeSent(false);
    }
  }, [seconds, codeSent]);

  const handleResend = async () => {
    setCodeSent(true);
    try {
      await axiosAPI.post(`/identity/email/${email}/otp`, {
        reason_type: "RP",
      });
      setLoading(false);
    } catch (error: any) {
      console.log("error", error);
      customToast.error(error?.message);
      setLoading(false);
    }
  };

  const openModal = () => {
    setConfirmOpen(true);
  };

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationTitle: "Are you sure",
      confirmationText:
        "Are you sure you want to cancel reset password flow.",
      closeLabel: "Cancel",
      confirmButtonText: "Yes, I am sure",
      onClose: () => setConfirmOpen(false),
      onConfirm: () => navigate("/login"),
    };

    return (
      <MaterialModal
        open={true}
        handleClose={() => setConfirmOpen(false)}
        width={"25rem"}
      >
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  const resetPassword = async () => {
    const requiredFields = ["pinCode"];
    const errors = {} as any;
    let valid = true;

    requiredFields.forEach((field) => {
      if (!formOtp) {
        valid = false;
        errors[field] = errorMessage.required;
      } else if (formOtp.length < 6) {
        valid = false;
        errors[field] = errorMessage.codeLength;
      }
    });
    setFieldErrors(errors);
    setValid(valid);
    if (!valid) {
      return;
    } else {
      setLoading(true);
      try {
        const resp = await axiosAPI.get(
          `/identity/email/${email}/otp/${formOtp}`
        );
        navigate("/reset-password", {
          state: {
            email: email,
            confirmationToken: resp.data.confirmation_token,
          },
        });
        setLoading(false);
      } catch (error: any) {
        console.log("error", error);
        customToast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="reset-password-form-container otp-container">
      <EnrollmentHeader
        title={getLabel("passwordRest")}
        titleColor={signUpTitleColor}
        subTitleColor={signUpSubTitleColor}
        className="reset-password-title"
      />
      <p className="forgot-otp-subtitle">An OTP has been sent to <span>{email}</span>.<br/> Please enter it to verify.</p>
      <Form name="resetPWEmail" layout="vertical" className="form-style">

        <div className="auth-form">
          <ReactCodeInput
            key={0}
            name="pinCode"
            inputMode="numeric"
            fields={6}
            value={formOtp}
            type="number"
            onChange={(value) => onFieldChange(value, true)}
            isValid={valid}
            className="pin-fields-container"
          />
          {Object.keys(fieldErrors).length > 0 && (
            <div style={{ color: "red" }}>
              {Object.values(fieldErrors).map((error, index) => (
                <span key={index}>{error as React.ReactNode}</span>
              ))}
            </div>
          )}
          <p className="label">
            Did not get the OTP?
            {!codeSent ? (
              <Button type="link" className="resend-code-button" onClick={handleResend}>
                Resend Code
              </Button>
            ) : (
              <>
                <span className="resend-code"> Resend code</span>
                <span> in {seconds} seconds.</span>
              </>
            )}
          </p>
        </div>
        {loading && <Loader className="custom-loader reset-password-otp-loader" />}
        <Form.Item>
          <FlexButtons className="mt-3 justify-start direction-row-reverse">
            <CustomButton
              text="Submit"
              type="primary"
              className="next-button action-button"
              disabled={!valid || loading}
              htmltype="submit"
              onClick={resetPassword}
            />
            <CustomButton
              text="Cancel"
              className="cancel-button action-button"
              type="outline"
              disabled={loading}
              onClick={() => openModal()}
            />
          </FlexButtons>
        </Form.Item>
      </Form>
      {renderConfirmModal()}
    </div>
  );
};

export default OTPForm;
