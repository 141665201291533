import { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import ReactCodeInput from "react-code-input";
import { sendOtp, verifyOtp } from "../../../../apis/b2c_api";
import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import { showErrorNotification } from "../../../../utils/common-utils";
import {
  getUserEmail,
  getUserPhoneNumber,
} from "../../../../utils/reUseFunction";
import CustomButton from "../../../custom-button";
import FlexButtons from "../../../flex-button";

interface OPTModalProps {
  setOptModal: any;
  setCongratulationsModal: any;
  userProfileData: any;
}

const OPTModal = ({
  setOptModal,
  setCongratulationsModal,
  userProfileData,
}: OPTModalProps) => {
  const [valueInput, setValueInput] = useState(false);
  const [valid, setValid] = useState(true);
  const [optValue, setOptValue] = useState("");

  const { blueColor } = useStyles(["blueColor"]);

  const { enterOTPTitle, enterOTPDescrip, resendOTP } = useLabels([
    "enterOTPTitle",
    "enterOTPDescrip",
    "resendOTP",
  ]);

  const handleValueInput = (e: any) => {
    if (String(e).replace(/[A-Za-z]/g, "").length === 4) {
      setOptValue(e);
      setValueInput(true);
      if (e !== "222222") {
        setValid(false);
      } else {
        setValid(true);
      }
    } else {
      setValueInput(false);
    }
  };

  const handleCancel = () => {
    setOptModal(false);
  };

  const verifyButton = () => {
    const verifyOtpBody = {
      email: getUserEmail(),
      phoneNo: getUserPhoneNumber(),
      otpValue: optValue,
    };

    verifyOtp(verifyOtpBody)
      .then((res) => {
        if (
          res.verifyOtp.otpStatus === "Invalid OTP." ||
          res.verifyOtp.otpStatus === "Issue in verifying OTP"
        ) {
          showErrorNotification(res.verifyOtp.otpStatus);
        } else {
          setOptModal(false);
          setCongratulationsModal(true);
        }
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const resendOpt = () => {
    const sendOtpBody = {
      email: getUserEmail(),
      phoneNo: getUserPhoneNumber(),
    };
    sendOtp(sendOtpBody)
      .then((res) => {})
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  return (
    <div className="opt-modal">
      <div className="opt-modal-heading">
        <h3>{enterOTPTitle}</h3>
        <p>
          {enterOTPDescrip}{" "}
          <span
            onClick={resendOpt}
            className="resend-opt pl-15"
            style={{ color: blueColor }}
          >
            {resendOTP}
          </span>
        </p>
      </div>
      <div className="opt-user-detail">
        <div className="opt-user-info">
          <Avatar size={60} icon={<UserOutlined />} />
          <h4>
            {userProfileData?.firstName} {userProfileData?.lastName}
          </h4>
          <p>{userProfileData?.email}</p>
          <p>
            {userProfileData?.phoneDetails[0]?.countryDialingCode}{" "}
            {userProfileData?.phoneDetails[0]?.phoneNumber}
          </p>
        </div>
        <div className="opt-form">
          <div className="auth-form mb-3">
            <ReactCodeInput
              name="resetPassword"
              inputMode="numeric"
              fields={4}
              type="text"
              onChange={(e) => handleValueInput(e)}
              isValid={valid}
              className="verification-code-input"
            />
          </div>
          <FlexButtons className="mb-3 gap-2">
            <CustomButton
              className="layout-button-small-width layout-button-small-height"
              type="outline"
              text="Cancel"
              onClick={handleCancel}
            />

            <CustomButton
              className="layout-button-small-width layout-button-small-height"
              type="primary"
              text="Verify"
              htmltype="submit"
              onClick={verifyButton}
            />
          </FlexButtons>
        </div>
      </div>
    </div>
  );
};

export default OPTModal;
