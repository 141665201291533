import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../views/Main/Footer";
import ContentBox from "../../content-box";
import FlexButtons from "../../flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import "./verification.css";
import useConfigs from "../../../hooks/use-config";

const imgPath = "/assets/images";

const SignupCongratulations = (props: any) => {
  
  let navigate = useNavigate();
  const location = useLocation();
  const { kycStatus  } = location.state as any;
 
  const { signupKycPendingReviewMessage , signupKycPassedSuccessMessage } = useConfigs(["signupKycPendingReviewMessage", "signupKycPassedSuccessMessage"]);
  const finalMessage = (!kycStatus || kycStatus === 'PASSED') ? signupKycPassedSuccessMessage : signupKycPendingReviewMessage;
  
  const finishConfig = () => {
    navigate("/login");
  };

  return (
    <ContentBox className="signup-success-container">
      <div className="cong-page p-4">
        <div className="auth-text">
          <div className="cong-image">
            <img src={`${imgPath}/signup-congratulations.png`} alt="success" />
          </div>
          <div className="mb-1">
            <span className="congratulations">You're All Set!</span>
          </div>
          <div className="mb-6">
            <span className="login-activate">
              {finalMessage}
            </span>
          </div>
          { ( !kycStatus || kycStatus === 'PASSED' ) &&
            <FlexButtons className="layout-buttons justify-center pt-2">
              <SignUpCustomButton
                type="primary"
                className="justify-center action-button next-button signup-success-button"
                onClick={finishConfig}
                text="Login"
              />
            </FlexButtons> }
        </div>
      </div>
      <Footer className="account-completion-footer"/>
    </ContentBox>
  );
};

export default SignupCongratulations;
