import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import FlexButtons from "../../components/flex-button";
import CustomButton from "../../components/custom-button";
import { ClockCircleTwoTone } from "@ant-design/icons";
import { refreshToken } from "../../app-store/user/user-action";
import { secondsToHms } from "../../utils/reUseFunction";
import { logoutUser, setLoginUser } from "../../app-store/user/user-reducer";
import MaterialModal from "../../components/Enrollment/Material-Modal";
import { CookieService } from "../../service/cookie-service";
import "./styles.css";

const SessionTimeoutModal = (props: any) => {
  const { isOpen, onClose, onLogoutClick } = props;
  const [time, setTime] = useState(
    Number(CookieService.getCookie("cc_session_timeout"))
  );
  const { activeUser } = useSelector((state: any) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "action") {
        if (e.newValue === "updateIdleTime") {
          handleStay();
        } else if (e.newValue === "updateLogoutAction") {
          handleLogout();
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let frameId: number;
    let intervalStartTime: number = Date.now();
    const updateCountdown = () => {
      const elapsedTime = Date.now() - intervalStartTime;
      if (elapsedTime >= 1000) {
        const secondsElapsed = Math.floor(elapsedTime / 1000);
        setTime((prev: number) => prev - secondsElapsed);
        intervalStartTime += secondsElapsed * 1000;
      }
      if (time > 0) {
        frameId = window.requestAnimationFrame(updateCountdown);
      } else {
        handleLogout();
      }
    };
    frameId = window.requestAnimationFrame(updateCountdown);
    return () => {
      window.cancelAnimationFrame(frameId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const handleLogout = () => {
    dispatch(logoutUser());
    CookieService.removeCookie("cc_sessionTimeoutAt");
    navigate("/");
    onLogoutClick();
  };

  const handleLogoutClict = (event: React.SyntheticEvent, reason: string) => {
    if (reason !== "backdropClick") {
      handleLogout();
    }
  };

  const handleStay = () => {
    refreshToken(activeUser.refresh_token)
      .then((response: any) => {
        dispatch(setLoginUser(response));
        CookieService.removeCookie("cc_sessionTimeoutAt");
      })
      .catch(console.error);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <MaterialModal
          boxClass="modal-width time-out-modal-container"
          open={isOpen}
          handleClose={handleLogoutClict}
          width={"30rem"}
        >
          <div className="time-out-modal">
            <h2 className="text-center">Session Expiry Alert!</h2>
            <div className="icon-content-area">
              <ClockCircleTwoTone />
              <span>
                Your session is about to expire due to inactivity. You will be
                logged out in
              </span>
            </div>
            <div className="counter">{secondsToHms(time)}</div>

            <FlexButtons className="mt-3 mb-3 gap-2 time-out-modal-btn-container">
              <CustomButton
                className="cancel-button action-button"
                type="outline"
                text="Log Out"
                onClick={handleLogout}
              />
              <CustomButton
                className="next-button action-button"
                type="primary"
                text="Stay"
                onClick={handleStay}
              />
            </FlexButtons>
          </div>
        </MaterialModal>
      )}
    </>
  );
};
export default SessionTimeoutModal;
