import { useEffect, useState } from "react";
import { timeDiff } from "../utils/common-utils";
import moment from "moment";
import { CookieService } from "../service/cookie-service";
import SessionTimeoutModal from "./TimeOutModal";


const IdleTimeoutComponent = () => {
  const [visible, setVisible] = useState(false);

  const checkForInactivity = () => {
    const currentUser = CookieService.getCookie("cc_currentUser");
    const idleTimeoutAt = CookieService.getCookie("cc_idleTimeoutAt");
    const currentTime = moment().toDate();
    const IdleTimeDiff = timeDiff(idleTimeoutAt, currentTime);
    const currentRoute = window.location.pathname;
    if (currentUser && currentRoute !== '/enrollment' && currentRoute !== '/login' && currentRoute !== '/forgot-password' && currentRoute !== '/reset-password' && currentRoute !== '/completion') {
      if (IdleTimeDiff <= 1) {
        setVisible(true);
        localStorage.setItem("action", "checkForInactivity");
        updateSessionTime();
      }
    }
  };

  const updateIdleTime = () => {
    const idleTimeoutAt = moment()
      .add(CookieService.idleTimeout(), "seconds")
      .toDate();
    CookieService.setCookie("cc_idleTimeoutAt", idleTimeoutAt.toString());

    if (visible) {
      setVisible(false);
      localStorage.setItem("action", "updateIdleTime"); 
    }
  };

  const updateSessionTime = () => {
    const sessionTimeoutAt = moment().add(CookieService.sessionTimeout(), "seconds").toDate();
    CookieService.setCookie("cc_sessionTimeoutAt", sessionTimeoutAt.toString());
  }

  const updateLogoutAction = () => {
    if (visible) {
        setVisible(false);
        localStorage.setItem("action", "updateLogoutAction"); 
      }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 1000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateIdleTime();
    window.addEventListener("click", updateIdleTime);
    window.addEventListener("keypress", updateIdleTime);
    window.addEventListener("scroll", updateIdleTime);
    window.addEventListener("mousemove", updateIdleTime);
    return () => {
      window.removeEventListener("click", updateIdleTime);
      window.removeEventListener("keypress", updateIdleTime);
      window.removeEventListener("scroll", updateIdleTime);
      window.removeEventListener("mousemove", updateIdleTime);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible && (
        <SessionTimeoutModal
          isOpen={visible}
          onClose={() => updateIdleTime()}
          onLogoutClick={() => updateLogoutAction()}
        />
      )}
    </>
  );
};
export default IdleTimeoutComponent;
