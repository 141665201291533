import { useState, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { login } from "../../app-store/user/user-action";
import { setLoginUser } from "../../app-store/user/user-reducer";
import { showErrorNotification } from "../../utils/common-utils";
import useLabels, { getLabel } from "../../hooks/use-labels";
import FlexButtons from "../../components/flex-button";
import Footer from "../Main/Footer";
import "./styles.css";
const { v4: uuidv4 } = require('uuid');

const assetPath = "/assets/images";

const Login = (): JSX.Element => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { loginButtonTitle, signUpButtonTitle } = useLabels([
    "getStartedButtonTitle",
    "loginButtonTitle",
    "signUpButtonTitle",
  ]);

  const onFinish = (values: any) => {
    setLoading(true);
    const deviceUniqueId = uuidv4();
    login({
      ...values,
      device_info: {
        device_unique_id: deviceUniqueId,
      },
    })
      .then((response: any) => {
        dispatch(setLoginUser(response));
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        if (error?.error_code === "AU006" || error?.error_code === "AU005" || error?.error_code === "AU009") {
          navigate("/consumer-verification", {
            state: {
              email: values.email,
              password: values.password,
              otpToken: error.additional_data?.mfa_token,
              device_info: {
                device_unique_id: deviceUniqueId,
              },
            },
          });
        } else {
          setLoading(false);
          showErrorNotification(error);
        }
      });
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signUpPage = () => {
    navigate("/enrollment");
  };

  const forgotPasswordPage = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-page-container">
        <Row gutter={[0, 0]} className="login-page-row">
          <Col lg={18} md={18} sm={24} className="login-banner-section">
            <div className="login-content-container">
              <Row gutter={[0, 0]} className="login-page-inner-row">
                <Col lg={9} md={12} sm={12}>
                  <div className="login-mobile-img-container">
                    <img
                      src={`${assetPath}/login-mobile-img.png`}
                      alt="login-mobile-img"
                    />
                  </div>
                </Col>
                <Col lg={15} md={12} sm={12}>
                  <div className="login-logo-content">
                    <div className="login-main-logo">
                      <img
                        src={`${assetPath}/login-page-main-logo.png`}
                        alt="Main Logo"
                      />
                    </div>
                    <div className="login-meta-content">
                      <div className="login-page-text">
                        {getLabel("bankingPurposeLabel")}
                      </div>
                      <div className="login-app-btn-container">
                        <FlexButtons className={"justify-space-between"}>
                          <FlexButtons className={"apps-icon-group"}>
                            <img
                              className="pointer"
                              src={"/assets/app-store-button.png"}
                              alt="App Store Badge"
                              height={"40px"}
                            />
                            <img
                              className="pointer"
                              src={"/assets/play-store-button.png"}
                              alt="App Store Badge"
                              height={"40px"}
                            />
                          </FlexButtons>
                        </FlexButtons>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={24} sm={24} className="login-form-section">
            <div className="login-form-content">
              <div className="login-form-heading">{getLabel("loginLabel")}</div>
              <div className="login-form-main-logo">
                <img
                  src={`${assetPath}/login-page-main-logo.png`}
                  alt="Main Logo"
                />
              </div>
              <Form
                name="login"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                className="login-form-container"
              >
                <div className="auth-form-items-container">
                  <Form.Item
                    name="email"
                    className="login-email-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>
                  <div>
                    <Form.Item
                      name="password"
                      className="login-password-input"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        value={password}
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        onChange={handlePasswordChange}
                        visibilityToggle={{
                          visible: showPassword,
                          onVisibleChange: togglePasswordVisibility,
                        }}
                        iconRender={(visible) =>
                          visible ? (
                            <img
                              src={`${assetPath}/visibility-Copy.png`}
                              alt="Eye icon"
                            />
                          ) : (
                            <img
                              src={`${assetPath}/Shape.png`}
                              alt="Eye icon"
                            />
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="login-page-buttons-container">
                    <Button
                      className="login-page-other-link"
                      type="link"
                      onClick={forgotPasswordPage}
                    >
                      Forgot ?
                    </Button>
                    <Form.Item>
                      <div className="button-style login-submit-btn">
                        <Button
                          className="login-button"
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                        >
                          {loginButtonTitle}
                        </Button>
                      </div>
                    </Form.Item>
                    <Button
                      className="login-page-other-link"
                      onClick={signUpPage}
                      type="link"
                    >
                      {signUpButtonTitle}
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="login-form-app-btn-container">
                <FlexButtons className={"justify-space-between"}>
                  <FlexButtons className={"apps-icon-group"}>
                    <img
                      className="pointer"
                      src={"/assets/apple-store-button.svg"}
                      alt="App Store Badge"
                      height={"40px"}
                    />
                    <img
                      className="pointer"
                      src={"/assets/google-play-button.svg"}
                      alt="App Store Badge"
                      height={"40px"}
                    />
                  </FlexButtons>
                </FlexButtons>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer className="login-footer-container" />
    </div>
  );
};

export default Login;