import { useNavigate } from "react-router";
import { getPageUrl } from "../../../../utils/reUseFunction";
import FlexButtons from "../../../flex-button";
import CustomButton from "../../../custom-button";
import { congratulationsImage } from "../../../../utils/SvgImage/Icons";
import useLabels from "../../../../hooks/use-labels";

interface CongratulationsProps {
  setCongratulationsModal: any;
  setOpenDrawer: any;
}

const Congratulations = ({
  setCongratulationsModal,
  setOpenDrawer,
}: CongratulationsProps) => {
  const { congratulationsTitle, congratulationsDescription } = useLabels([
    "congratulationsTitle",
    "congratulationsDescription",
  ]);

  let navigate = useNavigate();
  const currentUrl = getPageUrl();

  const finishConfig = () => {
    setOpenDrawer(false);
    setCongratulationsModal(false);
    navigate(`/${currentUrl}`);
  };

  return (
    <div className="congration-modal">
      <div className="cong-page">
        <div className="auth-text">
          <div className="cong-icon">{congratulationsImage}</div>
          <h2>{congratulationsTitle}</h2>
          <p>{congratulationsDescription}</p>

          <FlexButtons className="mb-3 gap-2 cong-modal-button">
            <CustomButton
              className="layout-button-small-width layout-button-small-height"
              type="primary"
              text="Done"
              htmltype="submit"
              onClick={finishConfig}
            />
          </FlexButtons>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
