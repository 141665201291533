import { Col, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import useLabels from "../../../../../hooks/use-labels";
import {
  fetchProfileData,
  initiateTransferReceive,
} from "../../../../../apis/b2c_api";
import {
  formatCurrency,
  showErrorNotification,
} from "../../../../../utils/common-utils";
import ContentBox from "../../../../content-box";
import CustomButton from "../../../../custom-button";
import FlexButtons from "../../../../flex-button";
import Loader from "../../../../shared/loader";
import customToast from "../../../../shared/services/toaster-service";
import { getSendTransferProps } from "../../../../../utils/reUseFunction";
import { useDispatch } from "react-redux";
import { setUserProfile } from "../../../../../app-store/user/user-reducer";

interface ConfirmTransferProps {
  onSubmit: (values: any) => void;
  transferValues: any;
  onPrevious: () => void;
  sendTransferProps: any;
  selectedAccount: any;
}

const ConfirmTransfer = (props: ConfirmTransferProps) => {
  const [loading, setLoading] = useState(false);
  const { amount, memo } = props.transferValues;
  const localStorageSendMoneyData = getSendTransferProps();
  const { currencyCode, id } = props.sendTransferProps
    ? props.sendTransferProps
    : localStorageSendMoneyData.state.sendTransferProps;
  const { value, __typename } = props.selectedAccount;

  const {
    transferConfirmationTitle,
    transferDescription,
    transferAmountTitle,
    transferFromTitle,
    transferToTitle,
    transferTransferDateTitle,
    fundsTransferSuccessMessage,
  } = useLabels([
    "transferConfirmationTitle",
    "transferDescription",
    "transferAmountTitle",
    "transferFromTitle",
    "transferToTitle",
    "transferMainAccountTitle",
    "transferSubAccountTitle",
    "transferTransferDateTitle",
    "fundsTransferSuccessMessage",
  ]);

  const titleColor = "#2C3E50";
  const labelColor = "#747F8B";

  const optionColor = "#000000";

  const dispatch = useDispatch();

  const transferFunds = () => {
    setLoading(true);
    const receiveMoneyBody = {
      entity: {
        type: __typename,
        id: value,
      },
      bankAccountId: id,
      transferAmount: {
        amount: parseFloat(amount),
        currency: currencyCode,
      },
      transferDate: moment().format("YYYY-MM-DD"),
      sameDayTransfer: true,
      companyId: "",
      individualId: "",
      description: memo,
      externalRefNo: "",
    };

    initiateTransferReceive(receiveMoneyBody)
      .then((response) => {
        setLoading(false);

        const profile = {
          transType: "",
          variableCode: "P2PTransferBeneficiaryAllowedProducts",
        };

        customToast.success(fundsTransferSuccessMessage);
        props.onSubmit(response);

        return fetchProfileData(profile);
      })
      .then(({ profile }: any) => {
        dispatch(setUserProfile(profile));
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  return (
    <ContentBox className="confirm-transfer-container">
      <div>
        <p
          className="text-left text-2lg weight-600"
          style={{ color: titleColor }}
        >
          {transferConfirmationTitle}
        </p>
        <p
          className="text-left text-sm weight-400"
          style={{ color: labelColor }}
        >
          {transferDescription}
        </p>
      </div>

      <div className="d-flex justify-start mt-2 align-center">
        <p
          className="text-left weight-500 min-width-8 mb-0"
          style={{
            fontSize: "22px",
            color: labelColor,
          }}
        >
          {transferAmountTitle}
        </p>

        <p
          className="text-left text-2lg weight-500 mb-0"
          style={{ color: optionColor, fontSize: "40px" }}
        >
          {formatCurrency(amount, "USD")}
        </p>
      </div>
      <div className="d-flex justify-start mt-2 full-width">
        <p
          className="text-left weight-500 pr-3 mt-1 min-width-8 mb-0"
          style={{
            fontSize: "22px",
            color: labelColor,
          }}
        >
          {transferFromTitle}
        </p>
        <Row className="full-width">
          <Col md={6}>
            <div className="text-left">
              <p className="weight-600 mt-1" style={{ color: optionColor }}>
                Checking Account **** 7980
              </p>
            </div>
          </Col>
          {/* ))} */}
        </Row>
      </div>
      <div className="d-flex justify-content mt-2">
        <p
          className="text-left  weight-500 pr-3 mt-1 min-width-8"
          style={{
            fontSize: "22px",
            color: labelColor,
          }}
        >
          {transferToTitle}
        </p>

        <Row className="full-width">
          <Col md={6}>
            <div className="text-left">
              <p className="weight-600 mt-1" style={{ color: optionColor }}>
                Chase Bank **** 8905
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <p
          className="text-left text-md mt-1 weight-400 pr-5"
          style={{
            color: "#9CA3AC",
          }}
        >
          Memo (Optional)
        </p>

        <p
          className="full-width break-word"
          style={{ borderBottom: `1.5px solid #9CA3AC`, color: labelColor }}
        >
          {memo}
        </p>

        <div className="d-flex mt-2 justify-between">
          <div style={{ width: "4rem" }}>
            <img
              src={require("./../calendar.png")}
              width={"40px"}
              height={"40px"}
              className="mt-1"
              alt="calendar"
            />
          </div>

          <div>
            <p style={{ color: labelColor }}>{transferTransferDateTitle}</p>
            <p> {moment().format("MM/DD/YYYY")} </p>
          </div>
        </div>
      </div>

      <FlexButtons className="layout-buttons justify-end mt-1 mb-4">
        {!loading ? (
          <>
            <CustomButton
              type="outline"
              className="mt-5 full-width"
              style={{ Color: "#006CF8" }}
              text="Back"
              onClick={props.onPrevious}
            />
            <CustomButton
              type="primary"
              className="mt-5 full-width"
              style={{ backgroundColor: "#006CF8" }}
              onClick={transferFunds}
              text="Send"
            />
          </>
        ) : (
          <Loader className="custom-loader" />
        )}
      </FlexButtons>
    </ContentBox>
  );
};
export default ConfirmTransfer;
