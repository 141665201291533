import { useState } from "react";
import { Col, Form, Input, Row } from "antd";
import useStyles from "../../../hooks/use-styles";
import EnrollmentHeader from "../header/header";
import "./verification.css";
import SignUpFlexButtons from "../SignUp-Button/signup-flex-button";
import SignUpCustomButton from "../SignUp-Button/signup-custom-button";
import { errorMessage, fieldValidator } from "../../../utils/validation";

const PersonalInformation = (props: any) => {
  const { signUpTitleColor, signUpSubTitleColor } = useStyles([
    "signUpTitleColor",
    "signUpSubTitleColor",
  ]);
  const [loading, setLoading] = useState(false) as any;

  const { formData, onFormSubmit, handleDelete } = props;
  const { firstName, lastName, email, password, confirmPassword } = formData;

  const [fieldValues, setFieldValues] = useState({
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    password: password || "",
    confirmPassword: confirmPassword || "",
  }) as any;

  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const validate = (
    key: string,
    value: string,
    options?: any,
    addError?: boolean
  ) => {
    const errors = { ...fieldErrors };
    if (!value.trim() && options) {
      errors[key] = errorMessage.required;
    } else if (key === "password" && value.length < 8) {
      errors[key] = "Password must be at least 8 characters long.";
    } else if (key === "password" && !fieldValidator.strongPassword(value)) {
      errors[key] =
        "Password must contain at least one uppercase letter, one digit, and one special character.";
    } else {
      delete errors[key];
    }
    if (key === "password" && value === fieldValues.confirmPassword) {
      delete errors["confirmPassword"];
    }
    if (!addError) {
      delete errors[key];
    }
    setFieldErrors(errors);
    setLoading(Object.keys(errors).length > 0);
  };

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options, false);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleNextFn = async () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "password",
      "confirmPassword",
    ];
    const errors = {} as any;
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        valid = false;
        errors[field] = errorMessage.required;
      }
    });

    if (fieldValues.password !== fieldValues.confirmPassword) {
      valid = false;
      errors["confirmPassword"] =
        "The two passwords that you entered do not match";
    }
    if (fieldValues.password && fieldValues.password.length < 8) {
      valid = false;
      errors["password"] = "Password must be at least 8 characters long.";
    } else if (
      fieldValues.password &&
      !fieldValidator.strongPassword(fieldValues.password)
    ) {
      valid = false;
      errors["password"] =
        "Password must contain at least one uppercase letter, one digit, and one special character.";
    }

    setFieldErrors(errors);
    setLoading(!valid);

    if (valid) {
      onFormSubmit({ ...fieldValues });
    }
  };

  return (
    <div className="personal-info-container">
      <EnrollmentHeader
        title={"Personal Information"}
        subTitle={`Enter your first and last name`}
        titleColor={signUpTitleColor}
      />

      <div className="login-form mt-2">
        <Form
          name="login"
          layout="vertical"
          initialValues={fieldValues}
          className="form-style"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="firstName"
                className="custom-form-item mb-1"
                validateStatus={fieldErrors.firstName ? "error" : ""}
                help={fieldErrors.firstName}
              >
                <Input
                  bordered={false}
                  onChange={(event) =>
                    onFieldChange("firstName", event.target.value, true)
                  }
                  value={fieldValues.firstName}
                  className="error-input"
                  placeholder="First Name"
                  maxLength={60}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="lastName"
                style={{ fontSize: "12px" }}
                validateStatus={fieldErrors.lastName ? "error" : ""}
                help={fieldErrors.lastName}
                className="custom-form-item mb-1"
              >
                <Input
                  bordered={false}
                  onChange={(event) =>
                    onFieldChange("lastName", event.target.value, true)
                  }
                  className=" "
                  value={fieldValues.lastName}
                  placeholder="Last Name"
                  maxLength={60}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <EnrollmentHeader
        title={"Create Your Account"}
        subTitle={`Enter your password`}
        className="email-password-header"
      />
      <div className="login-form">
        <Form
          name="login"
          layout="vertical"
          initialValues={fieldValues}
          className="form-style"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                className="custom-form-item mb-1"
              >
                <Input
                  bordered={false}
                  value={fieldValues.email}
                  autoComplete="off"
                  placeholder="Email"
                  maxLength={100}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                className="custom-form-item mb-1"
                validateStatus={fieldErrors.password ? "error" : ""}
                help={fieldErrors.password}
              >
                <Input.Password
                  type="password"
                  onChange={(event) =>
                    onFieldChange("password", event.target.value, true)
                  }
                  autoComplete="off"
                  value={fieldValues.password}
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="confirmPassword"
                dependencies={["password"]}
                validateStatus={fieldErrors.confirmPassword ? "error" : ""}
                help={fieldErrors.confirmPassword}
                className="custom-form-item mb-1"
              >
                <Input.Password
                  type="password"
                  onChange={(event) =>
                    onFieldChange("confirmPassword", event.target.value, true)
                  }
                  value={fieldValues.confirmPassword}
                  placeholder="Confirm Password"
                />
              </Form.Item>
            </Col>
          </Row>
          <SignUpFlexButtons className="layout-buttons justify-start action-buttons-container direction-row-reverse">
            <SignUpCustomButton
              type="primary"
              className="action-button next-button"
              onClick={handleNextFn}
              disabled={loading}
              htmltype="submit"
              text="Next"
            />

            <SignUpCustomButton
              type="outline"
              className="action-button cancel-button"
              onClick={props.openModal}
              text="Cancel"
            />
          </SignUpFlexButtons>
        </Form>
      </div>
    </div>
  );
};

export default PersonalInformation;
