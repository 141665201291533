import {iMetadata} from "../interfaces/types"
import defaults from "../utils/defaults";

let metadata: any;
export const setMetadata = (_metadata: any) => {
    metadata = _metadata;
    localStorage.setItem('metadata', JSON.stringify(metadata));
};

const getMetadata = () => {
    if (!metadata) {
        metadata = JSON.parse(localStorage.getItem('metadata') || "{}");
    }
    return metadata;
}

export const styles = (): iMetadata[] => {
    return getMetadata()?.style || [];
}

export const labels = (): iMetadata[] => {
    return getMetadata()?.label || [];
}

export const configs = (): iMetadata[] => {
    return getMetadata()?.configs || [];
}

export const errors = (): iMetadata[] => {
    return getMetadata()?.errors || [];
}

export const menus = (): iMetadata[] => {
    return getMetadata()?.menus || [];
}

export const logos = (): iMetadata[] => {
    return getMetadata()?.logo
}

export const findErrorMessage = (code: string, languageCode: string): string => {
    const errorMessage = errors().find((error: any) => error.config_code === code && error.language_code === languageCode);
    return errorMessage?.config_value || defaults[code] || defaults['generalErrorMessage']
}
