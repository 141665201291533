import { FC } from "react";

interface LabelValueProps {
  label?: string;
  value?: string;
  labelClass?: string;
  valueClass?: string;
  className?: string;
  onClick?: () => void;
}

const LabelValueContainer: FC<LabelValueProps> = ({
  label,
  value,
  className,
  labelClass,
  valueClass,
  onClick = undefined,
}) => {
  return (
    <div
      className={`label-value-container ${
        className ? className + "-container" : ""
      }`}
      onClick={onClick}
    >
      <div className={`data-label ${labelClass}`}>{label}</div>
      <div className={`data-value ${valueClass}`}>{value}</div>
    </div>
  );
};

export default LabelValueContainer;
