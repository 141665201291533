import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransferFundsTo } from "../../../../utils/reUseFunction";
import ConfirmTransfer from "./ConfirmTransfer";
import TransferAmount from "./TransferAmount";
import TransferSuccess from "./TransferSuccess";
import { useSelector } from "react-redux";

const WalletTransfer = () => {
  const toAccounts = getTransferFundsTo();
  const { state } = useLocation();
  const { record }: any = state || {};
  const { entity, nickName } = record ? record : toAccounts;
  const { id, type } = entity;

  const [step, setCurrentStep] = useState(1);
  const [transferInformation, setTransferInformation] = useState({});
  const navigate = useNavigate();
  const { userProfile } = useSelector((state: any) => state.users);

  const handleSubmit = (transferValues: any) => {
    setTransferInformation((prev) => ({ ...prev, ...transferValues }));
    handleNext();
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleClose = (againProceed: boolean = true) => {
    againProceed ? navigate(-1) : navigate("/beneficiaries");
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const makeAnother = () => {
    setTransferInformation({});
    setCurrentStep(1);
  };

  const walletSteps: any = {
    1: () => (
      <TransferAmount
        onSubmit={handleSubmit}
        transferValues={transferInformation}
        onClose={handleClose}
        id={id}
        nickName={nickName}
        fromWalletCurrency={
          userProfile.accounts[0]?.accountWallets[0]?.currency
        }
        type={type}
      />
    ),
    2: () => (
      <ConfirmTransfer
        type={type}
        transferValues={transferInformation}
        fromWalletCurrency={
          userProfile.accounts[0]?.accountWallets[0]?.currency
        }
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
      />
    ),
    3: () => (
      <TransferSuccess
        {...transferInformation}
        onClose={handleClose}
        makeAnother={makeAnother}
      />
    ),
  };

  return walletSteps[step]();
};
export default WalletTransfer;
