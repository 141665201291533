import axios from 'axios'
import { AUTH_APP_ID, AUTH_KEY, AUTH_URL } from "../credentials";
import { APIErrorResponse } from "../interfaces/api";

const getOptions = (providedHeaders?: object) => {
  const options = {
    headers: {
      "x-api-key": AUTH_KEY(),
      "x-app-id": AUTH_APP_ID(),
      // Origin: "http://localhost:4206/",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      ...providedHeaders,
    },
  };

  return options;
};

const prepareURL = (url: string) => {
  return `${AUTH_URL()}${url}`;
};

const extractAxiosError = (errorResponse: any) => {
  const { response } = errorResponse;
  const errorObject = {} as APIErrorResponse;
  if (response && response.data) {
    const { data } = response;
    errorObject.error_code = data.error_code;
    errorObject.message = data.message;
    errorObject.additional_data = data.additional_data;

    return errorObject;
  }

  return null;
};
const handleAxiosError = (error: any) => {
  const axiosError = extractAxiosError(error);
  if (axiosError) {
    return Promise.reject(axiosError);
  }
  console.log('error', error);
  return Promise.reject({
    error_code: "GENERAL",
    message: "Something went wrong!",
  });
};

const axiosAPI = {
  post: (url: string, data: object, headers?: object) =>
    axios
      .post(`${prepareURL(url)}`, data, getOptions(headers))
      .catch(handleAxiosError),
  get: (url: string, headers?: object) =>
    axios
      .get(`${prepareURL(url)}`, getOptions(headers))
      .catch(handleAxiosError),
  put: (url: string, data: object, headers?:object) =>
    axios.put(`${prepareURL(url)}`, data, getOptions(headers)).catch(handleAxiosError),
  delete: (url: string) => axios.delete(`${prepareURL(url)}`, getOptions()).catch(handleAxiosError),
};

export default axiosAPI;
