import React, { useEffect, useState } from "react";
import Loader from "./components/shared/loader";
import { initialize } from "./apis/init_api";
import App from "./App";
import { getSecureUrl } from "./utils/reUseFunction";
import { getAuthTokenAPI } from "./apis/auth_api";
import { useNavigate } from "react-router-dom";

const Init = (): JSX.Element => {
  const [initialized, setInitialized] = useState(false);
  const surl: any = getSecureUrl();
  let navigate = useNavigate();

  useEffect(() => {
    if (surl.includes("secure-urls")) {
      const getAuthTokenObj: any = getAuthTokenAPI(surl);

      if (getAuthTokenObj) {
        navigate("/");
      } else {
        navigate("/login");
      }
    }
  }, [surl,navigate]);

  useEffect(() => {
    initialize()
      .then((res) => {
        setInitialized(true);
      })
      .catch(console.error);
  }, []);

  if (!initialized) {
    return <Loader />;
  }

  return <App />;
};

export default Init;
