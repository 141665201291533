import {
  formatCurrency,
  padLastFour,
} from "../../../../../../utils/common-utils";

export const prepareAccountOptions = (accounts: any) => {
  return accounts.map(({ title, id, accountProxyNo }: any) => {
    let trimmedTitle = title;
    if (trimmedTitle.length > 16) {
      trimmedTitle = trimmedTitle.substring(0, 16);
    }
    const lastFourDigits = accountProxyNo
      .toString()
      .substring(accountProxyNo.toString().length - 4);
    return {
      label: `${trimmedTitle} ${padLastFour(lastFourDigits)}`,
      value: id,
    };
  });
};

export const prepareWalletOptions = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);
  return account;
};

export const getAccountTitle = (accountId: string, accounts: any) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const lastFourDigits = account.accountProxyNo
    .toString()
    .substring(account.accountProxyNo.toString().length - 4);
  return `${account.title} ${padLastFour(lastFourDigits)}`;
};

export const getWalletTitle = (
  accountId: string,
  walletId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const wallet = account.accountWallets.find(
    (walletItem: any) => walletItem.id === walletId
  );
  return `${wallet.title} ${formatCurrency(
    wallet.availableBalance,
    wallet.currency
  )}`;
};

export const getWalletCurrency = (
  accountId: string,
  walletId: string,
  accounts: any
) => {
  const account = accounts.find((item: any) => item.id === accountId);
  const wallet = account.accountWallets.find(
    (walletItem: any) => walletItem.id === walletId
  );
  return wallet.currency;
};
