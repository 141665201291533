export interface WalletStats {
  month: string;
  Balance?: number;
 }
export const prepareGrpahData = (walletStats: any) => {
  const walletStatsData: WalletStats[] = 
  [ 
    { month:"Jan", Balance: 0 }, 
    { month:"Feb", Balance: 0 },
    { month:"Mar", Balance: 0 }, 
    { month:"Apr", Balance: 0 }, 
    { month:"May", Balance: 0 }, 
    { month:"Jun", Balance: 0 }, 
    { month:"Jul", Balance: 0 }, 
    { month:"Aug", Balance: 0 }, 
    { month:"Sep", Balance: 0 }, 
    { month:"Oct", Balance: 0 }, 
    { month:"Nov", Balance: 0 }, 
    { month:"Dec", Balance: 0 }
  ]
  
  walletStats.forEach((stat: any, index: number) => {
    walletStatsData[getMonthNo(stat.activityDate)].Balance = stat.availableBalance 
  });
  
  let nextMonth = (new Date()).getMonth()+1;
  for (nextMonth; nextMonth<12 ; nextMonth++) {
    delete  walletStatsData[nextMonth].Balance; 
  }

  return walletStatsData;
};

function getMonthNo(date: string) {
  const formattedDate = new Date(date) 
  return formattedDate.getMonth();
}
