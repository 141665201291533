import ContentBox from "../../../../components/content-box";
import CustomButton from "../../../../components/custom-button";
import FlexButtons from "../../../../components/flex-button";
import { getLabel } from "../../../../hooks/use-labels";
import { formatCurrency } from "../../../../utils/common-utils";
import { getWalletCurrency } from "../TransferAmount/TransferForm/transfer-form-utils";
import "./styles.css";

const TransferSuccess = (props: any) => {
  const {
    amount,
    accounts,
    fromMainAccount,
    fromSubAccount,
    onClose,
    makeAnother,
  } = props;

  const walletCurrency = getWalletCurrency(
    fromMainAccount,
    fromSubAccount,
    accounts
  );

  return (
    <ContentBox className="between-accounts-success-container">
      <div className="between-accounts-success-content">
        <div className="between-accounts-success-img">
          <img src="/assets/images/between-success-img.png" alt="success" />
        </div>
        <div className="between-accounts-meta">
          <div className="between-acounts-meta-title"> Thank you</div>
          <div className="between-accounts-meta-subtitle">
            {formatCurrency(amount, walletCurrency)} has been transferred
            successfully
          </div>
        </div>
        <div className="between-accounts-btn-container">
          <FlexButtons>
            <CustomButton
              type="primary"
              className="button-xl-2 primary-black-btn"
              text="Make Another Transfer"
              onClick={() => makeAnother()}
            />
            <CustomButton
              type="outline"
              className="button-xl-2 primary-outline-btn"
              text={getLabel("noThanksLabel")}
              onClick={onClose}
            />
          </FlexButtons>
        </div>
      </div>
    </ContentBox>
  );
};
export default TransferSuccess;
