import { Col, Row } from "antd";
import { footerMetaData } from "./footer-links";
import "./styles.css";

const Footer = ({ className }: { className?: string }): JSX.Element => {
  const handlePDFPath = (path: string) => {
    window.open(path);
  };

  const footerData = footerMetaData().map((meta: any) => {
    const { key, label, linksArray, className, colSize } = meta;
    return (
      <Col md={colSize} key={key}>
        {label ? (
          <div className={className}>{label}</div>
        ) : (
          <ul className={className}>
            {linksArray.map((link: any) => {
              const { key, label, path, linkClass, divider, footerSeparator } =
                link;
              return (
                <li key={key} className={linkClass}>
                  <span onClick={() => handlePDFPath(path)}>{label}</span>
                  {divider && (
                    <span className={footerSeparator}>{divider}</span>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </Col>
    );
  });

  return (
    <footer className={`footer-section ${className}`}>
      <Row className="footer-content">{footerData}</Row>
    </footer>
  );
};

export default Footer;
