import React from "react";
import useStyles from "../../../hooks/use-styles";

const WithDrawerDepositModal = () => {
  const { blueColor } = useStyles(["blueColor"]);

  const openLinkNewTab = (path:string) => {
    window.open(path,"_blank")
  }

  return (
    <div className="deposit-modal">
      <h2 style={{ color: blueColor }}>Deposit or withdraw funds</h2>
      <p>
        We're currently working on enabling deposits and withdraws from the Fair
        web banking portal. In the meantime you can perform deposits and
        withdraws from the Fair mobile app which you can find{" "}
        <span
          onClick={()=>openLinkNewTab("https://apps.apple.com/us/app/bank-with-fair/id1558647195")}          
        >
          here
        </span>{" "}
        for IOS or{" "}
        <span onClick={()=>openLinkNewTab("https://play.google.com/store/apps/details?id=com.i2c.mcpcc.fairFintech&hl=en&gl=US&pli=1")}>
          here
        </span>{" "}
        for Android. Sorry for the inconvience.
      </p>
      <p>Sorry for the inconvience.</p>
    </div>
  );
};

export default WithDrawerDepositModal;
