import moment, { Moment, unitOfTime } from "moment";
import customToast from "../components/shared/services/toaster-service";
import { findErrorMessage } from "./metadata";
import dayjs from "dayjs";
const PNF = require("google-libphonenumber").PhoneNumberFormat;

export const RECIPIENT_TYPE = "P2P";

export const phoneInstance = () => {
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();
  return phoneUtil;
};

export const getCountryCodeFromDialCode = (dialCode: any) => {
  const list = [
    { code: +44, countryCode: "gb" },
    { code: +971, countryCode: "ae" },
    { code: +1, countryCode: "ca" },
    { code: +91, countryCode: "in" },
    { code: +92, countryCode: "pk" },
  ];
  return list.find((item: any) => item.code === dialCode)?.countryCode;
};

export const formatPhoneNumber = (
  phoneNumber: string,
  countryDialingCode: string,
  countryCode?: string | undefined
) => {
  const phoneUtil = phoneInstance();
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
  return `+${countryDialingCode} ${phoneUtil.format(number, PNF.NATIONAL)}`;
};

export const getPlanePhoneNumber = (
  countryDialingCode: string,
  phoneNumber: string
) => {
  return `+${countryDialingCode}${phoneNumber}`;
};

export const showErrorNotification = (error: any) => {
  if (typeof error === "string") {
    customToast.error(error);
  } else {
    let message = "";
    let messageArray = [];
    if (error.field_errors?.length > 0) {
      for (const fieldError of error.field_errors) {
        if (fieldError.field && fieldError.details) {
          messageArray.push(capitalize(fieldError.details));
        }
      }
    } else if (error.error_code) {
      message = findErrorMessage(error.error_code, "en");
    }

    if (!message) {
      console.error(`Could not find any message against [${error.error_code}]`);
    }

    if (!message) {
      message = error.message;
    }

    if (messageArray.length > 0) {
      for (const message of messageArray) {
        customToast.error(message);
      }
    } else {
      customToast.error(message);
    }
  }
};

export const isVisibleMenu = (key: string) => {
  return key && key.toLowerCase() === "y";
};

export const isJSON = (val: string): boolean => {
  if (typeof val === "string" && val.length > 1) {
    const startChar = val[0];
    const endChar = val[val.length - 1];
    return ["{", "["].includes(startChar) && ["]", "}"].includes(endChar);
  }

  return false;
};

export const timeDiff = (
  from: any,
  to: any,
  unit: unitOfTime.Diff = "seconds"
): number => {
  return moment(from).diff(to, unit);
};

export const timeDiffFromNow = (time: Moment, unit = "seconds"): number => {
  return timeDiff(time, moment(), unit as unitOfTime.Diff);
};

export const padStart = (value: string, length: number, character: string) => {
  return value.padStart(length, character);
};

export const getCardLastFour = (cardId: string, cards?: Array<any>) => {
  let lastFour: string | undefined | null = undefined;
  if (cards && cards?.length > 0) {
    lastFour = cards?.find((card: any) => card?.id === cardId)?.lastFour;
  }
  if (!lastFour) return undefined;
  return padLastFour(lastFour);
};

export const addSpaceInCardNumber = (cardNumber: string) => {
  return cardNumber.match(/.{1,4}/g)?.join(" ");
};

export const formatLastFour = (cardNumber: string) => {
  return cardNumber ? addSpaceInCardNumber(padStart(cardNumber, 16, ".")) : "";
};

export const padLastFourHtmlTag = (lastFour: string) => {
  return lastFour ? lastFour.padStart(14, "<span>....<span>") : "";
};

export const padLastFour = (lastFour: string) => {
  return lastFour ? lastFour.padStart(9, "....") : "";
};

export const padLastFive = (lastFive: string) => {
  return lastFive ? lastFive.padStart(23, "<span>.....</span>") : "";
};

export const previewMaskSsn = (ssnNumber: string) => {
  return ssnNumber.slice(ssnNumber.length - 4);
};

export const maskSSN = (ssnNumber: any) => {
  if (ssnNumber.length > 0) {
    const ssnDigits = ssnNumber.length - 1;
    const lastDigit = ssnNumber[ssnDigits];
    let ssn = "";
    const mask = "*";
    for (var i = 0; i < ssnDigits; i++) {
      ssn = `${ssn}${mask}`;
    }
    const finalMask = `${ssn}${lastDigit}`;
    return finalMask;
  } else {
    return "";
  }
};

export function formatSocialSecurity(val: string) {
  val = val.replace(/\D/g, "");
  val = val.replace(/^(\d{3})/, "$1-");
  val = val.replace(/-(\d{2})/, "-$1-");
  val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
  return val;
}

export const formatName = (firstName: string, lastName: string) => {
  return `${firstName[0].toUpperCase()}.${lastName}`;
};

export const formatCurrency = (amount: number, currency: string) => {
  let formatter: Intl.NumberFormat | undefined;
  if (currency) {
    formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currencyDisplay: "code",
      currency,
    });
  }

  return formatter?.format(amount) || amount;
};

export const formatAmountOnly = (amount: number): string => {
  let formattedAmount = "0.00";
  if (amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    formattedAmount = formatter.format(amount);
  }
  return formattedAmount;
};

export const pascalCaseToSpaces: (str: string) => string = (
  str: string = ""
) => {
  if (str === str.toUpperCase()) return capitalize(str);

  const result = str.replace(/([A-Z])/g, " $1");
  return result.trim();
};

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const decimalCount = (num: string) => {
  const numStr = String(num);
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  return 0;
};

export const startOfWeek = () => {
  return dayjs().startOf("week");
};

export const endOfWeek = () => {
  return dayjs().endOf("week");
};

export const isEmpty = (objectDetails: any) => {
  return !Object.keys(objectDetails).length;
};

export const accountLastFour = (accountProxyNo: any) => {
  return accountProxyNo
    ?.toString()
    ?.substring(accountProxyNo.toString().length - 4);
};

export const accountLastFive = (accountProxyNo: any) => {
  return accountProxyNo
    ?.toString()
    ?.substring(accountProxyNo.toString().length - 5);
};

export const getAccountInformation = (accountId: string, accounts: any) => {
  return accounts?.find((item: any) => item?.id === accountId);
};

export const getSecureURLDetails = () => {
  return localStorage.getItem("Cloud_SecureURL");
};

export const getNetworkLogo = (networks: any, networkCode: string) => {
  const network = networks.find((item: any) => item.code === networkCode);
  return network?.logo;
};

export const extractCountryCode = (phoneNumber: any) => {
  const match = phoneNumber?.match(/^\((\d+)\)/); // Regex pattern to match the country code in the format '(123)'
  return match ? match[1] : undefined; // Extract the captured group and return it, or undefined if no match
};

export const getStorageItem = (key: string): any => {
  const item = localStorage.getItem(key);

  if (item) return JSON.parse(item);

  return null;
};

export const setStorageItem = (key: string, data: any): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const capitalizeTextTransform = (string: string): string => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};
