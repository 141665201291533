import { Backdrop, Box, Fade, Modal } from '@mui/material'

interface ModalProps {
  handleClose: (event: React.SyntheticEvent, reason: string) => void;
  open: boolean
  width?: any
  children?: any
  height?: any
  boxClass?:string
  boxCustomBgColor?:string
}

const MaterialModal = (props: ModalProps) => {
  const { handleClose, open, width, boxClass } = props

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || '25rem',
    bgcolor: '#ffffff',
    outline: 'unset'
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
        sx: { backgroundColor: '#394E5F', opacity: `.8 !important` }
      }}
    >
      <Fade in={open}>
        <Box className={`mat-modal-box material-box ${boxClass!}`} sx={style}>
          <div className="d-flex-col align-center fullHeight fullWidth">
            {props.children}
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}

export default MaterialModal
