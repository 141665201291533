import { FC } from "react";
import "./form-style.css";

interface FieldHeadingProps {
  heading: string;
  subHeading: string;
}

const TransferFundsHeader: FC<FieldHeadingProps> = ({
  heading,
  subHeading,
}) => {
  return (
    <div className="transfer-funds-header">
      <div className="transfer-funds-header-content">
        <div className="transfer-funds-header-title">{heading}</div>
        <div className="transfer-funds-header-subtitle">{subHeading}</div>
      </div>
    </div>
  );
};
export default TransferFundsHeader;
