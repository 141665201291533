import { Table } from "antd";
import useStyles from "../../../../hooks/use-styles";
import FlexButtons from "../../../flex-button";
import useLabels from "../../../../hooks/use-labels";
import { useNavigate } from "react-router-dom";
import { accountLastFive, padLastFive } from "../../../../utils/common-utils";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlineOutlined } from "@mui/icons-material";

interface VerifyModalProps {
  showDrawer: Function;
  bankAccountData: any;
}

const BankTransferTable = ({
  showDrawer,
  bankAccountData,
}: VerifyModalProps) => {
  let navigate = useNavigate();
  const {
    pendingBlueColor,
    verifyBtnGreenColor,
    redColor,
    primaryButtonBackground,
    primaryButtonBorder,
    secondaryButtonBorder,
    secondaryButtonBackground,
    secondaryButtonLabelColor,
    statusYellowColor,
  } = useStyles([
    "lightGrayColor",
    "pendingBlueColor",
    "verifiyGreenColor",
    "verifyBtnGreenColor",
    "redColor",
    "primaryButtonBackground",
    "primaryButtonBorder",
    "secondaryButtonBorder",
    "secondaryButtonBackground",
    "secondaryButtonLabelColor",
    "statusYellowColor",
  ]);

  const {
    bankTableTitle,
    accountTableTitle,
    statusTableTitle,
    NickNameTableTitle,
  } = useLabels([
    "bankTableTitle",
    "accountTableTitle",
    "statusTableTitle",
    "NickNameTableTitle",
  ]);

  const columns: ColumnsType<any> = [
    {
      title: bankTableTitle,
      dataIndex: "bankName",
    },
    {
      title: accountTableTitle,
      dataIndex: "accountNumber",
      render: (text: any) => <div>{padLastFive(accountLastFive(text))}</div>,
    },
    {
      title: statusTableTitle,
      dataIndex: "verificationStatus",
      key: "status",
      render: (text: any, record: any) => (
        <>
          {text === "FAILED" && (
            <div>
              <p style={{ color: redColor }}>FAILED</p>
            </div>
          )}

          {text === "INPROGRESS" && (
            <>
              <div className="in-progress">
                <p style={{ color: pendingBlueColor }}>In Progress</p>
              </div>
              <div className="verify-send-button">
                <div className="d-flex">
                  <FlexButtons className="w-130 mr-15">
                    <button
                      className="bank-transfer-btn"
                      onClick={() => showDrawer(record)}
                      style={{
                        backgroundColor: verifyBtnGreenColor,
                        borderColor: verifyBtnGreenColor,
                        color: "white",
                      }}
                    >
                      Verify
                    </button>
                    <button
                      className="bank-transfer-btn"
                      onClick={() => onSendMoney(record)}
                      style={{
                        backgroundColor: primaryButtonBackground,
                        borderColor: primaryButtonBorder,
                        color: "white",
                      }}
                    >
                      Send
                    </button>
                    <DeleteOutlineOutlined fontSize="large" />
                  </FlexButtons>
                </div>
              </div>
            </>
          )}

          {text === "VERIFIED" && (
            <>
              <div className="verified">
                <p style={{ color: verifyBtnGreenColor }}>Verified</p>
              </div>
              <div className="send-receive-button">
                <div className="d-flex justify-content-between align-items-center">
                  <FlexButtons className="mr-15">
                    <button
                      className="bank-transfer-btn"
                      onClick={() => onReceiveMoney(record)}
                      style={{
                        backgroundColor: secondaryButtonBackground,
                        color: secondaryButtonLabelColor,
                        borderColor: secondaryButtonBorder,
                      }}
                    >
                      Receive
                    </button>
                    <button
                      className="bank-transfer-btn"
                      onClick={() => onSendMoney(record)}
                      style={{
                        backgroundColor: primaryButtonBackground,
                        borderColor: primaryButtonBorder,
                        color: "white",
                      }}
                    >
                      Send
                    </button>
                    {/* <DeleteOutlineOutlined fontSize="large" /> */}
                  </FlexButtons>
                </div>
              </div>
            </>
          )}

          {text === "UNVERIFIED" && (
            <>
              <div className="un-verified">
                <p style={{ color: statusYellowColor }}>Unverified</p>
              </div>
              <div className="send-receive-button">
                <div className="d-flex align-items-center">
                  <FlexButtons className="w-130">
                    <button
                      className="bank-transfer-btn"
                      onClick={() => onSendMoney(record)}
                      style={{
                        backgroundColor: primaryButtonBackground,
                        borderColor: primaryButtonBorder,
                        color: "white",
                      }}
                    >
                      Send
                    </button>
                    <DeleteOutlineOutlined fontSize="large" />
                  </FlexButtons>
                </div>
              </div>
            </>
          )}

          {text === "PREVERIFIED" && (
            <>
              <div className="verified">
                <p style={{ color: pendingBlueColor }}>Preverified</p>
              </div>
              <div className="send-receive-button">
                <div className="d-flex">
                  <FlexButtons className="w-130 h-20 mr-15">
                    <button
                      className="bank-transfer-btn"
                      onClick={() => onReceiveMoney(record)}
                      style={{
                        backgroundColor: secondaryButtonBackground,
                        color: secondaryButtonLabelColor,
                        borderColor: secondaryButtonBorder,
                      }}
                    >
                      Receive
                    </button>
                    <button
                      className="bank-transfer-btn"
                      onClick={() => onSendMoney(record)}
                      style={{
                        backgroundColor: primaryButtonBackground,
                        borderColor: primaryButtonBorder,
                        color: "white",
                      }}
                    >
                      Send
                    </button>
                    <DeleteOutlineOutlined fontSize="large" />
                  </FlexButtons>
                </div>
              </div>
            </>
          )}
        </>
      ),
    },
  ];

  const onReceiveMoney = (sendTransferProps: any) => {
    navigate("/Receive-Money", {
      state: {
        sendTransferProps,
        pageTitle: "Transfer Funds",
      },
    });
    localStorage.setItem(
      "SendTransfer_Props",
      JSON.stringify({
        state: {
          sendTransferProps,
          pageTitle: "Transfer Funds",
        },
      })
    );
  };

  const onSendMoney = (sendTransferProps: any) => {
    navigate("/Send-Money", {
      state: {
        sendTransferProps,
        pageTitle: "Transfer Funds",
      },
    });
    localStorage.setItem(
      "SendTransfer_Props",
      JSON.stringify({
        state: {
          sendTransferProps,
          pageTitle: "Transfer Funds",
        },
      })
    );
  };

  return (
    <div className="bank-transfer-table">
      <div className="friend-family-table">
        <Table
          columns={columns}
          dataSource={bankAccountData.bankAccounts}
          className="custom-ant-pagination mt-1 text-center"
          pagination={{ pageSize: 8 }}
          rowKey={"id"}
          scroll={{ x: "600px" }}
        />
      </div>
    </div>
  );
};

export default BankTransferTable;
