import { useNavigate } from "react-router-dom";

import { congratulationsImage } from "../../utils/SvgImage/Icons";
import CustomButton from "../custom-button";
import FlexButtons from "../flex-button";

const Congratulations = () => {
  let navigate = useNavigate();

  const finishConfig = () => {
    navigate("/dashboard");
  };

  return (
    <div className="card-item-white">
      <div className="cong-page">
        <div className="auth-text">
          <div className="cong-icon">{congratulationsImage}</div>
          <h2>Congratulations!</h2>
          <p>Your Contact is successfuly added</p>
          <FlexButtons className="ayout-buttons justify-start">
            <CustomButton
              type="primary"
              className="full-width"
              style={{ backgroundColor: "#006CF8" }}
              onClick={finishConfig}
              text="Finish"
            />
          </FlexButtons>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
