import { capitalize, formatCurrency } from "../../../../utils/common-utils";
import moment from "moment";
import useStyles from "../../../../hooks/use-styles";

interface TransactionDetailsProps {
  transaction: any;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  transaction,
}) => {

  const {
    transactionAmount,
    transactionDatetime,
    id,
    transactionStatus,
    feeAmount,
  } = transaction;

  const { textColor, greyColor, greenColor, redColor, darkBlueColor } = useStyles([
    'textColor',
    'greyColor',
    'greenColor',
    'redColor',
    'darkBlueColor'
  ])

  let coloredTransactionStatus: any = {
    "PROCESSED": <span style={{ color: greenColor }}>{capitalize(transactionStatus)}</span>,
    "PENDING": <span style={{ color: redColor }}>{capitalize(transactionStatus)}</span>
  }

  let fAmount = feeAmount < 0 ? formatCurrency(+feeAmount.amount * -1, feeAmount.currency || "USD")
    : formatCurrency(+feeAmount.amount, feeAmount.currency || "USD");

  const transactionDetails = [
    {
      title: 'Transaction ID',
      value: id
    },
    {
      title: 'Transaction Status',
      value: coloredTransactionStatus[transactionStatus.toString()]
    },
    {
      title: 'Fee',
      value: <span className="weight-500">{fAmount}</span>
    },
    {
      title: 'Location Date',
      value: <span className="weight-500">{transactionDatetime ? moment(transactionDatetime).format("ll") : "-"}</span>
    },
    {
      title: 'Location Time',
      value: transactionDatetime ? moment(transactionDatetime).format("LTS") : "-"
    }
  ]

  return (
    <>
      <div className="text-40px weight-530 text-center Helvetica" style={{ color: '#1D1E1F' }}>
        {transactionAmount ? formatCurrency(+transactionAmount.amount, transactionAmount.currency || "USD") : "-"}
      </div>

      <div className="text-sm text-center" style={{ color: greyColor }}>
        {transactionDatetime ? moment(transactionDatetime).format('ll [at] LT') : "-"}
      </div>

      <p className='mt-3 text-20px' style={{ color: greyColor }}>Transaction Details</p>

      <div className='mt-1' >
        {transactionDetails.map((item: any, index: number) => (
          <div key={index} className="text-sm d-flex justify-space-between Poppins">
            <p style={{ color: textColor }}>{item.title}</p>
            <p style={{ color: darkBlueColor }}>{item.value}</p>
          </div>
        ))}
      </div>

    </>
  );
};

export default TransactionDetails;
